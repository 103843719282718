<template>
  <page :title="$t('share.InviteFriends')" class="  bg-no-repeat bg-fixed bg-top priPageBg" :style="{
    backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
  }">
    <!-- <button slot="nav-action" @click="$router.push('/proxy')">
      {{ $t('share.rules') }}
    </button> -->
    <!-- <div class="relative -mt-[56px]">
      <img
        src="../../assets/img/Homeindex/sharePage/topBg.png"
        alt=""
        class="w-full"
      />
      <div
        class="p-2 rounded bg-white bg-opacity-20 backdrop-blur-lg absolute inset-x-[20px] top-1/2 -translate-y-1/2"
      >
        <div class="p-base">
          <h2 class="t-2xl font-bold">{{ $t('share.title') }}</h2>
          <p class="t-sm opacity-50 mt-1">{{ $t('share.desc') }}</p>
        </div>
      </div>
    </div> -->

    <div class="p-base pt-base ">
      <div class="card px-base py-4 text-center priBoxBg">
        <div class="flex justify-center">
          <div class="" v-if="userInfo.InviteLink">
            <vue-qr :text="userInfo.InviteLink" :size="200" class="rounded"></vue-qr>
          </div>
        </div>
        <p class="text-center mt-base">
          {{ $t('share.InvitationCode') }}{{ userInfo.InviteCode }}
        </p>

        <div class="flex justify-center py-1">
          <button class="btn t-xs px-2 h-[30px]" v-clipboard:copy="userInfo.InviteCode" v-clipboard:success="onCopy">
            {{ $t('share.copy') }}
          </button>
        </div>
        <p class="text-gray-300 mt-2">{{ $t('share.link') }}</p>
        <p class="mt-1">
          {{ shareLink }}
        </p>
        <div class="flex justify-center py-[16px]">
          <button class="btn t-xs px-2 h-[30px]" v-clipboard:copy="shareLink" v-clipboard:success="onCopy">
            {{ $t('share.copy') }}
          </button>
        </div>
        <div class="grid grid-cols-3 text-center gap-1 t-xs mt-4" v-if="false">
          <div class="flex flex-col items-center">
            <img class="h-[40px]" src="../../assets/img/Homeindex/sharePage/t1.png" alt="" />
            <span class="mt-[12px]">Line</span>
          </div>
          <div class="flex flex-col items-center">
            <img class="h-[40px]" src="../../assets/img/Homeindex/sharePage/t2.png" alt="" />
            <span class="mt-[12px]">SMS</span>
          </div>
          <div class="flex flex-col items-center">
            <img class="h-[40px]" src="../../assets/img/Homeindex/sharePage/t3.png" alt="" />
            <span class="mt-[12px]">Email</span>
          </div>
        </div>
      </div>
    </div>
  </page>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import { Toast } from 'vant';
import { mapActions, mapGetters } from 'vuex';
import VueQr from 'vue-qr';
export default {
  data() {
    return {
      shareLink: '',
    };
  },
  components: {
    Navbar,
    VueQr,
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
    }),
    onCopy() {
      Toast.success(this.$t('common.copied'));
    },
  },
  created() {
    this.getUserInfo().then((res) => {
      console.log(window.location.origin);
      this.shareLink =
        window.location.origin +
        `/#/regist?invite_code=${this.userInfo.InviteCode}`;
    });
  },
};
</script>

<style lang="scss" scoped>
.sharePage {
  position: relative;
  width: 100%;
  height: 22rem;

  .topBg {
    position: absolute;
    width: 100%;
    height: 8.2667rem;
  }

  .desc {
    position: absolute;
    top: 114px;
    left: 14px;
    color: #fff;

    .title {
      width: 5.3333rem;
      font-size: 0.4267rem;
      margin-bottom: 5px;
      font-weight: 900;
    }

    .descD {
      font-size: 0.3467rem;
      opacity: 0.5;
      width: 4.6rem;
    }
  }
}
</style>
