<template>
  <div class="teamMemberPage">
    <Navbar
      NavLeftArrow="true"
      :NavLeftText="$t('team.AgentRebateCommission')"
    ></Navbar>
    <div class="proxyView">
      <div
        :class="['proxyItem', curIndex == index ? 'active' : '']"
        v-for="(item, index) in levelTabs"
        :key="index"
        @click="curIndex = index"
      >
        <span>{{ item }}</span>
      </div>
    </div>

    <div class="space-y-1 px-[16px] py-2">
      <div
        class="bg-card text-white rounded p-2 flex"
        v-for="(item, index) in list"
        :key="index"
      >
        <div class="flex-1">
          <div class="text-[16px] font-bold">{{ item.Remark }}</div>
          <div class="mt-1 text-[12px] text-gray-400">
            {{ item.CreateTime }}
          </div>
        </div>
        <div class="text-[18px] text-up">{{ item.Amount }}</div>
      </div>
    </div>

    <p class="sign">{{ $t('noMore') }}</p>
  </div>
</template>

<script>
import { ApiPath } from '@/api/path';
import Navbar from '@/components/Navbar/Navbar.vue';
let _this;
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      curIndex: 0,
      list: [],
    };
  },
  watch: {
    curIndex(val) {
      this.getMyCommission(val + 1);
    },
  },
  computed: {
    levelTabs: () => [
      _this.$t('team.Level1Agent'),
      _this.$t('team.Level2Agent'),
      _this.$t('team.Level3Agent'),
    ],
  },
  methods: {
    async getMyCommission(level) {
      const params = {
        pageIndex: 1,
        pageSize: 20,
        level,
      };
      const { data, code } = await this.$http.get(
        ApiPath.getMyCommission,
        params
      );
      if (code == 0) {
        // this.list = data;
      }
    },
  },
  created() {
    _this = this;
    this.getMyCommission(this.curIndex + 1);
  },
};
</script>
<style lang="scss" scoped>
.teamMemberPage {
  width: 100%;
  height: 100%;
  background-color: #161616;
  padding-top: 60px;
  .proxyView {
    padding: 0 0.4rem;
    box-sizing: border-box;
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    .proxyItem {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.2933rem;
      height: 0.9333rem;
      span {
        font-size: 0.3733rem;
        color: #646566;
      }
    }
    .active {
      background-color: #1f1f1f !important;
      span {
        color: #ffe87e;
      }
    }
  }

  .sign {
    margin-top: 20px;
    text-align: center;
    color: #969799;
    font-size: 0.3733rem;
  }
}
</style>
