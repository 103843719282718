<template>
  <div class="tmRulePage">
    <Navbar NavLeftArrow="true" :NavMidText="$t('team.ReferralRules')"></Navbar>
    <!-- <img src="../../../assets/img/Homeindex/rulePage.jpg" alt=""> -->
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
export default {
  components: {
    Navbar,
  },
  created() {},
  data() {
    return {};
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.tmRulePage {
  width: 100%;
  height: 100%;
  background-color: #161616;
  padding-top: 60px;
  img {
    width: 100%;
  }
}
</style>
