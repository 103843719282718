<template>
  <div class="homePage">
    <router-view></router-view>
    <van-tabbar
      v-model="active"
      route
      active-color="#ffe87e"
      inactive-color="#686c6d"
    >
      <!-- tabbar 1 //首页-->
      <van-tabbar-item to="/index">
        <span>{{ $t('home.tabs.Home') }}</span>
        <template #icon="props">
          <img
            class="icon-tabbar"
            :src="props.active ? icon.active : icon.inactive"
          />
        </template>
      </van-tabbar-item>
      <!-- tabbar-->
      <!-- tabbar 2 //行情-->
      <van-tabbar-item to="/trend">
        <span>{{ $t('home.tabs.Market') }}</span>
        <template #icon="props">
          <img
            class="icon-tabbar"
            :src="props.active ? icon.active2 : icon.inactive2"
          />
        </template>
      </van-tabbar-item>
      <!-- tabbar-->
      <!-- tabbar 3 //團隊-->
      <van-tabbar-item to="/team">
        <span>{{ $t('home.tabs.Team') }}</span>
        <template #icon="props">
          <img
            class="icon-tabbar"
            :src="props.active ? icon.active3 : icon.inactive3"
          />
        </template>
      </van-tabbar-item>
      <!-- tabbar-->
      <!-- tabbar 4 //资产-->
      <van-tabbar-item to="/my">
        <span>{{ $t('home.tabs.Assets') }}</span>
        <template #icon="props">
          <img
            class="icon-tabbar"
            :src="props.active ? icon.active4 : icon.inactive4"
          />
        </template>
      </van-tabbar-item>
      <!-- tabbar-->
    </van-tabbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      icon: {
        active: require('../../assets/img/bar/1_A.png'),
        inactive: require('../../assets/img/bar/1_N.png'),
        active2: require('../../assets/img/bar/2_A.png'),
        inactive2: require('../../assets/img/bar/2_N.png'),
        active3: require('../../assets/img/bar/3_A.png'),
        inactive3: require('../../assets/img/bar/3_N.png'),
        active4: require('../../assets/img/bar/4_A.png'),
        inactive4: require('../../assets/img/bar/4_N.png'),
      },
    };
  },
  computed: {},
};
</script>

<style lang="scss" scoped>
.homePage {
  width: 100%;
  height: 100%;
  background-color: #1f1f1f;
  color: #fff;
  .van-tabbar-item {
    background-color: #1f1f1f;
  }
  [class*='van-hairline']::after {
    border: none;
  }
  .icon-tabbar {
    width: 22px;
    height: 22px;
  }

  .van-tabbar {
    height: 60px;
  }
}
::v-deep .van-tabbar-item--active .icon-tabbar {
  width: 23px;
  height: 23px;
}
::v-deep .van-tabbar-item__icon {
  margin-bottom: 2px !important;
}
::v-deep .van-tabbar-item--active .van-tabbar-item__text {
  padding-top: 0.1rem !important;
}
</style>
