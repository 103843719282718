<template>
  <tab-page class="myPage">
    <!-- 头部 -->
    <div class="flex flex-col px-base pt-[48px] pb-[60px] bg-myBanner bg-no-repeat bg-cover">
      <div class="flex justify-between items-center">
        <div class="flex justify-between items-center">
          <!-- <div class="bg-[#007BFF] w-[48px] h-[48px] rounded-full mr-[10px] flex items-center justify-center">
            <img src="../../../assets/img/svg/V2/nav/home.svg" class="w-[22px] h-[23px]" alt="">
          </div> -->
          <img src="../../../assets/img/svg/V2/user.svg" class="w-[36px] h-[36px] rounded-full mr-[10px]" alt="" />
          <h3 class="text-[18px] font-bold">
            {{ userInfo.UserName | hideStrMiddle }}
          </h3>
        </div>
        <language />
      </div>
      <div class="flex-1 ml-[58px]">
        <div class="inline-flex items-center text-[12px] bg-[#383d6e]/50 rounded-full px-[12px] h-[28px]"
          v-clipboard:copy="userInfo.InviteCode" v-clipboard:success="onCopy">
          <span>{{ $t('my.InvitationCode') }}: {{ userInfo.InviteCode }}</span>
          <img src="../../../assets/img/Homeindex/Mypage/copy.png" alt="" class="w-[10px] ml-1" />
        </div>
      </div>
    </div>
    <div class="card -mt-[40px] p-[20px] mx-base backdrop-blur-sm bg-[#383D6E]/50 relative overflow-hidden">
      <div class="bg-bgG w-[95px] h-[100px] bg-no-repeat bg-contain absolute top-[-13px] left-[-10px]">
      </div>
      <div class=" text-center">
        <p class=" text-[14px] opacity-50 ">
          {{ $t('my.MyWalletBalance') }}
        </p>
        <p class="text-[30px] mt-[8px] font-thin">
          {{ wallet.Balance }}
          <span class="">{{ globalSymbol }}</span>
        </p>
      </div>
      <div class="flex  mt-[16px] ">
        <div class="flex-1 flex justify-around">
          <div class="flex flex-col items-center">
            <div class="flex-1 text-gray-300 text-[12px]">
              {{ $t('my.Capitaltrading') }}
            </div>
            <div class="mt-[5px] break-all">
              {{ wallet.FrozenBalance }}
              <!-- 9221451556 -->
              <span>{{
                globalSymbol
              }}</span>
            </div>
          </div>

          <div class="flex flex-col items-center">
            <div class="flex-1 text-gray-300 text-[12px]">
              {{ $t('my.Totalofpersonalprofits') }}
            </div>
            <div class="mt-[5px] break-all">
              <!-- 1812.006939 -->
              {{ userInfo.Profit }}
              <span>{{
                globalSymbol
              }}</span>
            </div>
          </div>
   
        </div>


        <!-- <div class="flex items-center">
          <div class="flex-1 text-gray-300 text-[14px]">
            {{ $t('my.Todaysagentprofits') }}
          </div>
          <div class="">{{ userInfo.DailyProfit }}</div>
          <span class="font-light text-[12px] text-gray-400 ml-[4px]">{{
            globalSymbol
          }}</span>
        </div>
        <div class="flex items-center">
          <div class="flex-1 text-gray-300 text-[14px]">
            {{ $t('my.Totalofagentprofits') }}
          </div>
          <div class="">{{ userInfo.AgentProfit | toFixedStr }}</div>
          <span class="font-light text-[12px] text-gray-400 ml-[4px]">{{
            globalSymbol
          }}</span>
        </div> -->
        <!-- <div class="flex items-center">
          <div class="flex-1 text-gray-300 text-[14px]">
            {{ $t('my.Robotscommission') }}
          </div>
          <div class="">{{ userInfo.RobotProfit | toFixedStr }}</div>
          <span class="font-light text-[12px] text-gray-400 ml-[4px]">{{
            globalSymbol
          }}</span>
        </div> -->
      </div>
    </div>
    <div class="card mx-base mt-base p-base bg-[#695BF6] flex items-center glass"
      @click="$router.push('/robot' + `?robotsType=1`)">
      <img src="../../../assets/img/Homeindex/Mypage/robot.svg" class="w-[42px] h-[48px] rounded-full mr-[12px] mr-base"
        alt="" />
      <div class="flex-1 font-normal" v-html="$t('common.runningCount', {
        count: `<b class='font-bold text-yellow-400'>${robotCount}</b>`,
      })
        "></div>
      <t-icon icon="chevron-right" class="-mr-[4px]" />
    </div>
    <div class="p-base">
      <div class="card py-base grid grid-cols-3 gap-[4px] text-[12px] glass bg-opacity-70 priBoxBg">
        <div class="flex flex-col items-center space-y-[6px] press-btn" @click="$router.push('/deposit')">
          <!-- <t-icon icon="log-in" class="text-[28px]" /> -->
          <div class="w-[60px] h-[60px] rounded-[20px] bg-[#121634]/50 flex items-center justify-center">
            <img src="../../../assets/img/Homeindex/Mypage/recharge.svg" class="w-[30px] h-[26px]" alt="">
          </div>
          <div class="w-full text-center">{{ $t('home.Deposit') }}</div>
        </div>
        <div class="flex flex-col items-center space-y-[6px] press-btn" @click="$router.push('/trend')">
          <!-- <t-icon icon="bot" class="text-[28px]" /> -->
          <div class="w-[60px] h-[60px] rounded-[20px] bg-[#121634]/50 flex items-center justify-center">
            <img src="../../../assets/img/Homeindex/Mypage/robot.svg" class="w-[30px] h-[26px]" alt="">
          </div>
          <div class="w-full text-center">{{ $t('common.robot') }}</div>
        </div>
        <div class="flex flex-col items-center space-y-[6px] press-btn" @click="$router.push('/withdraw')">
          <!-- <t-icon icon="log-out" class="text-[28px]" /> -->
          <div class="w-[60px] h-[60px] rounded-[20px] bg-[#121634]/50 flex items-center justify-center">
            <img src="../../../assets/img/Homeindex/Mypage/withdrawal.svg" class="w-[30px] h-[26px]" alt="">
          </div>
          <div class="w-full text-center">{{ $t('common.Withdrawal') }}</div>
        </div>
      </div>
    </div>

    <!-- 记录 -->

    <div class="card py-[8px] mx-base mb-base glass bg-opacity-70 priBoxBg">
      <t-cell :imgSrc="require('../../../assets/img/svg/V2/records/transaction.svg')" link="/trade-record">{{ $t('my.r1')
      }}</t-cell>
      <t-cell :imgSrc="require('../../../assets/img/svg/V2/records/revenue.svg')" link="/income">{{ $t('my.r2')
      }}</t-cell>
      <!-- <t-cell icon="file-pie-chart" link="/cost">{{ $t('my.r3') }}</t-cell> -->
      <t-cell :imgSrc="require('../../../assets/img/svg/V2/records/withdrawalRecords.svg')" link="/withdraw-record">{{
        $t('my.r4')
      }}</t-cell>
      <t-cell :imgSrc="require('../../../assets/img/svg/V2/records/rechargeRecords.svg')" link="/deposit-record">{{
        $t('my.r5')
      }}</t-cell>
    </div>

    <div class="card py-[8px] mx-base mb-base glass bg-opacity-70 priBoxBg">
      <!-- <t-cell icon="settings" link="/bank">{{ $t('my.b1') }}</t-cell> -->
      <!-- <t-cell icon="users" link="/team">{{ $t('my.b2') }}</t-cell> -->
      <!-- <t-cell icon="tags" link="/bonus">{{ $t('my.b3') }}</t-cell> -->
      <!-- <t-cell icon="headphones" @click="change_showKefu(true)">{{
        $t('my.b4')
      }}</t-cell> -->
      <t-cell icon="lock" link="/login-pwd?type=1">{{ $t('my.b5') }}</t-cell>
      <t-cell icon="lock" link="/login-pwd?type=2">{{ $t('my.b7') }}</t-cell>
    </div>

    <!-- 退出登录 -->
    <div class="p-base">
      <button class="btn w-full bg-red-500" @click="logout">
        {{ $t('my.logout') }}
      </button>
    </div>
  </tab-page>
</template>

<script>
import { ApiPath } from '@/api/path';
import Store from '@/utils/storage';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { Toast } from 'vant';
import TabPage from '@/components/TabPage.vue';
import Language from '@/components/Language.vue';

export default {
  components: { TabPage, Language },
  created() { },
  data() {
    return {
      loading: false,
      robotList: [],
      robotCount: 0,
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'globalSymbol', 'wallet']),
  },
  methods: {
    ...mapMutations('showPopup', ['change_showKefu']),
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      getWallet: 'wallet/getWallet',
    }),
    async logout() {
      const { code, msg } = await this.$http.post(ApiPath.logout);
      if (code == 0) {
        Store.removeToken();
        if (!Store.getToken()) {
          this.$router.push('/login');
        }
      }
    },
    onCopy() {
      Toast.success(this.$t('common.copied'));
    },

    async getRobotOrderList() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
        status: 1, // 0：待激活，1：进行中，2：暂停中，3：已过期，4：已售
      };
      this.loading = true;
      try {
        const { data, code, total } = await this.$http.get(
          ApiPath.getRobotOrderList,
          params
        );
        this.loading = false;
        if (code == 0) {
          this.robotList = data;
          this.robotCount = total;
        }
      } catch (error) {
        this.loading = false;
      }
    },
  },
  created() {
    this.getUserInfo();
    this.getWallet();
    this.getRobotOrderList();
  },
};
</script>
<style lang="scss" scoped></style>
