<template>
  <div class="christmasPage">
    <div class="bgCover"></div>
    <!-- 站位 -->
    <div class="placeBox"></div>
    <Navbar
      :BGTransparent="true"
      :NavLeftArrow="true"
      :NavLeftText="$t('home.back')"
    ></Navbar>
    <div class="activeView">
      <p>{{ $t('home.chrInvi') }} : 0</p>
      <p>{{ $t('home.Redraws') }} : 0</p>
    </div>
    <!-- 抽獎 -->
    <div class="zp-main">
      <!-- <img src="../../assets/img/Homeindex/activeView.png" class="m-img-gg" /> -->
      <div class="robotRow r1">
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
      </div>
      <div class="robotRow r2">
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
      </div>
      <div class="robotRow r3">
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
        <img src="../../assets/img/Homeindex/robotImg.png" alt="" />
      </div>
    </div>
    <!-- 获奖信息 -->
    <div class="userGet">
      <div class="col">
        <span class="colName">{{ $t('home.Prizes') }}</span>
        <span>获得1个DEEPSPEED-1</span>
        <span>获得1个DEEPSPEED-1</span>
        <span>获得1个DEEPSPEED-1</span>
        <span>获得1个DEEPSPEED-1</span>
        <span>获得1个DEEPSPEED-1</span>
        <span>获得1个DEEPSPEED-1</span>
      </div>
      <div class="col">
        <span class="colName">{{ $t('home.User') }}</span>
        <span>334****5100</span>
        <span>334****5100</span>
        <span>334****5100</span>
        <span>334****5100</span>
        <span>334****5100</span>
        <span>334****5100</span>
      </div>
    </div>
    <!-- 获奖说明 -->
    <div class="activeRule">
      <p class="rule">{{ $t('home.chrDesc') }}</p>
      <p class="giftList">{{ $t('home.PrizeList') }}</p>
      <ul data-v-2de0d522="">
        <li data-v-2de0d522="">當日購買機器人5折</li>
        <li data-v-2de0d522="">當日購買機器人3折</li>
        <li data-v-2de0d522="">當日購買機器人2折</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B9</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B8</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B7</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B6</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B5</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B4</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B3</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B2</li>
        <li data-v-2de0d522="">獲得1個DEEPSPEED-B1</li>
        <li data-v-2de0d522="">謝謝惠顧</li>
      </ul>
      <!-- <img src="../../assets/img/Homeindex/activeTree.png" alt="" /> -->
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
export default {
  components: {
    Navbar,
  },
};
</script>

<style lang="scss" scoped>
.christmasPage {
  width: 100%;
  min-height: 100%;
  background-color: #161616;
  padding-bottom: 1.3333rem;
  .placeBox {
    width: 100%;
    height: 100px;
  }

  .bgCover {
    position: absolute;
    top: 0;
    width: 10rem;
    height: 3.6933rem;
    background: url('../../assets/img/Homeindex/activeTop.png') top no-repeat;
    background-size: cover;
  }

  .activeView {
    p {
      color: #fff;
      font-size: 0.4267rem;
      text-align: center;
    }
  }

  .zp-main {
    position: relative;
    display: flex;
    justify-content: center;

    .m-img-gg {
      margin-top: 0.4533rem;
      width: 8.9333rem;
      height: 9.2rem;
    }

    .robotRow {
      width: 7.76rem;
      justify-content: space-around;
      position: absolute;
      display: flex;

      img {
        width: 1.6rem;
        height: 2.0667rem;
      }
    }

    .r1 {
      top: 1.3333rem;
    }

    .r2 {
      top: 4rem;
    }

    .r3 {
      top: 6.6667rem;
    }
  }

  .userGet {
    margin: 0.8rem auto;
    width: 8.9333rem;
    display: flex;
    padding: 0.5333rem 0.2667rem;
    color: #fff;
    font-size: 0.4267rem;
    background-color: #1f1f1f;
    justify-content: space-around;
    box-sizing: border-box;
    border-radius: 0.26667rem;

    .col {
      flex: 1;
      display: flex;
      text-align: center;
      flex-direction: column;

      span {
        display: block;
        margin-top: 0.32rem;
        height: 30px;
        border-bottom: 0.05333rem solid #012a2c;
      }

      .colName {
        border-bottom: 0.10667rem solid #012a2c;
      }
    }
  }

  .activeRule {
    position: relative;
    margin: 20px auto;
    width: 9.2rem;
    height: 11.8133rem;
    padding: 0.5333rem 0.2667rem;
    box-sizing: border-box;
    background-color: #1f1f1f;
    border-radius: 0.26667rem;
    // background: url("../../assets/img/Homeindex/activeTree.png") bottom right no-repeat;
    // background-size: ;
    .rule {
      color: #ffffff;
      font-size: 0.3733rem;
    }
    .giftList {
      color: #f1a510;
      font-size: 0.3733rem;
      margin: 0.6933rem 0 0.4533rem;
    }
    img {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 5.3333rem;
      height: 8.0533rem;
    }
    ul {
      li {
        position: relative;

        color: #fff;
        font-size: 0.3733rem;
        height: 0.6667rem;
        margin-left: 25px;
      }
      li::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -0.3733rem;
        content: '';
        display: block;
        width: 0.1333rem;
        height: 0.1333rem;
        border-radius: 50%;
        background-color: #ffffff;
      }
    }
  }
}
</style>
<style>
.van-popup .chooseRobot {
  background-color: red;
}
</style>
