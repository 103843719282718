<template lang="">
  <div>
    <div
      v-for="(item, index) in items"
      :key="index"
      class="t-xs relative overflow-hidden"
    >
      <div
        class="transition-all duration-200 absolute right-0 inset-y-0 z-0"
        :class="[
          isBuy ? 'bg-up/10' : 'bg-down/10',
          isReverse ? 'left-0' : 'right-0',
        ]"
        :style="{
          width: `${(item.amount / (isBuy ? data.BV1 : data.SV1)) * 100}%`,
        }"
      />
      <div
        class="flex items-center relative z-10 px-base h-[32px]"
        :class="isReverse ? 'flex-row-reverse' : ''"
      >
        <div
          class="flex-1"
          :class="[
            isBuy ? 'text-up' : 'text-down',
            isReverse ? 'text-right' : 'text-left',
          ]"
        >
          {{ item.price }}
        </div>
        <div class="flex-1" :class="isReverse ? 'text-left' : 'text-right'">
          {{ item.amount }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    isBuy: {
      type: Boolean,
      default: false,
    },
    isReverse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  watch: {
    data(val) {
      const _list = val.Depth[this.isBuy ? 'Buy' : 'Sell'] || [];
      this.items = _list.map((item, index) => {
        return {
          price: item[`${this.isBuy ? 'BP' : 'SP'}${index + 1}`],
          amount: item[`${this.isBuy ? 'BV' : 'SV'}${index + 1}`],
        };
      });
    },
  },
};
</script>
<style lang=""></style>
