<template>
  <div id="app" class="text-[16px] text-white bg-bgc">
    <router-view />
    <Kefu></Kefu>
    <van-dialog
      v-model="isShowDialog"
      :confirm-button-text="$t('common.confirm')"
      @confirm="toggleNoticeDialog(false)"
      class="appVant"
    >
      <van-swipe :autoplay="5000">
        <van-swipe-item v-for="(item, index) in noticeList" :key="index">
          <div class="text-center p-base priBoxBg ">
            <h3 class="font-bold">{{ item.Title }}</h3>
            <p class="note mt-[10px]">{{ item.CreateTime }}</p>
          </div>
          <div class="flex justify-center priBoxBg  ">
            <div class="h-[2px] w-[40px]  deepPri"></div>
          </div>

          <div
            class="p-base t-sm text-white leading-[32px] priBoxBg "
            v-html="item.Content"
          />
        </van-swipe-item>
      </van-swipe>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { ApiPath } from './api/path';
import Kefu from './components/Kefu/Kefu.vue';
import { EventBus } from './main';
let _this;
export default {
  components: {
    Kefu,
  },

  computed: {
    ...mapGetters(['isLogined', 'showNotice']),
  },
  data() {
    return {
      timer: null,
      noticeList: [],
      isShowDialog: false,
    };
  },

  watch: {
    isLogined(val) {
      if (val) {
        if (this.timer != null) {
          clearInterval(this.timer);
        }
        console.log('开始心跳');
        this.timer = setInterval(() => {
          this.sendHeartBeat();
        }, 30000);
      } else {
        if (this.timer != null) {
          clearInterval(this.timer);
        }
      }
    },
    showNotice(val) {
      this.isShowDialog = val;
    },
  },
  methods: {
    ...mapMutations({
      updateData: 'exchange/UPDATE_COIN',
      showNoticeDialog: 'app/SHOW_NOTICE',
    }),
    ...mapActions({
      sendHeartBeat: 'user/sendHeartBeat',
    }),

    toggleNoticeDialog(isShow) {
      this.showNoticeDialog(isShow);
    },

    async getNoticeList() {
      const { data, code, msg } = await this.$http.get(ApiPath.getNoticeList, {
        type: 2,
      });

      if (code == 0) {
        this.noticeList = data;
        console.log(data);
        if (data && data.length) {
          this.toggleNoticeDialog(true);
        }
      }
    },
  },
  created() {
    _this = this;
    this.getNoticeList();
  },

  mounted() {
    EventBus.$on('socketMessage', (data) => {
      // console.log(data);
      this.updateData(data);
    });
  },
};
</script>

<style lang="scss">
.appVant{
  .van-button__content{
    background-color: #383d6e;
  }
}
</style>
