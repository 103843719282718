<template>
  <page :title="$t('income.IncomeRecords')" class="bg-no-repeat bg-fixed bg-top priPageBg" :style="{
      backgroundImage: `url(${require('../../../assets/img/bg-blur.png')})`,
    }">
    <!-- <van-tabs
      v-model="active"
      :swipe-threshold="4"
      title-active-color="#ffe87e"
      line-width="0"
    >
      <van-tab :title="$t('income.Makeanorder')"></van-tab>
      <van-tab :title="$t('income.TeamRebates')"></van-tab>
      <van-tab :title="$t('income.BuyRobotRebates')"></van-tab>
      <van-tab :title="$t('income.Returnofprincipal')"></van-tab>
      <van-tab :title="$t('income.ForSale')"></van-tab>
    </van-tabs> -->
    <t-list-wrap
      @onLoad="getList"
      :list-loading="listLoading"
      :is-empty="list.length == 0"
    >
      <div class="p-base space-y-[12px]">
        <div
          class="card p-base flex items-center priBoxBg"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="flex-1">
            <h3 class="t-sm font-bold">{{ item.Remark }}</h3>
            <p class="note mt-[8px]">
              <span>{{ item.CreateTime | formatDateStr }}</span>
            </p>
          </div>
          <div class="t-lg text-up">
            + <span> {{ item.Amount }}</span>
          </div>
        </div>
      </div>
    </t-list-wrap>
  </page>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import { ApiPath } from '@/api/path';
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      active: 1,
      finished: true,
      loading: false,
      isLoading: false,
      listLoading: false,
      list: [],
    };
  },
  methods: {
    onLoad() {
      console.log('上拉');
    },
    onRefresh() {
      console.log('下拉');
    },
    async getList() {
      const params = {
        pageIndex: 1,
        pageSize: 30,
      };
      this.listLoading = true;
      const { data, code } = await this.$http.get(
        ApiPath.getRobotProfit,
        params
      );
      this.listLoading = false;
      if (code == 0) {
        this.list = data;
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
::v-deep .van-tabs .van-tabs__nav {
  background-color: transparent !important;
}
::v-deep .van-tabs .van-tab--active {
  background-color: #1f1f1f;
  border-radius: 0.16rem;
}
::v-deep .van-tabs {
  margin-top: 3px;
  .van-tab {
    height: 35px;
    padding: 0 15px !important;
  }
}
// ::v-deep
</style>
