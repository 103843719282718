import { appConfig } from '@/assets/js/config';

export function isValidEmail(email) {
  // 使用正则表达式进行邮箱地址验证
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailRegex.test(email);
}

export function getSymbolLogo(symbol) {
  const baseURL = appConfig.OSS_URL;
  let _coin = '';
  if (typeof symbol != 'string') {
    return require('../assets/img/Homeindex/BTC.png');
  }

  if (symbol.includes('/')) {
    _coin = symbol.split('/')[0];
  } else {
    _coin = symbol;
  }

  const lowercaseName = _coin.toLowerCase();

  // 拼接线上图片路径并返回
  return `${baseURL}/coinimage/${lowercaseName}.png`;
}
