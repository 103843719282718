<template>
  <div class="costPage">
    <Navbar
      NavLeftArrow="true"
      :NavMidText="$t('cost.ExpenseRecords')"
    ></Navbar>
    <van-list
      v-model="loading"
      :finished="finished"
      :finished-text="$t('noMore')"
      @load="onLoad"
    >
      <van-pull-refresh v-model="isLoading" @refresh="onRefresh">
        <div class="buyView">
          <div class="buyItem" v-for="(item, index) in 7" :key="index">
            <p class="title">{{ $t('cost.BuyRobot') }}</p>
            <p class="money">{{ $t('cost.Amounts') }}<span>-5.00</span></p>
            <p class="time">
              {{ $t('cost.Time') }}<span>2023-10-16 08:59:19</span>
            </p>
          </div>
        </div>
      </van-pull-refresh>
    </van-list>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      finished: true,
      loading: false,
      isLoading: false,
    };
  },
  methods: {
    onLoad() {},
    onRefresh() {},
  },
};
</script>

<style lang="scss" scoped>
.costPage {
  padding-top: 60px;
  background-color: #161616;
  padding-bottom: 10px;
  .buyView {
    margin-top: 12px;
    .buyItem {
      margin: 0 auto 15px;
      width: 9.2rem;
      height: 2.9067rem;
      box-sizing: border-box;
      padding: 15px;
      border-radius: 0.26667rem;
      background-color: #1f1f1f;
      font-size: 0.3733rem;
      color: #fff;
      .title {
        font-size: 0.3733rem;
        font-weight: 700;
      }
      .money {
        margin-top: 10px;
        opacity: 0.5;
        span {
          margin-left: 5px;
          color: #05ec15 !important;
        }
      }
      .time {
        margin-top: 10px;
        opacity: 0.5;
        span {
          margin-left: 5px;
        }
      }
    }
  }
}
</style>
