<template>
  <page :title="$t('Deposit.RechargeInstructions')" class="">
    <div class="text-gray-300 leading-[36px] p-base">
      <p>
        {{ $t('Deposit.t1') }}<br />
        {{ $t('Deposit.t2') }}<br />{{ $t('Deposit.t3') }}<br />{{
          $t('Deposit.t4')
        }}<br />
        {{ $t('Deposit.t5') }}<br />{{ $t('Deposit.t6') }}<br />{{
          $t('Deposit.t7')
        }}<br />
        {{ $t('Deposit.t8') }}<br />{{ $t('Deposit.t9') }}<br />{{
          $t('Deposit.t10')
        }}<br />{{ $t('Deposit.t11') }}
      </p>
    </div>
  </page>
</template>

<script>
// import exampleMarkdown from '@/assets/markdown/en/recharge.md';
export default {
  components: {},
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>

<style lang="scss" scoped>
.rechargePage {
  background-color: #161616;
  min-height: 100%;
  margin: 0;
  color: #fff;
  font-size: 0.37333rem;
  padding-top: 1.6rem;
  line-height: 2;

  .view {
    padding: 15px;
  }
}
</style>
