const en = {
  common: {
    runningCount: 'You currently have {count} robots in operation.',
    profitRecord: 'Profits',
    payPassword: 'Payment password',
    payPasswordTips: 'Please enter payment password',
    rePayPasswordTips: 'Please enter payment password again',
    payPwdNotSame: 'The two payment passwords are inconsistent',
    selectArea: 'Select area',
    invalidAddress: 'The address is invalid, please contact customer service',
    copied: 'Copied to clipboard',
    submitAndWait: 'Submitted, please wait for approval',
    selectCurrency: 'Select currency',
    selectWithdrawalMethod: 'Select withdrawal method',
    enterWithdrawalAddress: 'Enter withdrawal address',
    enterAmount: 'Enter amount',
    enterPassword: 'Enter password',
    withdrawalSubmitted:
      'Withdrawal information has been submitted. Please wait for approval.',
    selled: 'Transfer',
    cancel: 'Cancel',
    confirm: 'Confirm',
    selectLinkName: 'Select chain name',
    withdrawAddress: 'Withdrawal address',
    inputWithdrawAddress: 'Please enter address',
    transfer: 'Transfer',
    loosingText: 'Release and Refresh',
    pullingText: 'Pull to Refresh',
    isLoading: 'Loading',
    loginTips: 'Login to view more content',
    days: 'Days',
    hours: 'Hours',
    minutes: 'Minutes',
    seconds: 'Seconds',
    selectCoin: 'Select currency',
    depositAddress: 'Depost address',
    uploadImg: 'Upload certificate',
    inputAmount: 'Please enter amount',
    robotTradeList: 'Robot Trading Record',
    login: 'Login',
    login2: 'Login',
    phoneLogin: 'Phone login',
    emailLogin: 'Email login',
    reg: 'Register',
    lang: 'Language',
    phoneNumber: 'Phone number',
    forgetNumber: 'Forgot your password?',
    noCount: "Don't have an account yet?",
    signUp: ' Sign up',
    regForEmail: 'Email register',
    regForPhone: 'Phone register',
    robot: 'Robots',
    property: 'Property',
    about: ' About Us ',
    Deposit: 'Deposit',
    Withdrawal: 'Withdrawal',
    Help: ' Support Center ',
    OnlineService: 'Online  Service',
    number: 'Number',
    begin: 'Begin',
    Nodata: 'No data available',
    Confirmrecharge: 'Confirm recharge',
    Recharge: 'Recharge',
    day: 'Day',
    people: 'People',
    get: 'Get verification code',
    Level: 'Level',
    Clicktologin: 'Click to login',
    loginSeeMore: 'After logging in, you can view more content',
    robots: {
      activated: 'Running',
      buyType: 'Purchasing method',
      Contractpurchase: 'Contract purchase',
      Successfullysigned: 'Successfully signed',
      activate: 'Activate',
      PauseInProgress: 'On pause',
      Expired: 'Expired',
      slod: 'Slod',
    },
    kefu: {
      onlineKefu: 'Online customer service',
      RechargeCustomerService: 'Recharge Customer Service',
      AddKefu: 'Add customer service line number',
      CUSTOMERSERVICE: 'Contact customer service',
      Telegram: 'Telegraph',
      downApp: 'Download App',
    },
    Cancel: 'Cancel',
    verify: 'Verify',
    Pleaseselect: 'Please select',
    wallet: {
      Embodyin: 'Embody in',
      Handlingcharge: 'Handling charge',
      Actualarrival: 'Actual arrival',
      Paymentmethod: 'Payment method',
      Accountnumber: 'Account number',
      username: 'Username',
      Embodimenttime: 'Embodiment time',
    },
    sendCodeBtn: {
      emaiFail: 'Incorrect email format',
      codeSendEmai: 'The verification code has been sent to the email',
      phoneInfo: 'SMS verification code has been sent',
    },
  },
  noMore: 'Nothing more',
  Earnings: 'Earnings',
  input: {
    phoneEmail: 'Please enter your phone or email address',
    phone: 'Please enter your phone number',
    email: 'Please enter your email address',
    pwd: 'Please enter your password',
    pwdAgain: 'Please enter the password again',
    userName: 'Please enter the user name',
    verification: 'Please enter the verification code',
    getCode: 'Get verification code ',
    Sending: 'Sending',
    findPwd: 'Retrieve password',
    ResetPwd: 'Reset password',
    Verificationcode: 'Verification code',
    enterCode: 'Please enter the verification code',
    inviCode: 'Please enter the invitation code',
    haveCount: 'Already have an account?',
    pdwUnSame: 'Inconsistent input of two passwords',
    regSuccess: 'Registration successful',
    loginFail: 'Incorrect username or password',
  },
  home: {
    tabs: {
      Home: 'Home',
      Market: 'Market',
      Team: 'Team',
      Assets: 'Account',
    },
    hello: 'Hello',
    profit: 'Personal Profit Today ',
    robot: 'Robots',
    allRobot: 'Number of my robots：',
    tower: 'tower',
    Contracted: 'Contracted Purchase',
    Price: 'Price:',
    ValidDay: 'Valid period:',
    day: 'Day',
    StartPrice: 'Start-up Funds：',
    USDT: 'USDT',
    Service: 'Service',
    Invite: 'Invite Friends',
    Deposit: 'Deposit',
    Withdrawal: 'Withdrawal',
    OnlineService: 'Online  Service',
    Help: ' Help Guide ',
    about: ' About Us ',
    Partners: 'Partners',
    // 其他頁面
    // msg
    message: 'My Message',
    detailMsg: 'Message Details',
    // christmas
    chrInvi: ' Number of successful invitations',
    Redraws: 'Remaining draws',
    Prizes: 'Prizes',
    User: 'User',
    chrDesc: 'Description of the rules of the lottery',
    PrizeList: 'Prize List',
    back: 'Back',
  },
  robot: {
    MyRobot: 'My Robot',
    Total: 'Total',
    NoActivation: 'Unactive',
    Activation: 'Active',
    PauseInProgress: 'Pause',
    Expired: 'Expired',
    slod: 'Sold',
    Source: 'Source: ',
    SourceResult: 'Contracted Purchase',
    Price: ' Price：',
    ValidPeriod: 'Valid period：',

    day: 'Day',
    Hours: ' Hours ',
    Minutes: ' Minutes ',

    StartUpFunds: 'Start-up Funds：',
    USDT: 'USDT',
    DateAcquired: 'Date Acquired：',
    FinalActivationTime: 'Final activation time：',
    ActivationTime: 'Activation time：',
    RunTime: 'Run time：',
    UniqueCode: 'Unique Code：',
    Work: 'Working',

    CapitalTrading: 'Frozen capital: ',
    Pause: ' Pause ',

    // 遮罩层
    Forsalegift: 'For sale/gift ',
    UniqueCode1: 'Unique Code',
    OfficialPrice: 'Official Price',
    SalesValue: 'Sales Value',
    p1: 'Please enter the user name of the other party',
    p2: ' Transfer to the property please review the information carefully, such as operation errors, can not be retrieved through the system or platform customer service. ',
    Confirm: 'Confirm',
  },
  share: {
    InviteFriends: 'Invite Friends',
    rules: 'Invitation Rules',
    title: 'Invite friends to earn coins together',
    desc: 'Upgrade the rebate, invite your friends to share up to 40% trading rebate, and be the boss together with your friends!',
    InvitationCode: 'Invitation Code',
    copy: 'copy',
    link: 'Recommended Links',
    InvitationRules: 'Invitation Rules',
  },
  Deposit: {
    Deposit: 'Deposit',
    RechargeInstructions: 'Recharge Instructions',
    WalletBalance: 'Wallet Balance',
    ExchangeRates: 'Exchange Rates',
    Amounts: 'Amounts',
    Depositamount: 'Deposit amount：',
    Price: 'Price',
    StartUpCapital: 'Start-up capital',
    t1: '1. Download DEEPSPEED—open',
    t2: ' 2. Click account in the lower right corner - register now - email registration - enter verification code - mobile phone number - mobile phone verification code',
    t3: '3. Set the PIN code, you can use the PIN code to log in to DEEPSPEED in the future',
    t4: 'Verified:',
    t5: 'Immediate verification—identity verification—upload the front of the ID card—upload the back of the ID card—upload the hand-held identity certificate on the handwritten note (write on the note: apply for DEEPSPEED account use, date, signature)—face recognition—complete identity verification—bind bank account — send',
    t6: 'Deposit:',
    t7: 'In the lower right corner, click Wallet—Click Add Value—Choose the storage method—Copy account number—After the transfer is successful, you can check the Taiwan dollar balance in the fund overview',
    t8: 'Buy USDT:',
    t9: 'Click the market—click TWD—choose USDT—click buy—point market price—100%—you can buy—the wallet can check the amount of USDT',
    t10: 'Withdraw to the platform;',
    t11: 'Copy our platform wallet address—enter DEEPSPEED—click wallet—choose withdrawal—choose USDT—paste our platform address—enter quantity—confirm—details screenshot',
  },
  withdraw: {
    Accountinformation: 'Account information',
    Selectacurrency: 'Select a currency',
    Selectpresentationmode: ' Select a withdrawal method',
    Confirmationembodiment: 'Confirmation of withdrawal',
    Paymentpassword: 'Please enter your payment password',

    WithdrawalMethod: 'Withdrawal Method',
    WalletBalance: 'Wallet Balance',
    ExchangeRates: 'Exchange Rates',
    Amounts: 'Amounts ',
    info: 'Please enter the withdrawal amount',
    t1: 'Withdrawal Instructions Tips',
    t2: ' The minimum withdrawal amount is 10 USDT. ',
    t3: 'USDT-TRC20 transaction fee is 1USDT, USDT-ERC20 transaction fee is 2USDT. It takes 0-2 hours, and it may take additional time, which is related to the hash rate of the network.',
    Recommend: 'Recommend',
    ERC20TIPS: 'ERC20 TIPS',
    Confirm: 'Confirm',
    Selectwithdrawalmethod: 'Select withdrawal method',
    Next: 'Next',
    Cancel: 'Cancel',

    WalletAddressBinding: 'Wallet address binding',
    walletAddress: 'Please enter your wallet address',
    t4: 'Instructions for wallet address withdrawal binding: Please check whether your TRC wallet address is wrong. If the transfer to the wrong address fails to arrive, we will not be responsible.  The USDT address of TRC20 starts with "Tx"  Each account can only be bound to one USDT address',
  },
  help: {
    HelpCenter: 'Support Center',
    commonProblem: 'DeepSpeedPro Robot Features',
    q1: ' DEEPSPEED robot features ',
    p1: `Automation: DeepSpeedPro robot can automatically process virtual currency transactions, automatically adjusting trading strategies based on market trends and investors' risk tolerance.`,
    p2: `Data Analysis: DeepSpeedPro robot can predict and analyze market trends, virtual currency trends, etc., through big data analysis to help investors make more informed investment decisions.`,
    p3: `Efficiency: DeepSpeedPro robot enable efficient virtual currency transactions, reducing the time and cost of manual operations.`,
    p4: `Learning Ability: DeepSpeedPro robot are able to continuously learn and optimize trading strategies based on the results of trading and changes in the market. This can improve the system's resilience and long-term profitability.`,
    p5: `Monitoring Risks: DeepSpeedPro robot can monitor market risks and investment risks in real time, adjust trading strategies in time, and reduce risks.`,
    p6: `Low Threshold: DeepSpeedPro robot have a low investment threshold, allowing more people to participate in the investment.`,

    p7: `Convenient and fast: DEEPSPEED robot can realize 24 hours non-stop investment operation, convenient and fast.`,
    q2: `DEEPSPEED robot advantage`,
    p8: `Pick it up quickly. Your withdrawal will arrive at your fund account within 2 hours to maximize the safety of your funds.`,
    p9: `Safe and efficient investment environment. 100% daily profit, zero investment risk, to ensure your long-term, stable and sustainable profits.`,
    p10: `Convenient storage and withdrawal channels. DEEPSPEED supports USDT storage and retrieval.`,
    p11: `Profits far exceed those of traditional financial investments. The daily income reaches 1.4% to 2.5%, and the monthly income reaches 30% to 55%.`,
    p12: `AI smart trading. DEEPSPEED automated trading helps customers make money without having to keep track of market dynamics.`,
    p13: `Diversify your income. A variety of profit ways, become an agent can achieve low investment and high return.`,
  },
  trend: {
    Market: 'Market',
    CurrentTotal: ' You currently have',
    t1: ' robots running automatically',
    CurrentRobotLevel: 'Current Robot Level：',
    RoboticExpirationDate: 'Robot Expiration Date',
    Status: 'Status：',
    Regular: 'Regular',
    StartUpFunds: 'Start-up Funds：',
    USDT: 'USDT',
    Earnings: 'Earnings：',
    GotoExchange: 'Go to Exchange',
  },
  team: {
    MyTeam: 'My Team',
    ReferralRules: 'Referral Rules',
    CurrentExperience: 'Current Experience：',
    MemberInformation: 'Member Information',
    TeamRewards: 'Team Rewards',
    Today: 'Today',
    Thisweek: 'This week',
    Total: 'Total',
    more: 'More',
    Level1Agent: 'Level 1 Agent',
    Level2Agent: 'Level 2 Agent',
    Level3Agent: 'Level 3 Agent',
    AgentRebateCommission: 'Agent Rebate Commission',
  },
  my: {
    InvitationCode: 'Invitation Code',
    Language: 'Language',
    MyWalletBalance: 'My Wallet Balance',
    Capitaltrading: 'Frozen Funds',
    Totalofpersonalprofits: 'Total of personal profits',
    Todaysagentprofits: 'Today‘s agent profits',
    Totalofagentprofits: 'Cumulative income',
    Robotscommission: 'Robot’s commission',
    r1: 'Robot Trading History',
    r2: 'Income Records',
    r3: 'Expense Records',
    r4: 'Withdrawal Records',
    r5: 'Deposit Records',
    Viewmyrobots: 'View my robots',
    b1: 'My Bank Card',
    b2: 'My Team',
    b3: 'Agent Rebate Commission',
    b4: 'Online  Service',
    b5: 'Change Login Password',
    b6: 'Download APP',
    b7: 'Change Payment Password',
    logout: 'Logout',
    RobotTradingHistory: 'Robot Trading History',
  },
  income: {
    IncomeRecords: 'Income Records',
    Makeanorder: 'Make an order',
    TeamRebates: 'Team Rebates',
    BuyRobotRebates: 'Buy Robot Rebates',
    Returnofprincipal: 'Return of principal',
    ForSale: 'For Sale',
    Amounts: 'Amounts',
    Time: 'Time',
  },
  cost: {
    ExpenseRecords: 'Expense Records',
    BuyRobot: 'Buy Robot',
    Amounts: 'Amounts',
    Time: 'Time',
  },
  withdrawRecord: {
    WithdrawRecords: 'Withdrawal Records',
    Feedback: 'Feedback error',
    Withdrawalamount: 'Withdrawal amount',
    Handlingfee: 'Handling fee',
    WithdrawalChannel: 'Withdrawal Channel',
    ApplicationTime: 'Application Time',
  },
  DepositRecords: {
    DepositRecords: 'Deposit Records',
  },
  bank: {
    bank: 'My Bank Card',
    Selectwithdrawalmethod: 'Select withdrawal method',
    Recommend: 'Recommend',
    Cancel: 'Cancel',
    Next: 'Next',
  },
  loginpwd: {
    ChangeLoginPassword: 'Change Login Password',
    OldPassword: 'Old Password',
    p1: 'Please enter the original password',
    NewPassword: 'New Password',
    p2: 'Please enter a new password',
    ConfirmNewPassword: 'Confirm New Password',
    p3: 'Please enter to confirm new password',
    Confirm: 'Confirm',
    p4: 'The two password entries are inconsistent',
  },
  ContractedPurchase: {
    ContractedPurchase: 'Contracted Purchase',
    Price: 'Price',
    Expecteddailyearnings: 'Expected daily earnings',
    Validperiod: 'Validity period',
    StartupFunds: 'Start-up costs',
    tower: 'Tower',
    CurrentBalance: 'Current Balance',
    USDT: 'USDT',
    p1: 'Warm Tip：',
    p2: 'Robot No. 1',
    p3: 'Robots can be purchased and transferred. Transferred robots are limited to unactivated and unexecuted robots.',
    p4: 'Each level of robots can run more than one at the same time, please ensure that the account operation principal is sufficient.',
    p5: 'Robots are time-sensitive, so please run them fully to earn more.',
    p6: 'The higher the robot level, the more efficient the time spent making orders and the better the capture of earnings.',
    Payments: 'Payments',
    day: 'Day',
  },
  aboutUs: {
    intro: 'Intro',
    VisionandMission: 'Vision and Mission',
    p1: `DEEPSPEED is a technology company focused on the research and development of artificial intelligence technology, headquartered in Denver, Colorado, USA. DEEPSPEED combines artificial intelligence and big data analytics technology to provide investors with a smarter, more efficient and robust financial trading experience, resulting in stable and long-term returns.`,
    p2: `DEEPSPEED's vision is to become the world's leading smart financial trading platform, providing investors with the best investment strategies and trading tools to help them achieve financial freedom and long-term stable capital appreciation. To achieve this vision, DEEPSPEED's mission is to leverage artificial intelligence and big data analytics to provide comprehensive portfolio management and trade execution services to minimize risk and increase returns. DEEPSPEED will automatically generate the best portfolio allocation scenario based on the investor's risk tolerance, investment objectives and time horizon, and provide instant market analysis and trade execution recommendations. DEEPSPEED will also provide comprehensive education and training resources to help investors improve their financial knowledge and skills to better understand market trends and investment strategies. We believe that only investors with sufficient knowledge and skills can make informed investment decisions and remain calm and confident in market volatility.`,
    p3: `DEEPSPEED is committed to protecting the interests of investors and the safety of their funds. We use the most advanced encryption technology and security measures to ensure that investors' personal information and transaction data are properly protected. We only work with compliance bodies to ensure the legality and compliance of the platform and to comply with all relevant legal and regulatory requirements.`,
    p4: ' Finally, we will continue to innovate and improve our platform to meet the changing needs of investors and market conditions. We will pay close attention to the latest developments in technology and finance and apply them to our platform to provide more diversified and personalized services. ',
    p5: `Under DEEPSPEED's leadership, investors will be able to more easily achieve their local goals and achieve more stable and sustainable returns in the investment market. We look forward to working with you to usher in a new era of smart finance at Al.`,
  },
  record: {
    recharge: 'recharge',
    withdraw: 'withdraw',
    SecondContractOrder: 'Second contract order',
    SecondContractSettlement: 'Second contract settlement',
    CoinTransaction: 'Coin transaction',
    OrderInPledge: 'Order in pledge',
    PledgeInterest: 'Pledge interest',
    PledgeToReturnThePrincipal: 'Pledge to return the principal',
    PledgeMinusLiquidatedDamages: 'Pledge minus liquidated damages',
    NewCurrencySubscription: 'New currency subscription',
    SingaporeDollarSettlement: 'Singapore dollar settlement',
    FlashTransferOut: 'Flash transfer out',
    FlashTransfer: 'Flash transfer',
    BuyRobot: 'Buy robot',
    FreezeRobot: 'Freeze the robot to make an order',
    RobotDailYrevenue: 'Robot daily revenue',
    RobotsDoSinglePrincipalReturn: 'Robots do single principal return',
    TeamRebate: 'Team rebate',
    RobotForSale: 'Robot for sale',
  },
  "冻结中":"Freezing"
};
export default en;
