import Vue from 'vue';
Vue.component('t-button', () => import('./Button.vue'));
Vue.component('t-icon', () => import('./Icon.vue'));
Vue.component('t-loading', () => import('./Loading.vue'));
Vue.component('t-empty', () => import('./Empty.vue'));
Vue.component('t-selector', () => import('./Selector.vue'));
Vue.component('t-input', () => import('./Input.vue'));
Vue.component('chg-rate', () => import('./ChgRate.vue'));
Vue.component('t-cell', () => import('./Cell.vue'));
Vue.component('t-tab', () => import('./Tabs.vue'));
Vue.component('page', () => import('./Page.vue'));
Vue.component('t-img', () => import('./Image.vue'));
Vue.component('t-scroll', () => import('./Scroll.vue'));
Vue.component('t-list-wrap', () => import('./ListWrap.vue'));
Vue.component('t-markdown', () => import('./Markdown.vue'));
