<template>
  <page :title="$t('DepositRecords.DepositRecords')" class="bg-no-repeat bg-fixed bg-top priPageBg" :style="{
    backgroundImage: `url(${require('../../../assets/img/bg-blur.png')})`,
  }">
    <t-list-wrap
      @onLoad="getList"
      :list-loading="isLoading"
      :is-empty="list.length == 0"
    >
      <div class="px-[16px] space-y-[12px]">
        <div
          class="priBoxBg p-[16px] rounded text-white "
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="flex items-center pb-[12px] border-b border-gray-600">
            <div class="flex-1">
              <div class="text-[16px] font-bold">
                {{ $t('home.Deposit') + ` ${item.Symbol}` }}
              </div>
              <div class="note mt-[6px]">{{ item.CreateTime }}</div>
            </div>
            <div class="text-[18px] font-bold text-red-500">
              + {{ item.Amount }}
            </div>
          </div>
        </div>
      </div>
    </t-list-wrap>
  </page>
</template>

<script>
import { ApiPath } from '@/api/path';
import Navbar from '@/components/Navbar/Navbar.vue';
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      finished: true,
      loading: false,
      isLoading: false,
      list: [],
    };
  },
  methods: {
    onLoad() {},
    onRefresh() {},
    async getList() {
      const params = {
        pageIndex: 1,
        pageSize: 30,
        status: this.status,
      };
      this.isLoading = true;
      const { data, code } = await this.$http.get(
        ApiPath.getRechargeRecordList,
        params
      );
      this.isLoading = false;
      if (code == 0) {
        this.list = data;
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.depositRecordPage {
  padding-top: 60px;
  background-color: #161616;
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 10px;
  .depositRecordView {
    margin-top: 12px;
    .depositRecordItem {
      margin: 0 auto 15px;
      width: 9.2rem;
      box-sizing: border-box;
      padding: 15px;
      border-radius: 0.26667rem;
      background-color: #1f1f1f;
      font-size: 0.3733rem;
      color: #fff;
      .title {
        font-size: 0.4267rem;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .row {
        margin-top: 10px;
        opacity: 0.6;
        span {
          margin-left: 5px;
          // color: #05ec15!important;
        }
      }
    }
  }
}
</style>
