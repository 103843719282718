<template>
    <div class="proxyPage">
        <Navbar NavLeftArrow="true" :NavMidText="$t('share.InvitationRules')"></Navbar>
        <!-- <img src="../../assets/img/Homeindex/sharePage/shareRule.jpg" alt=""> -->
    </div>
</template>

<script>
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
        components:{
            Navbar
        }
    }
</script>

<style lang="scss" scoped>
.proxyPage{
    padding-top: 60px;
    width: 100%;
    background-color: #161616;
    img{
    width: 100%;

    }
}
</style>