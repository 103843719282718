import axios from 'axios';
import { Toast } from 'vant';
import Store from '../utils/storage';
import Vue from 'vue';
import { appConfig } from '@/assets/js/config';

class HttpClient {
  constructor() {
    this.instance = axios.create({
      baseURL: appConfig.API_URL,
      timeout: 5000,
    });

    // 添加请求拦截器
    this.instance.interceptors.request.use(
      (config) => {
        // 在请求发送前，您可以在这里进行全局处理，如添加认证标头等
        const token = Store.getToken();
        config.headers['Authorization'] = token ? 'Bearer ' + token : '';
        config.headers['language'] = Store.getLang();
        return config;
      },
      (error) => {
        // 处理请求错误
        return Promise.reject(error);
      }
    );

    // 添加响应拦截器
    this.instance.interceptors.response.use(
      (response) => {
        // 在接收响应后，您可以在这里进行全局处理，如处理响应数据
        return response;
      },
      (error) => {
        // 处理响应错误
        return Promise.reject(error);
      }
    );
  }

  async get(url, params) {
    try {
      const response = await this.instance({
        url: url,
        method: 'get',
        params,
      });
      return response.data;
    } catch (error) {
      console.error('请求出错:', error);
      throw error;
    }
  }

  async post(url, data) {
    try {
      const response = await this.instance.post(url, data);
      return response.data;
    } catch (error) {
      console.error('请求出错:', error);
      throw error;
    }
  }
}

const httpClient = new HttpClient();

export default httpClient;

// 挂载到Vue实例上
export const HttpClientPlugin = {
  install(Vue) {
    // 将HttpClient类添加到Vue原型中
    Vue.prototype.$http = httpClient;
  },
};
