<template lang="">
  <div>
    <!-- 我的机器人 -->
    <div class="">
      <div class="flex items-center pb-[12px]">
        <h2 class="flex-1 text-[20px]">{{ $t('home.robot') }}</h2>

        <!-- <div
          class="flex items-center text-[12px] rounded-[4px] overflow-hidden ring-[1px] ring-primary"
        >
          <span
            class="h-[30px] px-[8px] flex items-center bg-primary text-white"
            >{{ $t('common.number') }}</span
          >
          <div class="px-[8px] text-primary" @click="$router.push('/robot')">
            {{ robotList.length || 0 }} {{ $t('home.tower') }}
          </div>
        </div> -->
      </div>

      <t-loading v-if="robotsLoading" />

      <div class="card flex justify-center" v-else-if="robotList.length == 0">
        <t-empty />
      </div>
      <div class="space-y-[12px]" v-else>
        <div
          class="card overflow-hidden"
          v-for="(item, index) in robotList"
          :key="index"
          @click="getRobot(item.Id)"
        >
          <div class="flex items-center px-base py-[10px] bg-[#383d6e] ">
            <img
              :src="item.CoverImage"
              class="w-[36px] h-[36px] rounded-full mr-[6px]"
              alt=""
            />
            <div class="flex-1 title-base text-[16px]">
              {{ item.RobotName }}
            </div>
            <div class="note">
              <span class="mr-[4px] text-white">{{
                $t('ContractedPurchase.Expecteddailyearnings')
              }}</span>
              <span class="text-secondary"
                >{{ item.DailyProfit }} {{ globalSymbol }}</span
              >
            </div>
          </div>
          <div class="grid grid-cols-3 p-base bg-[#2e3360] text-white">
            <div class=" text-center">
              <div class="note min-h-[28px]">
                {{ $t('ContractedPurchase.Price') }}({{ globalSymbol }})
              </div>
              <div class="mt-[4px]">{{ item.Price | toFixedStr }}</div>
            </div>
            <div class="text-center">
              <div class="note min-h-[28px]">
                {{ $t('ContractedPurchase.Validperiod') }}({{ $t('common.days') }})
              </div>
              <div class="mt-[4px]">{{ item.Days }}</div>
            </div>
            <div class="text-center">
              <div class="note min-h-[28px] whitespace-nowrap">
                {{ $t('ContractedPurchase.StartupFunds') }}({{
                  $t('home.USDT')
                }})
              </div>
              <div class="mt-[4px]">{{ item.OrderPrice }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 购买弹出层 -->
    <van-popup v-model="show" position="bottom" @close="popClosed">
      <div class="priPageBg">
        <div class="p-6 flex justify-center" v-if="robotLoading">
          <van-loading />
        </div>
        <div class="" v-else>
          <div class="flex items-center p-base">
            <div class="flex-1">
              <img :src="curRobot.CoverImage" class="w-[40px]" alt="" />
            </div>
            <van-icon name="cross" size="22" @click="show = false" />
          </div>
          <div class="p-base pt-0 max-h-[600px] overflow-y-auto">
            <div class="flex items-center">
              <h3 class="t-lg font-bold mt-[12px] flex-1">
                {{ curRobot.RobotName }}
              </h3>
              <div class="text-up">
                {{ $t('ContractedPurchase.ContractedPurchase') }}
              </div>
            </div>
            <div class="my-[16px]">
              <div class="grid grid-cols-2 gap-[14px] pt-[10px] text-up">
                <div class="">
                  <h5 class="note mb-[6px]">
                    {{ $t('ContractedPurchase.Price') }}
                  </h5>
                  <div class="">
                    <span>{{ curRobot.Price }}</span>
                    <span class="text-gray-400 t-sm ml-[4px]">{{
                      globalSymbol
                    }}</span>
                  </div>
                </div>
                <div class="text-right">
                  <h5 class="note mb-[6px]">
                    {{ $t('ContractedPurchase.Expecteddailyearnings') }}
                  </h5>
                  <div class="">
                    <span>{{ curRobot.DailyProfit }}</span>
                    <span class="text-gray-400 t-sm ml-[4px]">{{
                      globalSymbol
                    }}</span>
                  </div>
                </div>
                <div class="">
                  <h5 class="note mb-[6px]">
                    {{ $t('ContractedPurchase.Validperiod') }}
                  </h5>
                  <div class="">
                    <span>{{ curRobot.Days }}</span>
                    <span class="text-gray-400 t-sm ml-[4px]">{{
                      $t('ContractedPurchase.day')
                    }}</span>
                  </div>
                </div>
                <div class="text-right">
                  <h5 class="note mb-[6px]">
                    {{ $t('ContractedPurchase.StartupFunds') }}
                  </h5>
                  <div class="">
                    <span>{{ curRobot.OrderPrice }}</span>
                    <span class="text-gray-400 t-sm ml-[4px]">{{
                      $t('ContractedPurchase.USDT')
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="">
              <div class="card priBoxBg  p-base flex items-center">
                <div class="flex items-center space-x-2 flex-1">
                  <button
                    class="w-[24px] h-[24px] bg-primary rounded-full flex justify-center items-center"
                    @click="setCount('minus')"
                  >
                    <t-icon icon="minus" class="t-xl" />
                  </button>
                  <div class="t-xl px-2 flex-1 text-center">
                    {{ buyCount }}
                  </div>
                  <button
                    class="w-[24px] h-[24px] bg-primary rounded-full flex justify-center items-center"
                    @click="setCount('add')"
                  >
                    <t-icon icon="plus" class="t-xl" />
                  </button>
                </div>
                <span class="ml-2">{{ $t('ContractedPurchase.tower') }}</span>
              </div>
              <div class="mt-base flex items-center">
                <div class="t-sm text-gray-400">
                  {{ $t('ContractedPurchase.CurrentBalance') }}
                </div>
                <div class="flex-1 text-right">
                  {{ userInfo?.Balance }}
                  <span class="text-gray-400">{{ globalSymbol }}</span>
                </div>
              </div>

              <div class="py-3">
                <t-button
                  class="px-4 whitespace-nowrap w-full"
                  :loading="submiting"
                  @click="addOrder"
                >
                  <span class="">{{ $t('ContractedPurchase.Payments') }}</span>
                  <span class="px-[2px]">{{
                    (curRobot.Price * buyCount) | toFixedStr
                  }}</span>
                  <span>{{ globalSymbol }}</span>
                </t-button>
              </div>
            </div>
            <div class="py-base">
              <t-markdown fileName="buyTips.md" />
            </div>
            <!-- <div class="nt-tip">
              <div class="hd">{{ $t('ContractedPurchase.p1') }}</div>
              <ul class="t-sm text-gray-400 list-decimal pl-[16px] space-y-[4px]">
                <li>{{ $t('ContractedPurchase.p3') }}</li>
                <li>{{ $t('ContractedPurchase.p4') }}</li>
                <li>{{ $t('ContractedPurchase.p5') }}</li>
                <li>{{ $t('ContractedPurchase.p6') }}</li>
              </ul>
            </div> -->
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
let _this;
import MyRobot from '@/components/MyRobot/MyRobot.vue';
import { mapActions, mapGetters } from 'vuex';
import { ApiPath } from '@/api/path';
import { Toast } from 'vant';
export default {
  components: {
    MyRobot,
  },
  data() {
    return {
      show: false,
      curRobot: {},
      robotLoading: false,
      buyCount: 0,
      submiting: false,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters([
      'globalSymbol',
      'wallet',
      'robotList',
      'isLogined',
      'userInfo',
      'robotsLoading',
    ]),
  },
  methods: {
    ...mapActions({
      getWallet: 'wallet/getWallet',
      getRobotList: 'robots/getRobotList',
      getUserInfo: 'user/getUserInfo',
    }),
    setCount(type) {
      if (type == 'add') {
        this.buyCount++;
      } else {
        this.buyCount = this.buyCount > 0 ? this.buyCount - 1 : this.buyCount;
      }
    },
    popClosed() {
      this.buyCount = 0;
    },

    async getRobot(id) {
      if (!this.isLogined) {
        this.$router.push('/login');
        return;
      }
      this.show = true;
      const params = {
        id,
      };
      this.robotLoading = true;
      try {
        const { data, code } = await this.$http.get(ApiPath.getRobot, params);
        this.robotLoading = false;
        if (code == 0) {
          this.curRobot = data;
        }
      } catch (error) {
        this.robotLoading = false;
      }
    },

    async addOrder() {
      this.submiting = true;
      try {
        const { data, code, msg } = await this.$http.post(
          ApiPath.addRobotOrder,
          {
            Quantity: this.buyCount,
            RobotId: this.curRobot.Id,
          }
        );
        this.submiting = false;
        if (code == 0) {
          Toast.success(_this.$t('common.succeed'));
          this.show = false;
          this.getUserInfo();
        } else {
          Toast.fail(msg);
        }
      } catch (error) {
        this.submiting = false;
      }
    },
  },
  created() {
    _this = this;
    this.getRobotList();
    if (this.isLogined) {
      // 获取全局币种钱包
      // this.getWallet();
      this.getUserInfo();
    }
  },
};
</script>
<style lang="scss"></style>
