<template lang="">
  <page
    :title="$t('已售机器人')"
    :is-loading="isLoading"
    :is-empty="list.length == 0"
  >
    <div class="p-base">
      <div
        class="card p-base flex"
        v-for="(item, index) in sellRobotList"
        :key="index"
      >
        <div class="flex-1">Hello</div>
      </div>
    </div>
  </page>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return {
      list: [],
      isLoading: false,
    };
  },
  computed: {},
  methods: {
    async getSellRobotList() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
      };
      this.isLoading = false;
      try {
        const { data, code } = await http.get(ApiPath.getSellRobotList, params);
        this.isLoading = false;
        if (code == 0) {
          this.list = data;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getSellRobotList();
  },
};
</script>
<style lang=""></style>
