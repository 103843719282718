<template lang="">
  <div class="pb-[50px] box-border  min-h-screen bg-[#121633]">
    <slot />
    <div
      class="flex h-[56px] items-center fixed inset-x-0 bottom-0 bg-[#6067a150] backdrop-blur-lg"
    >
      <router-link
        :to="item.link"
        class="flex flex-col items-center flex-1 overflow-hidden  justify-center text-white"
        :class="$route.fullPath == item.link ? ' ' : 'opacity-50'"
        v-for="(item, index) in navList"
        :key="index"
      >
        <!-- <t-icon :icon="item.icon" class="text-[22px]" /> -->
        <img :src="item.icon" alt="">
        <div class="mt-[3px] text-[12px] transform scale-90">
          {{ item.label }}
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
// var _this=null
export default {
  mounted() {},
  computed: {
    navList() {
      return [
        { icon: require('../assets/img/svg/V2/nav/home.svg') , label: this.$t('home.tabs.Home'), link: '/' },
        {
          icon: require('../assets/img/svg/V2/nav/robot.svg'),
          label: this.$t('common.robot'),
          link: '/trend',
        },
        // { icon: 'users', label: this.$t('home.tabs.Team'), link:  '/team' },
        { icon:require('../assets/img/svg/V2/nav/revenue.svg'), label: this.$t('common.profitRecord'), link: '/robots' },
        { icon: require('../assets/img/svg/V2/nav/wallet.svg'), label: this.$t('home.tabs.Assets'), link: '/my' },
      ];
    },
  },
  created() {
    // _this=this
  },
};
</script>
<style lang=""></style>
