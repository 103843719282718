// 1. 导入 Vue 和 Vuex
import Vue from 'vue';
import Vuex from 'vuex';
import getters from './getters';

// 2. 将 Vuex 安装为 Vue 的插件
Vue.use(Vuex);

const modulesFiles = require.context('./modules', true, /\.js$/);

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1');
  const value = modulesFiles(modulePath);
  modules[moduleName] = value.default;
  return modules;
}, {});

const store = new Vuex.Store({
  modules,
  getters,
});

// 4. 向外共享 Store 的实例对象
export default store;
