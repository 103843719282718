<template>
  <div
    class="registPage min-h-screen  pt-[60px] pb-[50px] bg-no-repeat bg-fixed bg-top priPageBg	"
    :style="{
     backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
    }"
  >
    <Navbar
      :NavLeftArrow="true"
      :NavLeftText="$t('common.signUp')"
      :BGTransparent="true"
    >
      <div slot="rightSolt">
        <Language class="" />
      </div>
    </Navbar>
    <div class="max-w-[320px] mx-auto">
      <t-tab
        :tabs="[$t('common.regForEmail'), $t('common.regForPhone')]"
        @tab-change="tabChange"
        :tab-index="curTabIndex"
        class="mt-4"
      />
      <div class="space-y-2 mt-[16px]">
        <t-input
          glass
          :placeholder="isEmail ? $t('input.email') : $t('input.phone')"
          v-model="formData.UserName"
        >
          <div class="" slot="before" v-if="!isEmail">
            <select-area :areaCode="this.areaCode"  @on-confirm="(val) => (this.areaCode = val)" />
          </div>
        </t-input>

        <t-input
          glass
          :placeholder="$t('input.enterCode')"
          v-model="formData.VerifyCode"
        >
          <div class="text-blue-400 text-[14px] pr-[6px]" slot="after">
            <send-code-btn
              :countTime="60"
              :email="isEmail ? formData.UserName : ''"
              :mobile="!isEmail ? formData.UserName : ''"
            />
          </div>
        </t-input>
        <!-- 登录密码 -->
        <t-input
          glass
          type="password"
          :placeholder="$t('input.pwd')"
          v-model="formData.Password"
        ></t-input>
        <t-input
          glass
          type="password"
          :placeholder="$t('input.pwdAgain')"
          v-model="repassword"
        ></t-input>
        <!-- 支付密码 -->
        <t-input
          glass
          type="password"
          :placeholder="$t('common.payPasswordTips')"
          v-model="formData.PayPassword"
        ></t-input>
        <t-input
          glass
          type="password"
          :placeholder="$t('common.rePayPasswordTips')"
          v-model="rePaypassword"
        ></t-input>
        <t-input
          glass
          :placeholder="$t('input.inviCode')"
          v-model="formData.InviteCode"
        ></t-input>
        <div class="flex justify-center" @click="confirmSignup">
          <t-button class="w-full" :loading="isLoading">{{
            $t('common.signUp')
          }}</t-button>
        </div>
        <p class="t-sm text-center pt-[16px]">
          <span>{{ $t('input.haveCount') }}</span>
          <span @click="$router.push('/login')" class="text-primary">
            {{ $t('common.login2') }}</span
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
let _this;
import Navbar from '@/components/Navbar/Navbar.vue';
import ChooseLang from '@/components/ChooseLang/ChooseLang.vue';
import LoginType from '@/components/LoginType/LoginType.vue';
import FormInput from '@/components/FormInput/FormInput.vue';
import { Toast } from 'vant';
import { ApiPath } from '@/api/path';
import SendCodeBtn from '@/components/SendCodeBtn.vue';
import Language from '@/components/Language.vue';
import SelectArea from '@/components/SelectArea.vue';
export default {
  data() {
    return {
      repassword: '',
      rePaypassword: '',
      curTabIndex: 0,
      isLoading: false,
      areaCode: '',
      formData: {
        UserName: '',
        Password: '',
        PayPassword: '',
        InviteCode: '',
        VerifyCode: '',
        Type: 0,
      },
    };
  },
  components: {
    Navbar,
    ChooseLang,
    LoginType,
    FormInput,
    SendCodeBtn,
    Language,
    SelectArea,
  },
  computed: {
    isEmail: () => _this.curTabIndex == 0,
  },
  methods: {
    tabChange(tab) {
      // console.log(tab);
      this.curTabIndex = tab;
      this.formData.UserName = '';
      this.formData.Password = '';
      this.formData.PayPassword = '';
      this.repassword = '';
      this.rePaypassword = '';
    },

    async confirmSignup() {
      if (!this.formData.UserName) {
        Toast.fail(
          this.isEmail ? _this.$t('input.email') : _this.$t('input.phone')
        );
        return;
      }
      if (!this.formData.VerifyCode) {
        Toast.fail(_this.$t('input.verification'));
        return;
      }

      // 登录密码
      if (!this.formData.Password) {
        Toast.fail(_this.$t('input.pwd'));
        return;
      }
      if (!this.repassword) {
        Toast.fail(_this.$t('input.pwdAgain'));
        return;
      }
      if (this.repassword != this.formData.Password) {
        Toast.fail(_this.$t('input.pdwUnSame'));
        return;
      }

      // 支付密码
      if (!this.formData.PayPassword) {
        Toast.fail(_this.$t('common.payPasswordTips'));
        return;
      }
      if (!this.rePaypassword) {
        Toast.fail(_this.$t('common.rePayPasswordTips'));
        return;
      }
      if (this.rePaypassword != this.formData.PayPassword) {
        Toast.fail(_this.$t('common.payPwdNotSame'));
        return;
      }

      const _params = {
        ...this.formData,
        Type: this.curTabIndex,
        UserName: this.isEmail
          ? this.formData.UserName
          : this.areaCode + this.formData.UserName,
      };
      this.isLoading = true;
      try {
        const { data, code, msg } = await this.$http.post(
          ApiPath.register,
          _params
        );
        this.isLoading = false;
        if (code == 0) {
          Toast.success(_this.$t('input.regSuccess'));
          this.$router.push('/login');
        } else {
          Toast.fail(msg);
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  created() {
    _this = this;
    this.formData.InviteCode = this.$route.query.invite_code;
  },
};
</script>

<style lang="scss" scoped></style>
