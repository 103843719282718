const TokenKey = 'token';
const UserInfoKey = 'userInfo';
const LoginInfoKey = 'loginInfo';
const LangKey = 'locale';

const storage = window.localStorage;

export const getStore = (key) => {
  var storeItem = storage.getItem(key);
  if (storeItem == undefined || storeItem == 'undefined') return null;

  try {
    return JSON.parse(storeItem);
  } catch (error) {
    return storeItem;
  }
};
export const setStore = (key, data) => {
  if (typeof data == 'string') {
    return storage.setItem(key, data);
  }
  return storage.setItem(key, JSON.stringify(data));
};

export function removeStore(key) {
  storage.removeItem(key);
}

const Store = {
  getToken: () => getStore(TokenKey),
  removeToken: () => removeStore(TokenKey),
  setToken: (token) => setStore(TokenKey, token),

  getUserInfo: () => getStore(UserInfoKey),
  removeUserInfo: () => removeStore(UserInfoKey),
  setUserInfo: (info) => setStore(UserInfoKey, info),

  getLoginInfo: () => getStore(LoginInfoKey),
  removeLoginInfo: () => removeStore(LoginInfoKey),
  setLoginInfo: (info) => setStore(LoginInfoKey, info),

  getLang: () => getStore(LangKey),
  setLang: (locale) => setStore(LangKey, locale),

  removePersist: () => removeStore('persist:root'),
};

export default Store;
