<template>
  <div>
    <div class="">
      <t-scroll isScrollX class="w-full">
        <t-tab
          :tabs="klinePeriods.map((item) => item.label)"
          class="!ring-0 t-sm bg-transparent px-[16px] space-x-[8px]"
          itemClass="!min-w-[50px] !h-[32px] deepPri"
          @tab-change="changeKlineTab"
          :tabIndex="periodIndex"
        />
      </t-scroll>
    </div>
    <div class="relative">
      <div class="p-[20px] flex justify-center" v-if="isLoading">
        <t-loading />
      </div>
      <v-chart
        v-else
        class="chart"
        :option="option"
        :style="{ height: `${height}px` }"
        ref="chartRef"
        autoresize
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { use } from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  ToolboxComponent,
  BrushComponent,
  VisualMapComponent,
  GridComponent,
  DataZoomComponent,
} from 'echarts/components';
import { CandlestickChart, LineChart, BarChart } from 'echarts/charts';
import VChart, { THEME_KEY } from 'vue-echarts';
import rawData from '@/assets/js/rawData';
import { toFixeds, datetimeFormats } from '@/utils/filters';
import { ApiPath } from '@/api/path';

use([
  CanvasRenderer,
  TitleComponent,
  TooltipComponent,
  LegendComponent,

  ToolboxComponent,
  BrushComponent,
  VisualMapComponent,
  GridComponent,
  DataZoomComponent,
  CandlestickChart,
  LineChart,
  BarChart,
]);

const upColor = '#00BC6E';
const downColor = '#E11E48';
const bgColor = 'transparent';
const tooltipBg = '#2B2D49ee';
const tooltipTextColor = '#C4C6DA';
const axisLabelColor = '#65718D';
const xAxisLineColor = '#484E66';
const splitLineColor = '#3E4561';

function formatData(arr) {
  // 元数据格式     [时间戳,开盘价,最高价,最低价,收盘价,时间,交易量]
  // console.log(arr);
  let categoryData = [];
  let values = [];
  let volumes = [];

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];
    categoryData.push(item[5]); //时间
    values.push([
      item[1], //开盘
      item[4], //收盘
      item[3], //最低
      item[2], //最高
      item[6], //交易量
    ]);
    volumes.push([i, item[2], item[4] > item[1] ? 1 : -1]);
  }
  const resultData = {
    categoryData: categoryData,
    values: values,
    volumes: volumes,
  };
  // console.log(resultData);
  return resultData;
}
function calculateMA(dayCount, data) {
  // console.log(data.values);
  var result = [];
  for (var i = 0; i < data.values.length; i++) {
    if (i < dayCount) {
      result.push('-');
      continue;
    }
    var sum = 0;
    for (var j = 0; j < dayCount; j++) {
      sum += data.values[i - j][1];
    }
    result.push(+(sum / dayCount).toFixed(6));
  }
  // console.log(result);
  return result;
}

export default {
  components: {
    VChart,
  },
  provide: {
    [THEME_KEY]: 'dark',
  },
  props: {
    symbol: {
      type: String,
      default: '',
    },

    height: {
      type: String,
      default: '300',
    },
  },
  data() {
    return {
      list: [],
      option: {},
      zoomStart: 95,

      klineList: [],
      curPeriod: '',
      periodList: [],
      isLoading: false,
      periodIndex: 2,

      klinePeriods: [
        { label: 'M1', value: '1m', period: '1min' },
        { label: 'M5', value: '5m', period: '5min' },
        { label: 'M15', value: '15m', period: '15min' },
        { label: 'M30', value: '30m', period: '30min' },
        { label: 'H1', value: '1h', period: '60min' },
        { label: 'D1', value: '1d', period: '1day' },
      ],
    };
  },
  watch: {
    klineList(newVal, oldVal) {
      // console.log(val);
    },
    lastKline(data) {
      // console.log(data);
      // this.updateKline(data);
    },

    symbol(newV, oldV) {
      if (newV != oldV) {
        this.init();
      }
    },
  },
  computed: {
    ...mapGetters(['lastKline']),
  },
  created() {
    this.init();
  },
  methods: {
    ...mapMutations({
      setPeriod: 'market/SET_PERIOD',
    }),
    ...mapActions({
      getHistory: 'market/getKlineHistory',
    }),
    init() {
      this.changeKlineTab(this.periodIndex);
    },
    changeKlineTab(tab) {
      console.log(tab);
      this.periodIndex = tab;
      this.curPeriod = this.klinePeriods[tab].value;
      this.getKlineList(this.curPeriod);
    },

    // 获取周期
    async getPeriods() {
      //   const { code, data } = await getKlinePeriods();
      //   if (code == 0) {
      //     this.periodList = data;
      //   }
    },
    // 获取K线历史数据
    async getKlineList(period) {
      const params = {
        symbol: this.symbol,
        time: period,
        rows: 500,
      };
      this.isLoading = true;
      try {
        const { data, code } = await this.$http.get(
          ApiPath.getKlineHistory,
          params
        );
        this.isLoading = false;
        if (code == 0) {
          this.klineList = data;
          this.zoomStart = this.klineZoomStart(data.length);
          this.setOptions(data);
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
    // 更新K线
    updateKline(data) {
      if (this.symbol == data.symbol && this.curPeriod == data.period) {
        const kItem = [
          data.id,
          data.open,
          data.high,
          data.low,
          data.close,
          data.vol,
        ];
        this.klineList = [...this.klineList, kItem];

        // console.log(this.datas.lastItem);
        // console.log(_history.lastItem);
      }
    },
    klineZoomStart(count) {
      const showCount = 105;
      if (count > showCount) {
        return showCount - Math.floor((showCount / count) * 100);
      } else {
        return 0;
      }
    },

    setOptions(list) {
      const data = formatData(list);
      // console.log(data);
      this.option = {
        animation: false,

        backgroundColor: bgColor,

        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
          },
          borderWidth: 0,
          backgroundColor: tooltipBg,
          padding: 10,
          fontSize: 12,
          textStyle: {
            color: tooltipTextColor,
          },
          position: function (pos, params, el, elRect, size) {
            const obj = {
              top: 10,
            };
            obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
            return obj;
          },
          // extraCssText: 'width: 190px',
        },
        axisPointer: {
          // link: [
          //   {
          //     xAxisIndex: 'all',
          //   },
          // ],
          label: {
            backgroundColor: axisLabelColor,
          },
        },
        // toolbox: {
        //   feature: {
        //     dataZoom: {
        //       yAxisIndex: false,
        //     },
        //     brush: {
        //       type: ['lineX', 'clear'],
        //     },
        //   },
        // },
        brush: {
          xAxisIndex: 'all',
          brushLink: 'all',
          outOfBrush: {
            colorAlpha: 0.1,
          },
        },
        visualMap: {
          show: false,
          seriesIndex: 5,
          dimension: 2,
          pieces: [
            {
              value: 1,
              color: downColor,
            },
            {
              value: -1,
              color: upColor,
            },
          ],
        },
        grid: [
          {
            top: '10%',
            left: '3%',
            right: '12%',
            bottom: '9%',
          },
          {
            left: '3%',
            right: '12%',
            bottom: '9%',
            height: '16%',
          },
        ],
        xAxis: [
          {
            type: 'category',
            data: data.categoryData,
            boundaryGap: false,
            axisLine: {
              onZero: false,
              lineStyle: {
                color: xAxisLineColor,
              },
            },
            splitLine: { show: false },
            min: 'dataMin',
            max: 'dataMax',
            axisPointer: {
              z: 50,
              snap: true,
              label: {
                backgroundColor: '#292D42',
              },
            },
            axisLabel: {
              textStyle: {
                fontSize: 10,
                color: axisLabelColor,
              },
            },
          },
          {
            type: 'category',
            gridIndex: 1,
            data: data.categoryData,
            boundaryGap: false,
            axisLine: {
              onZero: false,
              lineStyle: {
                color: xAxisLineColor,
              },
            },
            axisTick: { show: false },
            splitLine: { show: false },
            axisLabel: { show: false },
            min: 'dataMin',
            max: 'dataMax',
          },
        ],
        yAxis: [
          {
            scale: true,
            splitArea: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                color: splitLineColor,
                width: 0.5,
              },
            },
            axisPointer: {
              z: 50,
              snap: true,
              label: {
                backgroundColor: '#292D42',
              },
            },
            position: 'right',
            splitNumber: 4,
            axisLabel: {
              textStyle: {
                fontSize: 10,
              },
            },
          },
          {
            scale: true,
            gridIndex: 1,
            splitNumber: 2,
            axisLabel: { show: false },
            axisLine: { show: false },
            axisTick: { show: false },
            splitLine: { show: false },
          },
        ],
        dataZoom: [
          {
            type: 'inside',
            xAxisIndex: [0, 1],
            start: this.zoomStart,
            end: 100,
          },
        ],
        series: [
          {
            name: this.symbol,
            type: 'candlestick',
            data: data.values,
            // barWidth: 3,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: undefined,
              borderColor0: undefined,
              barMaxWidth: 20,
            },
          },
          {
            name: 'MA5',
            type: 'line',
            data: calculateMA(5, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1,
              opacity: 0.5,
            },
          },
          {
            name: 'MA10',
            type: 'line',
            data: calculateMA(10, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1,
              opacity: 0.5,
            },
          },
          {
            name: 'MA20',
            type: 'line',
            data: calculateMA(20, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1,
              opacity: 0.5,
            },
          },
          {
            name: 'MA30',
            type: 'line',
            data: calculateMA(30, data),
            smooth: true,
            showSymbol: false,
            lineStyle: {
              width: 1,
              opacity: 0.5,
            },
          },
          {
            name: 'Volume',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: data.volumes,
            itemStyle: {
              color: upColor,
              color0: downColor,
              borderColor: undefined,
              borderColor0: undefined,
              opacity: 0.5,
            },
          },
        ],
      };
    },
  },
  mounted() {},
};
</script>

<style></style>
