import { exchangeGetter } from './modules/exchange';
import { userGetter } from './modules/user';
import { walletGetter } from './modules/wallet';
import { robotsGetter } from './modules/robots';
import { appGetter } from './modules/app';
const getters = {
  ...userGetter,
  ...exchangeGetter,
  ...walletGetter,
  ...robotsGetter,
  ...appGetter,
};

export default getters;
