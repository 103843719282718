<template>
    <div class="navBar" :style="BGTransparent==true?'backgroundColor:transparent!important':''">
        <!-- 左 -->
        <div class="leftView" >
            <van-icon name="arrow-left"  @click="goBack" v-if="NavLeftArrow" color="#fff" size="20"/>
            <span  v-if="NavLeftText">{{NavLeftText}}</span>
        </div>
        <!-- 中 -->
        <div class="midView" >
            <span v-if="NavMidText">{{ NavMidText }}</span>
        </div>
        <!-- 右 -->
        <div class="rightView" >
            <slot name="rightSolt">
            </slot>
        </div>
    </div>
</template>

<script>
    export default {
        name:'Navbar',
        props:{
            NavLeftArrow:{
                default:false,
            },
            NavLeftText:{
                default:false,
            },
            NavMidText:{
                default:false,
            },
            BGTransparent:{
                default:false
            },
            // Fixed:{
            //     default:false
            // }
        },
        methods:{
            goBack(){
                this.$router.back()
            }
        }
    }   
</script>

<style lang="scss" scoped>
.navBar{
    position: fixed;
    top: 0;
    left: 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding-top: .1333rem;
    height: 1.4667rem;
    padding: 0 .2667rem;
    padding-top: 15px;
    width: 100%;
    box-sizing: border-box;
    background-color: #161616;
    z-index: 99;
    
    &>div{
        flex: 1;
        color: #fff;
        font-size: .4267rem;
        display: flex;
        align-items: center;
        line-height: 22px;
    }
    .leftView{
        span{
            margin-left: .1333rem;
            text-wrap: nowrap;
        }
    }
    .midView,.rightView{
       
        span{
            color: #fff;
            text-wrap: nowrap;
        }
    }
    .midView{
        justify-content: center;
    }
    .rightView{
        display:block;
        text-align: right;
    }
}

</style>