import { ApiPath } from '@/api/path';
import store from '..';
import http from '@/api';
import { getSymbolLogo } from '@/utils';
const getDefaultState = () => {
  return {
    coinList: [],
    coinsLoading: false,
    curCoin: {},
    socketData: {},
  };
};
const state = getDefaultState();

export const exchangeGetter = {
  coinList: (state) => state.exchange.coinList,
  curCoin: (state) => state.exchange.curCoin,
  socketData: (state) => state.exchange.socketData,
  coinsLoading: (state) => state.exchange.coinsLoading,
};

const mutations = {
  SET_COIN_LIST: (state, payload) => {
    state.coinList = payload;
  },
  SET_COINS_LOADING: (state, payload) => {
    state.coinsLoading = payload;
  },
  SET_CUR_COIN: (state, payload) => {
    state.curCoin = payload;
  },
  UPDATE_COIN: (state, payload) => {
    const _data = payload;
    state.socketData = _data;
    // 更新列表数据
    state.coinList = handleCoinList(state.coinList || [], _data);

    // 更新当前币币数据
    if (state.curCoin?.Code == _data.StockCode) {
      state.curCoin = handleCoin(state.curCoin, _data);
      // console.log(state.curCoin.Market.Price);
    }
  },
};
const actions = {
  async getCoinList({ commit }) {
    commit('SET_COINS_LOADING', true);
    try {
      const { code, data } = await http.get(ApiPath.getCoinList);
      commit('SET_COINS_LOADING', false);

      if (code == 0) {
        commit(
          'SET_COIN_LIST',
          data.map((item) => {
            item.Logo = getSymbolLogo(item.Symbol);
            item.CoinName = item.Symbol.replace('/', '_');
            return item;
          })
        );
      }
    } catch (error) {
      commit('SET_COINS_LOADING', false);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};

function handleCoinList(list, newData) {
  return list.map((item) => {
    let _item = item;
    if (item.Code == newData.StockCode) {
      return {
        ..._item,
        Market: {
          ..._item.Market,
          LastClose: newData.LastClose,
          Price: newData.Price,
          Open: newData.Open,
          Low: newData.Low,
          High: newData.High,
          DiffRate: newData.DiffRate,
          Depth: newData.Depth,
          BS: newData.BS,
        },
      };
    } else {
      return {
        ..._item,
        Market: {
          ..._item.Market,
        },
      };
    }
  });
}
function handleCoin(coin, newData) {
  return {
    ...coin,
    Market: {
      ...coin.Market,
      LastClose: newData.LastClose,
      Price: newData.Price,
      Open: newData.Open,
      Low: newData.Low,
      High: newData.High,
      DiffRate: newData.DiffRate,
      Depth: newData.Depth,
      BS: newData.BS,
    },
  };
}
