<template lang="">
  <div class="">
    <button
      class="h-full pl-[8px] flex items-center "
      @click="showSheet = true"
    >
      <span>{{ areaList[curIndex].code }}</span>
      <t-icon icon="chevron-down" class="text-gray-400 ml-[6px]" />
    </button>
    <van-popup v-model="showSheet" position="bottom" get-container="body">
      <van-picker
        show-toolbar
        :title="$t('common.selectArea')"
        :columns="areaList.map((item) => item.code)"
        :default-index="curIndex"
        @change="onChangeArea"
        @confirm="onConfirm"
    /></van-popup>
  </div>
</template>
<script>
const areaList = [
  {
    country: '台灣',
    code: '+886',
  },
  {
    country: '中國大陸',
    code: '+86',
  },
  {
    country: '美国',
    code: '+1',
  },
  {
    country: '印度',
    code: '+91',
  },
  {
    country: '巴西',
    code: '+55',
  },
  {
    country: '俄罗斯',
    code: '+7',
  },
  {
    country: '德国',
    code: '+49',
  },
  {
    country: '日本',
    code: '+81',
  },
  {
    country: '澳大利亚',
    code: '+61',
  },
  {
    country: '南非',
    code: '+27',
  },
  {
    country: '韩国',
    code: '+82',
  },
];
export default {
  data() {
    return {
      showSheet: false,
      curIndex: 0,
      areaList,
    };
  },
  created() {
    this.onConfirm(this.areaList[this.curIndex].code, this.curIndex);
  },
  methods: {
    onChangeArea(value, index) {
      //   this.curIndex = index;
    },
    onConfirm(value, index) {
      const _value = this.areaList[index].code;
      this.curIndex = index;
      this.showSheet = false;
      this.$emit('on-confirm', _value, index);
    },
  },
};
</script>
<style>
.van-picker__mask{
  display:none;
}
.van-picker__toolbar{
  background-color: #121634;
}
.van-picker-column{
  background-color:#121634;
}
</style>
