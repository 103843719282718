<template>
  <tab-page class="trendPage priPageBg">
    <page :title="$t('common.robot')" class="priPageBg" :isLoading="isLoading" :isEmpty="robotOrderList.length == 0"
      :noBack="true">
      <div class="fixed top-[56px] z-50 inset-x-0  h-[48px] flex items-center priPageBg" slot="page-top">
        <t-scroll isScrollX class="w-full">
          <t-tab :tabs="filterStatus.map((item) => item.label)"
            class="!ring-0 t-sm bg-transparent px-[16px] space-x-[8px]" itemClass="!min-w-[50px] !h-[36px] deepPri  "
            @tab-change="onTabChange" :tabIndex="statusIndex" />
        </t-scroll>
      </div>

      <div class="pt-[56px] p-base space-y-[16px] ">
        <!-- 单个机器人 -->
        <div class="card priBoxBg overflow-hidden" v-for="(item, index) in robotOrderList" :key="index">
          <div class="flex p-base items-center justify-between ">
            <div class="card bg-[#3C438A] rounded-full flex items-center p-[2px] ">
              <img src="@/assets/img/svg/V2/AiRobot.svg" class="w-[36px] h-[36px] rounded-full mr-1" alt="" />
              <span>{{ item.RobotName }}</span>
              <div class="w-[6px] h-[6px] rounded-full mx-1" :class="item.State == 1 ? 'bg-up' : 'bg-down'"></div>
            </div>
            <span class="text-[12px] px-[8px] py-[6px] ring-1 rounded-full min-w-[70px] text-center" :class="{
      'ring-orange-500 text-orange-500': item.State == 0,
      'ring-up text-up': item.State == 1,
      'ring-primary text-primary': item.State == 2,
      'ring-gray-600 text-gray-600': item.State == 3,
      'ring-gray-600 text-gray-600': item.State == 4,
      'ring-gray-300 text-gray-300': item.State == 5,
    }">
              {{ item.State | robotStatus }}
            </span>
          </div>
          <div class="relative">
            <div class="p-base space-y-[12px] t-sm">
              <div class="flex items-center">
                <div class="text-gray-300 flex-1">
                  {{ $t('robot.CapitalTrading') }}
                </div>
                <div class="">
                  <span class="text-up">{{ item.OrderPrice }}</span>
                  <span class="text-[12px] text-white ml-[4px]">{{
      globalSymbol
    }}</span>
                </div>
              </div>
              <div class="flex items-center">
                <div class="text-gray-300 flex-1">
                  {{ $t('robot.Price') }}
                </div>
                <div class="">
                  <span class="text-up">{{ item.Price }}</span>
                  <span class="text-[12px] text-white ml-[4px]">{{
      globalSymbol
    }}</span>
                </div>
              </div>

              <div class="flex items-center">
                <div class="text-gray-300 flex-1">
                  {{ $t('robot.ValidPeriod') }}
                </div>
                <div class="">
                  <span class="text-up">{{ item.Days }}</span>
                  <span class="text-[12px] text-white ml-[4px]">{{
      $t('robot.day')
    }}</span>
                </div>
              </div>
              <div class="flex items-center">
                <div class="text-gray-300 flex-1">
                  {{ $t('robot.RunTime') }}
                </div>
                <div class="" v-if="!item.ActiveTime || !item.TimeDifference">
                  --
                </div>
                <div class="" v-else>
                  <span class="text-up">{{
      item.State != 1
        ? formatRunTime(item.TimeLength)[0]
        : item.TimeDifference[0]
    }}</span>
                  <span>{{ $t('common.days') }}</span>
                  <span class="text-up">{{
      item.State != 1
        ? formatRunTime(item.TimeLength)[1]
        : item.TimeDifference[1]
    }}</span>
                  <span>{{ $t('common.hours') }}</span>
                  <span class="text-up">{{
      item.State != 1
        ? formatRunTime(item.TimeLength)[2]
        : item.TimeDifference[2]
    }}</span>
                  <span>{{ $t('common.minutes') }}</span>
                  <!-- <span class="text-up">{{
                  item.State != 1
                    ? formatRunTime(item.TimeLength)[3]
                    : item.TimeDifference[3]
                }}</span>
                <span>{{ $t('common.seconds') }}</span> -->
                </div>
              </div>
              <div class="space-y-[12px]" v-if="item.expanded">
                <div class="flex items-center">
                  <div class="text-gray-300 flex-1">
                    {{ $t('robot.StartUpFunds') }}
                  </div>
                  <div class="">
                    <span class="text-up">{{ item.OrderPrice }}</span>
                    <span class="text-[12px] text-white ml-[4px]">{{
      $t('robot.USDT')
    }}</span>
                  </div>
                </div>

                <div class="flex items-center">
                  <div class="text-gray-300 flex-1">
                    {{ $t('robot.DateAcquired') }}
                  </div>
                  <span class="">{{ item.CreateTime }}</span>
                </div>

                <div class="flex items-center">
                  <div class="text-gray-300 flex-1">
                    {{ $t('robot.ActivationTime') }}
                  </div>
                  <span class="">{{ item.ActiveTime || '--' }}</span>
                </div>
                <div class="flex items-center">
                  <div class="text-gray-300 flex-1">
                    {{ $t('robot.FinalActivationTime') }}
                  </div>
                  <span class="">{{ item.LastActiveTime || '--' }}</span>
                </div>

                <div class="flex items-center">
                  <div class="text-gray-300 flex-1">
                    {{ $t('robot.UniqueCode') }}
                  </div>
                  <span class="text-up">{{ item.SNCode }}</span>
                </div>
                <div class="flex items-center">
                  <div class="text-gray-300 flex-1">
                    {{ $t('common.robots.buyType') }}
                  </div>
                  <div class="">
                    <span class="text-up">{{
      $t('common.robots.Contractpurchase')
    }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center my-[12px] h-[1px] bg-[#3C438A] mx-base relative"
              :class="item.State >= 3 ? 'mb-[20px]' : ''">
              <button
                class="rounded-[4px] bg-[#3C438A] px-[12px] py-[1px] absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                @click="toggleExpand(index)">
                <t-icon icon="chevron-down" class="text-[14px] transform transition-all duration-300"
                  :class="item.expanded ? ' rotate-180' : 'rotate-0'" />
              </button>
            </div>
          </div>
          <!-- 机器人操作 -->
          <div class="px-base py-[12px] flex items-center justify-end space-x-[16px] t-sm" v-if="item.State < 3">
            <!-- 转让 -->
            <button class="btn min-w-[90px] h-[36px] bg-down" @click="showSellRobotSheet(item)" v-if="item.State != 1">
              <span>{{ $t('common.transfer') }} </span>
            </button>


            <!-- 激活 -->
            <button class="btn min-w-[90px] h-[36px] bg-primary" @click="onAcitvated(item)" v-if="item.State == 0">
              {{ $t('common.robots.activate') }}
            </button>
            <!-- 启动和暂停 -->
            <button class="btn min-w-[90px] h-[36px] bg-up" :class="{
      'bg-orange-500': item.State == 1,
      'bg-up': item.State == 2,
    }" @click="toggleRobot(item)" v-if="item.State != 0">
              <span>{{
      item.State == 1 ? $t('robot.Pause') : $t('common.begin')
    }}</span>
            </button>

          </div>
        </div>
      </div>
      <!-- 弹出层 -->
      <van-popup v-model="show" position="bottom">
        <div class="priPageBg">
          <div class="flex items-center p-base">
            <img src="@/assets/img/svg/V2/AiRobot.svg" class="w-[40px] mr-[12px]" alt="" />
            <div class="flex-1 font-bold">
              {{ curRobot.RobotName }}
            </div>
            <van-icon name="cross" size="22" @click="show = false" />
          </div>

          <div class="p-base t-sm space-y-[12px]">
            <div class="flex items-center">
              <div class="text-gray-400">{{ $t('robot.UniqueCode1') }}</div>
              <div class="flex-1 text-right">{{ curRobot.SNCode }}</div>
            </div>
            <div class="flex items-center">
              <div class="text-gray-400">{{ $t('robot.OfficialPrice') }}</div>
              <div class="flex-1 text-right">{{ curRobot.Price }}</div>
            </div>
            <div class="flex items-center">
              <div class="text-gray-400">{{ $t('robot.SalesValue') }}</div>
              <div class="flex-1 text-right">{{ curRobot.Price }}</div>
            </div>
          </div>
          <div class="p-base">
            <div class="">
              <t-input type="text" :placeholder="$t('robot.p1')" v-model="giveUserName" />
            </div>
            <p class="t-sm text-gray-400 mt-base">{{ $t('robot.p2') }}</p>
            <button class="btn flex mt-3 w-full" @click="giveRobot">
              {{ $t('robot.Confirm') }}
            </button>
          </div>
        </div>
      </van-popup>
    </page>
  </tab-page>
</template>

<script>
import TabPage from '@/components/TabPage.vue';
import { ApiPath } from '@/api/path';
import Navbar from '@/components/Navbar/Navbar.vue';
import { mapGetters } from 'vuex';
import { Toast } from 'vant';
import { calculateTimeDifference, formatSecondTime } from '@/utils/filters';

let _this;
export default {
  components: {
    Navbar, TabPage
  },
  data() {
    return {
      showPopover: false,
      filterState: '',
      show: false,
      sellCount: 20,
      robotOrderList: [],
      status: null,
      curRobot: {},
      giveUserName: '',
      curFilterObj: {},
      setTime: calculateTimeDifference,
      formatSecondTime,
      listTimer: null,
      statusIndex: 0,
      isLoading: false,
      expandIndex: null,
    };
  },
  filters: {
    robotStatus(val) {
      switch (val) {
        case 1:
          return _this.$t('common.robots.activated');
        case 2:
          return _this.$t('common.robots.PauseInProgress');
        case 3:
          return _this.$t('common.robots.Expired');
        case 4:
          return _this.$t('common.robots.slod');
        case 5:
          return _this.$t('冻结中');
        default:
          return _this.$t('robot.NoActivation');
      }
    },
  },

  computed: {
    ...mapGetters(['globalSymbol']),
    filterStatus() {
      return [
        { label: _this.$t('robot.Total'), state: null },
        { label: _this.$t('robot.NoActivation'), state: 0 },
        { label: _this.$t('robot.Activation'), state: 1 },
        { label: _this.$t('robot.PauseInProgress'), state: 2 },
        { label: _this.$t('robot.Expired'), state: 3 },
        { label: _this.$t('common.selled'), state: 4 },
        { label: _this.$t('冻结中'), state: 5 },
      ];
    },
  },
  methods: {
    toggleExpand(index) {
      if (this.expandIndex == index) {
        this.robotOrderList[index].expanded =
          !this.robotOrderList[index].expanded;
      } else {
        if (this.expandIndex != null) {
          this.robotOrderList[this.expandIndex].expanded = false;
        }
        this.robotOrderList[index].expanded = true;
        this.expandIndex = index;
      }
    },
    onTabChange(tab) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.statusIndex = tab;
      this.getRobotOrderList(tab);
    },
    async getRobotOrderList(index) {
      const params = {
        pageIndex: 1,
        pageSize: 50,
        status: this.filterStatus[index].state, // 0：待激活，1：进行中，2：暂停中，3：已过期，4：已售
      };
      clearInterval(this.listTimer);
      this.isLoading = true;
      try {
        const { data, code } = await this.$http.get(
          ApiPath.getRobotOrderList,
          params
        );
        this.isLoading = false;
        if (code == 0) {
          this.robotOrderList = data;
          this.updateDataTimeDifference(data);
        }
      } catch (error) {
        this.isLoading = false;
      }
    },

    // 激活
    async onAcitvated(robot) {
      const params = {
        Id: robot.Id,
      };
      const { data, code, msg } = await this.$http.post(
        ApiPath.activeRobot,
        params
      );
      if (code == 0) {
        Toast.success(this.$t('robot.Activation'));
        this.onTabChange(this.statusIndex);
      } else {
        Toast.fail(msg);
      }
    },
    // 显示转让面板
    showSellRobotSheet(robot) {
      this.show = true;
      this.curRobot = robot;
    },

    // 更新列表时间
    updateDataTimeDifference(array) {
      clearInterval(this.listTimer);
      this.listTimer = setInterval(() => {
        this.robotOrderList = array.map((obj) => {
          const activeTime = new Date(obj.ActiveTime);
          const timeDifference = Math.abs(new Date() - activeTime) / 1000;
          if (obj.ActiveTime == null || isNaN(timeDifference)) {
            obj.TimeDifference = [0, 0, 0, 0];
            return obj;
          }

          const days = Math.floor(timeDifference / (24 * 3600)); // 计算天数
          const hours = Math.floor((timeDifference % (24 * 3600)) / 3600);
          const minutes = Math.floor((timeDifference % 3600) / 60);
          const seconds = Math.floor(timeDifference % 60);

          obj.TimeDifference = [days, hours, minutes, seconds];
          return obj;
        });
      }, 1000); // 每秒钟更新一次
    },

    // 格式化运行时间
    formatRunTime(secondTime) {
      return [
        Math.floor(secondTime / (24 * 3600)),
        Math.floor((secondTime % (24 * 3600)) / 3600),
        Math.floor((secondTime % 3600) / 60),
        Math.floor(secondTime % 60),
      ];
    },

    // 启动和暂停
    async toggleRobot(robot) {
      const params = {
        Id: robot.Id,
      };
      if (robot.State == 1) {
        const { data, code } = await this.$http.post(ApiPath.stopRobot, params);
        if (code == 0) {
          Toast.success('已暫停');
          // clearInterval(this.listTimer);
          this.onTabChange(this.statusIndex);
        }
      }
      if (robot.State == 2) {
        this.onAcitvated(robot);
      }
    },

    // 赠送转让
    async giveRobot() {
      const params = {
        Id: this.curRobot.Id,
        UserName: this.giveUserName,
      };
      const { data, code, msg } = await this.$http.post(
        ApiPath.giveRobot,
        params
      );
      if (code == 0) {
        Toast.success('已贈送');
        this.show = false;
      } else {
        Toast.fail(msg);
      }
    },
  },
  created() {
    _this = this;
    let _statusIndex = this.statusIndex;
    const routerType = this.$route.query.robotsType;
    if (routerType == 1) {
      _statusIndex = 2;
    }
    this.onTabChange(_statusIndex);
  },
};
</script>

<style lang="scss" scoped>
.trendPage {
  // background: url(../../../assets/img/T2/bg.png) top no-repeat;
  background-size: contain;
  min-height: 80vh;
  font-size: 0.37333rem;
  // background-color: #161616;
  padding-bottom: 100px;

  &>div {
    margin: 0 auto;
  }

  .pageTitle {
    padding-top: 0.1867rem;
    line-height: 55px;
    text-align: center;
    font-size: 16px;
  }

  .pointView {
    margin-top: 20px;
    padding: 15px;
    margin: 12px 15px 0;
    border-radius: 0.26667rem;
    background-color: #1f1f1f;
    box-sizing: border-box;
    margin-bottom: 20px;

    .swiperHeader {
      padding: 5px 0;
      display: flex;
      margin-bottom: 18px;

      .left {
        width: 270px;
      }

      .right {
        margin-left: 10px;
        width: 0.96rem;
        height: 0.9067rem;
        line-height: 0.9067rem;
        text-align: center;
        font-size: 30px;
        border: 0.02667rem solid #ffe87e;
        color: #ffe87e;
        box-sizing: border-box;
      }
    }

    .coinItem {
      display: flex;
      height: 1.68rem;
      box-sizing: border-box;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      border-bottom: 0.02667rem solid #313132;

      // margin-bottom: 20px;
      .left {
        display: flex;
        align-items: center;

        span {
          margin-left: 10px;
        }

        img {
          width: 0.8533rem;
          height: 0.8533rem;
        }

        span {
          font-size: 0.3733rem;
        }
      }

      .right {
        color: #05ec15 !important;
      }
    }
  }

  .friendItem {
    height: 0.96rem;
    background-color: #f1ce78;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.16rem;
    margin-right: 0.2667rem;

    img {
      width: 2.3467rem;
      height: 0.7467rem;
    }
  }

  .profitItem {
    margin: 0 16px;
    height: 2.9067rem;
    background-color: #1f1f1f;
    border-radius: 0.26667rem;
    margin-bottom: 15px;
    padding: 15px;
    box-sizing: border-box;

    &>div {
      display: flex;
    }

    .row1 {
      img {
        width: 32px;
        height: 32px;
      }

      span {
        font-weight: 900;
      }

      p {
        margin-top: 4px;
        color: #fff;
        opacity: 0.5;
      }

      .right {
        margin-left: 10px;
      }
    }

    .row2 {
      margin: 18px 0 0 8px;
      line-height: 14px;

      .left {
        transform: rotate(180deg);
      }

      p {
        margin-left: 15px;
        font-size: 0.3733rem;
        color: #757a7b !important;

        span {
          color: #05ec15;
          margin-left: 20px;
        }
      }
    }
  }

  .exchangeView {
    padding: 15px;
    width: 9rem;
    height: 10.1333rem;
    box-sizing: border-box;
    background-color: #161616;
    border-radius: 10px;
    position: relative;

    .left {
      position: absolute;
      right: 0.5333rem;
    }

    .quot-title {
      padding: 10px 0;
      font-size: 0.48rem;
      font-weight: 700;
      text-align: center;
    }

    .exchangeBox {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .exchangeItem {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 3.832rem;
        height: 1.3067rem;
        background-color: #f8fcd3;
        border-radius: 0.26667rem;
        margin-bottom: 0.26667rem;

        img {
          margin-right: 10px;
          width: 92px;
          height: 29px;
        }
      }
    }
  }

  .van-popup {
    background-color: transparent;
  }
}
</style>
