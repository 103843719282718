import { ApiPath } from '@/api/path';
import store from '..';
import http from '@/api';
import axios from 'axios';
import { appConfig } from '@/assets/js/config';
import { langList } from '@/i18n';
const getDefaultState = () => {
  return {
    ossData: {},
    curLang: langList[0],
    showKefu: false,
    showNotice: false,
    checkAddress: [],
  };
};
const state = getDefaultState();

export const appGetter = {
  ossData: (state) => state.app.ossData,
  curLang: (state) => state.app.curLang,
  showKefu: (state) => state.app.showKefu,
  showNotice: (state) => state.app.showNotice,
};

const mutations = {
  SET_OSSDATA: (state, payload) => {
    state.ossData = payload;
  },

  SET_LOCALE: (state, payload) => {
    state.curLang = payload;
  },
  SHOW_KEFU: (state, payload) => {
    state.showKefu = payload;
  },
  SHOW_NOTICE: (state, payload) => {
    state.showNotice = payload;
  },
};
const actions = {
  async getOssData({ commit }) {
    try {
      const { data } = await axios.get(
        appConfig.OSS_URL + '/info.json' + `?${Date.now()}`
      );
      commit('SET_OSSDATA', data);
      document.title = data.baseInfo.appname;
    } catch (error) {}
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
