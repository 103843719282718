var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"t-xs relative overflow-hidden"},[_c('div',{staticClass:"transition-all duration-200 absolute right-0 inset-y-0 z-0",class:[
        _vm.isBuy ? 'bg-up/10' : 'bg-down/10',
        _vm.isReverse ? 'left-0' : 'right-0',
      ],style:({
        width: `${(item.amount / (_vm.isBuy ? _vm.data.BV1 : _vm.data.SV1)) * 100}%`,
      })}),_c('div',{staticClass:"flex items-center relative z-10 px-base h-[32px]",class:_vm.isReverse ? 'flex-row-reverse' : ''},[_c('div',{staticClass:"flex-1",class:[
          _vm.isBuy ? 'text-up' : 'text-down',
          _vm.isReverse ? 'text-right' : 'text-left',
        ]},[_vm._v(" "+_vm._s(item.price)+" ")]),_c('div',{staticClass:"flex-1",class:_vm.isReverse ? 'text-left' : 'text-right'},[_vm._v(" "+_vm._s(item.amount)+" ")])])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }