<template>
  <div
    class="loginPage min-h-screen bg-no-repeat bg-fixed  bg-top priPageBg pt-[120px]	"
    :style="{
      backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
    }"
  >
    <language class="fixed right-[16px] top-[16px]" />
    <div class="flex justify-center mb-6">
      <img src="../../assets/img/logo-text.png" class="h-[48px]" alt="" />
    </div>
    <div class="max-w-[320px] mx-auto mt-[20px] ">
      <t-tab
        :tabs="[$t('common.emailLogin'), $t('common.phoneLogin')]"
        @tab-change="tabChange"
        :tab-index="curTabIndex"
        class="mb-[24px]  "
      />

      <t-input
        glass
        :placeholder="isEmail ? $t('input.email') : $t('input.phone')"
        v-model="formData.UserName"
      >
        <div class="" slot="before" v-if="!isEmail">
          <select-area @on-confirm="(val) => (this.areaCode = val)" />
        </div>
      </t-input>
      <t-input
        glass
        :placeholder="$t('input.pwd')"
        v-model="formData.Password"
        class="mt-[24px]"
        type="password"
      ></t-input>
      <div class="mt-[24px]">
        <button
          @click="$router.push('/find-pwd')"
          class="text-[14px] text-primary"
        >
          {{ $t('common.forgetNumber') }}
        </button>
      </div>
      <div class="mt-2">
        <t-button @click="login" :loading="isLoading" class="w-full">{{
          $t('common.login2')
        }}</t-button>
      </div>
      <p class="text-center text-[14px] mt-2">
        {{ $t('common.noCount')
        }}<span @click="$router.push('/regist')" class="text-primary">{{
          $t('common.signUp')
        }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { ApiPath } from '@/api/path';
import ChooseLang from '@/components/ChooseLang/ChooseLang.vue';
import FormInput from '@/components/FormInput/FormInput.vue';
import loginType from '@/components/LoginType/LoginType.vue';

import Store from '@/utils/storage';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import Language from '@/components/Language.vue';
import { Toast } from 'vant';
import SelectArea from '@/components/SelectArea.vue';

let _this;

export default {
  components: {
    ChooseLang,
    FormInput,
    loginType,
    Language,
    SelectArea,
  },
  data() {
    return {
      isLoading: false,
      curTabIndex: 0,
      areaCode: '',
      formData: {
        UserName: '',
        Password: '',
      },
    };
  },
  computed: {
    ...mapGetters(['ossData']),
    isEmail: () => _this.curTabIndex == 0,
  },
  methods: {
    ...mapMutations({
      setLoginState: 'user/SET_LOGIN',
    }),
    ...mapActions({
      getOss: 'app/getOssData',
    }),
    tabChange(tab) {
      this.formData.UserName = '';
      this.formData.Password = '';
      // console.log(tab);
      this.curTabIndex = tab;
    },
    async login() {
      if (!this.formData.UserName) {
        Toast.fail(
          this.isEmail ? this.$t('input.email') : this.$t('input.phone')
        );
        return;
      }
      if (!this.formData.Password) {
        Toast.fail(this.$t('input.pwd'));
        return;
      }

      const _params = {
        ...this.formData,
        UserName: this.isEmail
          ? this.formData.UserName
          : this.areaCode + this.formData.UserName,
      };

      this.isLoading = true;
      try {
        const { data, code, msg } = await this.$http.post(
          ApiPath.login,
          _params
        );
        this.isLoading = false;
        if (code == 0) {
          Store.setToken(data);
          this.setLoginState(true);
          this.$router.push('/');
        } else {
          Toast.fail(msg);
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  created() {
    _this = this;
    this.getOss();
  },
};
</script>

<style lang="scss"></style>

<style></style>
