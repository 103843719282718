<template>
  <page
    :title="$t('Deposit.Deposit')"
    class="bg-no-repeat bg-fixed  bg-top priPageBg"
    :style="{
      backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
    }"
  >
    <!-- <button
      slot="nav-action"
      @click="$router.push('/recharge')"
      class="text-[14px] px-[12px]"
    >
      {{ $t('Deposit.RechargeInstructions') }}
    </button> -->
    <div class="p-base space-y-[12px] ">
      <div class="">
        <div class="card p-[20px] priBoxBg">
          <div class="text-[12px] mb-[12px] opacity-70">
            {{ $t('Deposit.WalletBalance') }}
          </div>
          <div class="text-[28px] font-bold ">
            {{ userInfo.Balance }}
            <span class="font-light text-[12px]">{{ globalSymbol }}</span>
          </div>
        </div>
      </div>

      <div class="">
        <div class="t-sm text-gray-400 mb-[12px] ">
          {{ $t('common.selectCoin') }}
        </div>
        <t-selector
          :columns="symbolList.map((item) => item.Symbol)"
          :label="$t('common.selectCoin')"
          @on-confirm="changeSymbol"
          box-class=""
        />
      </div>
      <div class="" v-if="curCoin.AddressList && curCoin.AddressList.length">
        <div class="card p-base priBoxBg">
          <div class="flex justify-center mb-2">
            <t-tab
              :tabs="curCoin.AddressList.map((item) => item.Channel)"
              class="t-sm bg-[#121634]"
              @tab-change="onLinksChange"
            />
          </div>
          <div class="" v-if="curAddress">
            <div class="flex justify-center">
              <vue-qr :text="curAddress" :size="150" class="rounded"></vue-qr>
            </div>
            <div
              class="t-sm text-center mb-[12px] mt-[16px] text-gray-400 font-bold"
            >
              {{ $t('common.depositAddress') }}
            </div>
            <div
              class="mt-[8px] mb-[12px] w-full break-words text-center"
              :class="
                addressIsHide
                  ? 'text-red-500 t-sm'
                  : 'text-white font-medium t-base'
              "
            >
              {{ curAddress }}
            </div>
            <div class="flex justify-center">
              <button
                class="btn bg-primary t-xs mt-1 px-2 h-[32px]"
                v-clipboard:copy="curAddress"
                v-clipboard:success="onCopy"
              >
                {{ $t('share.copy') }}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="">
        <div class="t-sm text-gray-400 mb-[12px]">
          {{ $t('Deposit.Amounts') }}
        </div>
        <t-input
          v-model="amount"
          class="w-full h-[56px] t-lg"
          :placeholder="$t('common.inputAmount')"
        >
          <div class="t-sm text-gray-400 mr-[6px]" slot="after">
            {{ globalSymbol }}
          </div>
        </t-input>
        <div class="mt-base grid grid-cols-3 gap-[12px] ">
          <button
            class="btn w-full"
            v-for="(item, index) in amountList"
            :key="index"
            @click="
              amount = item;
              amountIndex = index;
            "
            :class="
              amountIndex == index ? 'bg-primary' : 'bg-[#383d6e] text-gray-200'
            "
          >
            {{ item }}
          </button>
        </div>
      </div>
      <div class="">
        <div class="t-sm text-gray-400 mb-[12px]">
          {{ $t('common.uploadImg') }}
        </div>
        <div class="card px-base py-[32px] flex justify-center relative priBoxBg">
          <div class="flex justify-center" v-if="curImg">
            <div class="relative">
              <img :src="curImg" alt="" class="max-w-full" />
              <button
                class="w-[24px] h-[24px] bg-red-500 rounded-full flex items-center justify-center absolute -right-[12px] -top-[12px]"
                @click="curImg = ''"
              >
                <t-icon icon="x" class="text-[20px]" />
              </button>
            </div>
          </div>

          <van-uploader :after-read="afterRead" class="" v-else>
            <t-icon icon="camera" class="text-[32px] text-white" />
          </van-uploader>
        </div>
      </div>
      <div class="pt-[12px]">
        <t-button class="w-full" @click="submitRecharge">{{
          $t('common.Confirmrecharge')
        }}</t-button>
      </div>
    </div>
  </page>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import { Toast } from 'vant';
import { mapActions, mapGetters } from 'vuex';
import { ApiPath } from '@/api/path';
import VueQr from 'vue-qr';
import { appConfig } from '@/assets/js/config';
import axios from 'axios';
export default {
  components: {
    Navbar,
    VueQr,
  },
  data() {
    return {
      amount: '',
      addressList: [],
      symbolList: [],
      curImg: '',
      amount: '',
      curAddress: '',
      curCoin: {},
      curLinkIndex: 0,
      curAddrObj: {},
      addressIsHide: false,
      amountList: [100, 500, 1000, 5000, 10000, 50000],
      amountIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['userInfo', 'globalSymbol', 'checkAddress']),
  },

  methods: {
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      getOssAddress: 'app/getCheckAddressJson',
    }),
    async afterRead(file) {
      // console.log(file);
      const params = {
        base64Str: file.content,
      };
      const { data, code, msg } = await this.$http.post(
        ApiPath.uploadBase64File,
        params
      );
      if (code == 0) {
        this.curImg = data;
      } else {
        Toast.fail(msg);
      }
    },

    changeSymbol(index) {
      this.curCoin = this.symbolList[index];
      this.onLinksChange(0);
    },
    onLinksChange(index) {
      this.curLinkIndex = index;
      this.curAddrObj = this.curCoin.AddressList[index];

      this.checkOssAddress(this.curAddrObj.Address);
      // this.curAddress = this.curAddrObj.Address;
    },
    async checkOssAddress(apiAddress) {
      const checkOssUrl = appConfig.CHECK_ADDRESS_OSS + `?${Date.now()}`;
      const res = await axios.get(checkOssUrl);
      const { despoit } = res.data;
      // console.log(despoit);
      if (despoit && despoit.length > 0) {
        if (despoit.includes(apiAddress)) {
          this.curAddress = apiAddress;
          this.addressIsHide = false;
        } else {
          this.curAddress = this.$t('common.invalidAddress');
          this.addressIsHide = true;
        }
      } else {
        this.curAddress = this.$t('common.invalidAddress');
        this.addressIsHide = true;
      }
    },
    onCopy() {
      Toast.success(this.$t('common.copied'));
    },
    async getDepositAddressList() {
      const { code, data } = await this.$http.get(
        ApiPath.getRechargeAddressList
      );

      if (code == 0) {
        this.symbolList = data.filter((item) => item.AddressList.length > 0);
        console.log(this.symbolList);
      }
    },
    async submitRecharge() {
      const params = {
        Amount: this.amount,
        Symbol: this.curCoin.Symbol,
        ImageUrl: this.curImg,
        LinkName: this.curAddrObj.Channel,
        Address: this.curAddress,
      };
      if (!this.curCoin.Symbol) {
        Toast.fail(this.$t('common.selectCoin'));
        return;
      }
      if (!this.amount) {
        Toast.fail(this.$t('common.inputAmount'));
        return;
      }
      if (!this.curImg) {
        Toast.fail(this.$t('common.uploadImg'));
        return;
      }
      const { code, msg, data } = await this.$http.post(
        ApiPath.saveRecharge,
        params
      );
      if (code == 0) {
        Toast.success(this.$t('common.submitAndWait'));
        this.amount = '';
        this.curImg = '';
      } else {
        Toast.fail(msg);
      }
    },
  },
  created() {
    this.getUserInfo();
    this.getDepositAddressList();
  },
};
</script>

<style lang="scss" scoped></style>
