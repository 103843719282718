<template lang="">
  <div
    class="text-[14px] font-medium flex items-center"
    :class="rate > 0 ? ' textPriUp' : 'textPriDown'"
  >
  <span>{{ Math.abs(rate) | toFixedStr }}% </span>
  <img src="../../assets/img/up.png" class="ml-[4px] mt-[4px]" v-if="rate > 0" >
  <img src="../../assets/img/down.png" class="ml-[4px] mt-[4px]" v-else>
    <!-- <t-icon
      :icon="rate > 0 ? 'arrow-up-from-dot' : 'arrow-down-to-dot'"
      class="text-[14px] mr-[2px]"
    /> -->
  </div>
</template>
<script>
export default {
  props: {
    rate: {
      type: Number,
      default: 0,
    },
  },
};
</script>
<style lang=""></style>
