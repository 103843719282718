<template>
  <div class="helpPage bg-no-repeat bg-fixed  bg-top bg-fixed priPageBg" :style="{
    backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
  }">
    <Navbar
      NavLeftArrow="true"
      :NavLeftText="$t('help.HelpCenter')"
      :BGTransparent="true"
    ></Navbar>
    <div class="bg"></div>
    <div class="card mx-base -mt-[48px] p-base priBoxBg ">
      <div class="text-[20px] font-bold mb-base">{{ $t('help.commonProblem') }}</div>
      <div class="space-y-[16px]">
        <div
          class=""
          @click="openQA(index)"
          v-for="(item, index) in helpList"
          :key="index"
        >
          <div class="flex items-center gap-[8px] py-[8px]">
            <span
              class="py-[6px] px-[10px] rounded-[8px] text-[14px] font-bold bg-primary"
              >Q</span
            >
            <div class="flex-1 text-[18px] font-medium">
              {{ item.question }}
            </div>
            <div
              class="transform trans"
              :class="curActiveIndex == index ? ' -rotate-180' : 'rotate-0'"
            >
              <t-icon icon="chevron-down" class="text-gray-500" />
            </div>
          </div>
          <div
            class="overflow-hidden transition-all duration-500"
            :class="curActiveIndex == index ? 'h-auto' : 'h-0'"
          >
            <ul
              class="py-[8px] text-gray-300 text-[14px] leading-[24px]"
              v-for="(itm, idx) in item.answers"
              :key="idx"
            >
              <li>
                <span class="text-primary">{{ idx + 1 }}.</span>
                {{ itm }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
export default {
  components: {
    Navbar,
  },
  data() {
    console.log(this);
    return {
      curActiveIndex: 0,
      helpList: [
        {
          question:  this.$t('help.q1'),
          answers: [
            this.$t('help.p1'),
            this.$t('help.p2'),
            this.$t('help.p3'),
            this.$t('help.p4'),
            this.$t('help.p5'),
            this.$t('help.p6'),
            this.$t('help.p7'),
          ],
        },
        {
          question: this.$t('help.q2'),
          answers: [
            this.$t('help.p8'),
            this.$t('help.p9'),
            this.$t('help.p10'),
            this.$t('help.p11'),
            this.$t('help.p12'),
            // this.$t('help.p13')
          ],
        },
      ],
    };
  },
  methods: {
    openQA(index) {
      if (index == this.curActiveIndex) {
        this.curActiveIndex = null;
      } else {
        this.curActiveIndex = index;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.helpPage {
  position: relative;
  width: 100%;
  padding-bottom: 50px;
  min-height: 100%;

  .bg {
    // background: url('../../assets/img/Homeindex/helpPage/bg.png') top no-repeat;
    background-size: cover;
    width: 100%;
    height: 150px;
    z-index: 1;
  }
}
</style>
