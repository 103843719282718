<template>
  <tab-page class="homeIndexPage min-h-screen">
    <!-- 头Logo -->
    <div class="p-base flex items-center">
      <div class="logo flex-1">
        <img src="../../assets/img/logo-text.png" class="h-[36px]" alt="" />
        <!-- <img src="../../assets/img/Homeindex/logo.png" alt="" /> -->
        <!-- <div class="font-orbitron font-bold text-[26px]">
          {{ ossData?.baseInfo?.appname }}
        </div> -->
      </div>
      <language class="mr-[16px]" />
      <van-badge :content="unreadCount">
        <div class="" @click="$router.push('/message-list')">
          <!-- <t-icon icon="bell" class="text-[24px]" /> -->
          <img src="../../assets/img/Homeindex/notice.png" class="w-[20px] h-[25px]">
        </div>
      </van-badge>
    </div>

    <!-- 今日收益 -->

    <div class="">
      <div
        class="card p-base mx-base h-[140px]  bg-homeBanner bg-cover bg-center flex flex-col items-center justify-center"
        v-if="isLogined">
        <div class="text-[14px] opacity-50">{{ $t('home.profit') }}</div>
        <div class="mt-[10px] flex items-center w-full">
          <div class="text-[30px] text-center w-full break-all">
            {{ userInfo.DailyProfit || '0.00' }} USDT
          </div>
        </div>
      </div>

      <div class="card p-2 mx-base flex items-center priBoxBg" @click="$router.push('/login')" v-else>
        <div class="flex-1">
          <div class="text-[24px] font-bold">
            {{ $t('common.Clicktologin') }}
          </div>
          <div class="text-[12px] text-orange-400 mt-[4px]">
            {{ $t('common.loginTips') }}
          </div>
        </div>
        <t-icon icon="chevron-right" />
      </div>
    </div>
    <!-- 导航 -->
    <div class="mt-[16px]">
      <div class="grid grid-cols-3">
        <div @click="onNavTap(item)"
          class="flex flex-col items-center px-[10px] text-white active:bg-primary/10 py-[8px] rounded text-center"
          v-for="(item, index) in navList" :key="index">
          <div class="w-[60px] h-[60px] rounded-[20px]   deepPri  flex items-center justify-center">
            <img :src="item.icon">
            <!-- <t-icon :icon="item.icon" class="text-[24px]" /> -->
          </div>
          <div class="text-[12px] mt-[8px] line-clamp-2 w-full">
            {{ item.label }}
          </div>
        </div>
      </div>
    </div>

    <t-loading v-if="coinsLoading" />
    <div class="py-base text-[14px]" v-else>
      <!-- 轮播货币 -->
      <van-swipe :loop="true" :width="220" :show-indicators="false" :onchange="swiperChange">
        <van-swipe-item v-for="(item, index) in coinList" :key="index">
          <div class="pl-[16px] box-border ">
            <div class="card p-base priBoxBg" @click="$router.push(`/coins/${item.CoinName}`)">
              <div class="flex items-center mb-[12px]">
                <t-img :src="item.Logo" class="w-[20px] h-[20px] rounded-full" />
                <div class="flex-1 mx-[4px] text-[14px]">{{ item.Symbol }}</div>
                <chg-rate :rate="item.Market.DiffRate" class="text-[12px] font-normal" />
              </div>
              <div
                :class="['text-[20px]', 'font-bold', 'mt-[5px]', item.Market.DiffRate > 0 ? 'textPriUp' : 'textPriDown']">
                {{ item.Market.Price }}
              </div>
              <p class="text-[12px] mt-[6px]  note font-thin">
                VOL:{{ item.Market.TotalVol | toFixedStr(4) }}
              </p>
            </div>
          </div>
        </van-swipe-item>
      </van-swipe>
    </div>
    <!-- 活动 -->
    <!-- <div class="actionBanner" @click="$router.push('/christmas')"></div> -->
    <!-- 我的机器人 -->
    <div class="px-base pb-[50px]">
      <robot-list />
    </div>
  </tab-page>
</template>

<script>
import MyRobot from '@/components/MyRobot/MyRobot.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { ApiPath } from '@/api/path';
import RobotList from '../components/RobotList.vue';
import Store from '@/utils/storage';
import ChgRate from '@/components/ui/ChgRate.vue';
import { toFixedStr } from '@/utils/filters';
import TabPage from '@/components/TabPage.vue';
import Language from '@/components/Language.vue';
import { Toast } from 'vant';

export default {
  data() {
    return {
      activeIndex: 0,
      show: false,
      showkefu: false,
      swiperList: [],
      unreadCount: 0,
      partners: [
        require('../../assets/img/Homeindex/f1.png'),
        require('../../assets/img/Homeindex/f2.png'),
        require('../../assets/img/Homeindex/f3.png'),
        require('../../assets/img/Homeindex/f4.png'),
        require('../../assets/img/Homeindex/f5.png'),
        require('../../assets/img/Homeindex/f6.png'),
      ],
    };
  },
  components: {
    MyRobot,
    RobotList,
    ChgRate,
    TabPage,
    Language,
  },
  computed: {
    ...mapGetters([
      'userInfo',
      'coinList',
      'coinsLoading',
      'isLogined',
      'ossData',
    ]),

    navList() {
      return [
        // { icon: require('../../assets/img/svg/V2/robot.svg'), label: this.$t('common.robot'), link: '/robot' },
        // { icon: require('../../assets/img/svg/V2/home/property.svg'), label: this.$t('common.property'), link: '/my' },
        {
          icon: require('../../assets/img/svg/V2/home/deposit.svg'),
          label: this.$t('common.Deposit'),
          link: '/deposit',
        },
        {
          icon: require('../../assets/img/svg/V2/home/withdrawal.svg'),
          label: this.$t('common.Withdrawal'),
          link: '/withdraw',
        },
        { icon: require('../../assets/img/svg/V2/home/share.svg'), label: this.$t('home.Invite'), link: '/share' },
        {
          icon: require('../../assets/img/svg/V2/home/helpService.svg'),
          label: this.$t('common.OnlineService'),
          type: 'kefu',
        },
        { icon: require('../../assets/img/svg/V2/home/help.svg'), label: this.$t('common.Help'), link: '/help' },
        { icon: require('../../assets/img/svg/V2/home/about.svg'), label: this.$t('common.about'), link: '/about' },
      
      ];
    },
  },
  methods: {
    ...mapMutations({
      updateData: 'exchange/UPDATE_COIN',
      setShowKefu: 'app/SHOW_KEFU',
    }),
    ...mapActions({
      getUserInfo: 'user/getUserInfo',
      getCoinList: 'exchange/getCoinList',
    }),
    swiperChange(i) { },
    async getSwiperList() {
      try {
        const { data, code } = await this.$http.get(ApiPath.getSwiperList);
        if (code == 0) {
          this.swiperList = data;
        }
      } catch (error) { }
    },
    async getMsgUnread() {
      try {
        const { data, code } = await this.$http.get(ApiPath.getMessageUnread);
        if (code == 0) {
          this.unreadCount = data;
        }
      } catch (error) { }
    },
    async onNavTap(item) {
      if (!item.type) {
        this.$router.push(item.link);
        return;
      }

      if (item.type == 'kefu') {
        window.open(this.ossData.customer.line, '_blank');
        // this.showkefu = true;
        // this.setShowKefu(true);
      }
    },
  },

  created() {
    this.getUserInfo();
    this.getSwiperList();
    this.getCoinList();
    this.getMsgUnread();

    // Toast({
    //   type: 'success',
    //   message: 'Hello, this is a test',
    //   className: 'custom-toast', // 添加自定义类名
    //   duration: 20000,
    // });
  },
};
</script>

<style lang="scss" scoped></style>
