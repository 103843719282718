import { ApiPath } from '@/api/path';
import store from '..';
import http from '@/api';
const getDefaultState = () => {
  return {
    robotList: [],
    sellRobotList: [],
    robotOrderList: [],
    robotsLoading: false,
  };
};
const state = getDefaultState();

export const robotsGetter = {
  robotList: (state) => state.robots.robotList,
  sellRobotList: (state) => state.robots.sellRobotList,
  robotOrderList: (state) => state.robots.robotOrderList,
  robotsLoading: (state) => state.robots.robotsLoading,
};

const mutations = {
  SET_ROBOT_LIST: (state, payload) => {
    state.robotList = payload;
  },
  SET_ROBOTS_LOADING: (state, payload) => {
    state.robotsLoading = payload;
  },
  SET_SELLROBOT_LIST: (state, payload) => {
    state.sellRobotList = payload;
  },
  SET_ROBOT_ORDER_LIST: (state, payload) => {
    state.robotOrderList = payload;
  },
};
const actions = {
  async getRobotList(
    { commit },
    params = {
      pageIndex: 1,
      pageSize: 50,
    }
  ) {
    commit('SET_ROBOTS_LOADING', true);
    try {
      const { data, code } = await http.get(ApiPath.getRobotList, params);
      commit('SET_ROBOTS_LOADING', false);
      if (code == 0) {
        commit('SET_ROBOT_LIST', data);
      }
    } catch (error) {
      commit('SET_ROBOTS_LOADING', false);
    }
  },
  async getSellRobotList(
    { commit },
    params = {
      pageIndex: 1,
      pageSize: 50,
    }
  ) {
    const { data, code } = await http.get(ApiPath.getSellRobotList, params);
    if (code == 0) {
      commit('SET_SELLROBOT_LIST', data);
    }
  },
  async getRobotOrderList(
    { commit },
    params = {
      pageIndex: 1,
      pageSize: 50,
    }
  ) {
    const { data, code } = await http.get(ApiPath.getRobotOrderList, params);
    if (code == 0) {
      commit('SET_ROBOT_ORDER_LIST', data);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
