<template>
  <div
    class="rounded-[6px] box-border text-white transition-all duration-300 w-full h-[48px] flex"
    :class="[
      isFocused
        ? `bg-gray-800   ring-[2px]  ring-primary/80`
        : `${glass ? 'bg-gray-700' : 'bg-gray-700'}`,
      glass ? ' backdrop-blur-[10px] bg-opacity-70' : '',
    ]"
  >
    <!-- <div
      class="phoneType"
      v-show="phoneType"
      @click="change_showChooseAreaPhone(true)"
    >
      <span>+886</span>
      <div class="moreTypeIcon"><van-icon name="play" /></div>
    </div> -->
    <div class="flex items-center justify-center px-[8px]">
      <slot name="before" />
    </div>
    <div class="flex-1">
      <input
        class="box-border w-full h-full bg-transparent outline-none placeholder-gray-400/50 text-[16px]"
        :class="inputClass"
        :type="inputType"
        :placeholder="placeholder"
        :value="value"
        @input="$emit('input', $event.target.value)"
        @focus="isFocused = true"
        @blur="isFocused = false"
      />
    </div>
    <div class="flex items-center justify-center px-[8px]">
      <slot name="after" />
      <div class="pr-[8px]" v-if="type == 'password'">
        <button @click="changeType">
          <t-icon
            class="text-primary text-[20px]"
            :icon="this.inputType == 'password' ? 'eye' : 'eye-off'"
          />
        </button>
      </div>
    </div>
    <!-- <div class="canSee" v-show="canSee">
      <van-icon
        v-show="inputType == 'password'"
        name="eye"
        color="#ffe87e"
        size="20"
        @click="inputType = 'password'"
      />
      <van-icon
        v-show="inputType == 'password'"
        name="closed-eye"
        color="#ffe87e"
        size="20"
        @click="inputType = 'text'"
      />
    </div>
    <div class="getCodeBtn" v-show="getCodeBtn">
      <span>{{ $t('input.getCode') }}</span>
    </div> -->
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    value: String,
    inputClass: String,
    type: {
      type: String,
      default: 'text',
    },
    glass: {
      type: Boolean,
      default: false,
    },
    codeImg: {
      default: false,
    },
    canSee: {
      type: Boolean,
      default: false,
    },
    getCodeBtn: {
      default: false,
    },
    size: {
      default: 'normal',
    },
    placeholder: {
      default: '請輸入使用者名稱,字母加數字',
      type: String,
    },
  },
  created() {
    // this.canSee ? (this.inputType = 'password') : '';
  },
  data() {
    return {
      inputType: this.type,
      isFocused: false,
    };
  },
  methods: {
    ...mapMutations('showPopup', ['change_showChooseAreaPhone']),
    changeType() {
      if (this.inputType == 'password') {
        this.inputType = 'text';
      } else {
        this.inputType = 'password';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.FormInput {
  width: 5.9467rem;
  height: 0.8rem;
  padding: 0.2667rem 0.4267rem;
  margin-bottom: 0.4rem;
  background-color: #1f1f1f;
  border-radius: 0.26667rem;
  display: flex;
  position: relative;
  align-items: center;
  input::-webkit-input-placeholder {
    color: #c8c9cc;
  }
  input {
    background-color: rgba(0, 0, 0, 0);
    font-size: 0.3733rem;
    color: #c8c9cc;
    overflow: hidden;
  }
  .phoneType {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-right: 0.4267rem;
    .moreTypeIcon {
      margin-top: -3px;
      margin-left: 6px;
      transform: rotate(90deg);
    }
  }
  .codeImgView {
    flex: 1;
    padding: 10px 0;
    img {
      float: right;
      width: 2.6667rem;
      height: 0.8rem;
    }
  }
  .getCodeBtn {
    text-wrap: nowrap;
    margin-left: 10px;
    padding: 0 5px;
    // width: 2.0533rem;
    height: 0.8533rem;
    line-height: 0.8533rem;
    background-color: #ed531d;
    font-size: 0.32rem;
    text-align: center;
    border-radius: 0.21333rem;
  }
}
</style>
