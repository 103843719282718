<template>
    <div class="bindWalletPage">
        <Navbar NavLeftArrow="true" :NavLeftText="$t('withdraw.WalletAddressBinding')"></Navbar>
        <div class="normalView">
            <h3>USDT</h3>
        </div>
        <div class="walletTitle">
            TRC20
        </div>
        <div class="normalView n2">
            <input type="text" :placeholder="$t('withdraw.walletAddress')">
        </div>
        <p class="desc">
            {{$t('withdraw.t4')}}
        </p>
        <div class="buttonOk">
            {{$t('withdraw.Confirm')}}
        </div>
    </div>
</template>

<script>
    import Navbar from '@/components/Navbar/Navbar.vue';
    export default {
        components:{
            Navbar
        }
    }
</script>

<style lang="scss" scoped>
.bindWalletPage{
    width: 100%;
    min-height: 100%;
    background-color: #161616;
    padding-top: 1.6rem;
    color: #fff;
    .normalView{
        margin: 15px auto;
        padding: 15px 16px; 
        width: 9.2rem;
        height: 1.4133rem;
        background-color: #1f1f1f;
        box-sizing: border-box;
        border-radius: .26667rem;
        h3{
            font-size: .5333rem;
        }
        input{
            font-size: .3733rem;
            background-color: transparent;
            &::-webkit-input-placeholder{
            color: rgba(255,255,255,.7);
        }
        }
       
    }
    .n2{
        margin-top: 10px;
        display: flex;
        align-items: center;
    }
    .walletTitle{
        padding-left: 15px;
        font-size: .4267rem;
    }
    .desc{
        margin: 0 auto;
        width: 345px;
        font-size: .3733rem;
        color: #757a7b!important;
    }
    .buttonOk{
        margin: .32rem auto;
        width: 9.2rem;
        height: 1.3333rem;
        background-color: #ffe87e;
        color: #000;
        border-radius: .21333rem;
        font-size: .4267rem;
        text-align: center;
        line-height: 1.3333rem;
        font-weight: 700;
    }
}
</style>