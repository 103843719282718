<template>
  <button @click="getVCode" :disabled="counting" class="disabled:opacity-70">
    {{ buttonText }}
  </button>
</template>

<script>
let _this;
import { ApiPath } from '@/api/path';
import { Toast } from 'vant';
import { isValidEmail } from '@/utils';
import { mapGetters } from 'vuex';

export default {
  props: {
    countTime: {
      type: Number,
      default: 60,
    },

    email: {
      type: String,
      default: '',
    },
    mobile: {
      type: String,
      default: '',
    },
    type: {
      type: String | Number,
      default: 1,
    },
    areaCode: {
      type: String,
      default: '+886',
    }
  },

  data() {
    return {
      counting: false, // 用于标识是否正在倒计时
      countdown: this.countTime, // 设置倒计时秒数
      sending: false,
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
    buttonText() {
      return this.counting
        ? ` ${this.countdown}s`
        : this.sending
          ? _this.$t('input.Sending')
          : _this.$t('common.get');
    },
  },
  created() {
    _this = this;
  },
  methods: {
    startCountdown() {
      if (!this.counting) {
        this.counting = true;
        this.updateCountdown();
      }
    },
    updateCountdown() {
      if (this.countdown > 0) {
        setTimeout(() => {
          this.countdown--;
          this.updateCountdown();
        }, 1000);
      } else {
        this.counting = false;
        this.$emit('time-up');
      }
    },
    async getVCode() {
      // 注册用
      if (this.type == 1) {
        // 邮箱验证码
        if (this.email) {
          if (!this.email) {
            Toast.fail(_this.$t('input.email'));
            return;
          }
          if (!isValidEmail(this.email)) {
            Toast.fail(_this.$t('common.sendCodeBtn.emaiFail'));
            return;
          }
          this.sending = true;
          try {
            const { data, code, msg } = await this.$http.post(
              ApiPath.sendEmailCode,
              {
                Email: this.email,
              }
            );
            this.sending = false;
            if (code == 0) {
              Toast.success(_this.$t('common.sendCodeBtn.codeSendEmai'));
              this.startCountdown();
            } else {
              Toast.fail(msg);
            }
          } catch (error) {
            this.sending = false;
          }
        }
        // 手机验证码
        if (this.mobile) {
          if (!this.mobile) {
            Toast.fail(_this.$t('input.phone'));
            return;
          }

          this.sending = true;
          try {
            const { data, code, msg } = await this.$http.post(
              ApiPath.sendMobileCode,
              {
                Mobile: this.areaCode + this.mobile,
              }
            );
            this.sending = false;
            if (code == 0) {
              Toast.success(_this.$t('common.sendCodeBtn.phoneInfo'));
              this.startCountdown();
            } else {
              Toast.fail(msg);
            }
          } catch (error) {
            this.sending = false;
          }
        }
      }
      // 修改密码用
      if (this.type == 2) {
        this.sending = true;
        const { data, code, msg } = await this.$http.post(
          ApiPath.sendUserEmailCode
        );
        this.sending = false;
        if (code == 0) {
          Toast.success(
            _this.$t('common.sendCodeBtn.emaiFail.codeSendEmai') +
            ` ${userInfo.Email}`
          );
          this.startCountdown();
        } else {
          Toast.fail(msg);
        }
      }
    },
  },
};
</script>
