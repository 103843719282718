<template>
      <div style="position: fixed; right: 10px; top: 20px;"><a href="javascript:;"  @click="chooseLang" class="">
                <div style="font-size: 12px; color: rgb(255, 255, 255);">{{ $t('common.lang') }}</div>
            </a></div>
</template>

<script>
    export default {
        methods:{
            chooseLang(){
            this.$router.push('/lang')
        }
        }
    }
</script>

<style lang="scss" scoped>

</style>