<template lang="">
  <div class="">
    <button @click="showLang = true">
      <img
        :src="curLang.logo"
        class="w-[24px] object-cover"
        :class="logoClass"
        alt=""
      />
    </button>
    <van-popup v-model="showLang" position="bottom" get-container="body">
      <div class=" priPageBg">
        <div class="p-base t-base  text-gray-400 font-bold ">
          {{ $t('common.lang') }}
        </div>
        <div class="min-h-[300px] pb-[20px] ">
          <t-cell
            v-for="(item, index) in languageList"
            :key="index"
            @click="changeLang(item)"
            class="py-[16px] "
          >
            <img
              slot="leading"
              :src="item.logo"
              class="w-[24px] mr-[12px] object-cover"
              alt=""
            />
            <div>
              {{ item.lang }}
            </div>
          </t-cell>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import { langList } from '@/i18n';
import Store from '@/utils/storage';
import { mapGetters, mapMutations } from 'vuex';
export default {
  props: {
    logoClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showLang: false,
    };
  },
  computed: {
    ...mapGetters(['curLang']),
    languageList() {
      return langList;
    },
  },
  methods: {
    ...mapMutations({
      setLang: 'app/SET_LOCALE',
    }),
    changeLang(lang) {
      console.log(lang);
      Store.setLang(lang.locale);
      this.$i18n.locale = lang.locale; // 修改页面需要显示的语言
      this.setLang(lang);
      this.showLang = false;
    },
  },
  created() {
    const curLocale = Store.getLang();

    if (curLocale) {
      this.changeLang(langList.find((item) => item.locale == curLocale));
    } else {
      this.changeLang(langList[0]);
    }
  },
};
</script>
<style lang=""></style>
