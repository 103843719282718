import { render, staticRenderFns } from "./Kefu.vue?vue&type=template&id=bb0e4eec&scoped=true&"
import script from "./Kefu.vue?vue&type=script&lang=js&"
export * from "./Kefu.vue?vue&type=script&lang=js&"
import style0 from "./Kefu.vue?vue&type=style&index=0&id=bb0e4eec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb0e4eec",
  null
  
)

export default component.exports