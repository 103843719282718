<template lang="">
  <page :title="$t('common.robotTradeList')" class="priPageBg">
    <t-loading v-if="isLoading" bgClass="priPageBg" />
    <t-empty v-else-if="list.length == 0" />

    <div class="px-base space-y-1 priPageBg " v-else>
      <div
        class="card p-base flex items-center priBoxBg "
        v-for="(item, index) in list"
        :key="index"
      >
        <t-img
          :src="item.Logo"
          class="w-[32px] h-[32px] rounded-full mr-[12px]"
        />
        <div class="flex-1 ">
          <div class="t-base">{{ item.Symbol }}</div>
          <div class="t-xs text-gray-400 mt-[8px]">{{ item.CreateTime }}</div>
        </div>
        <div class="flex items-center t-sm">
          <t-icon icon="move-up" class="textPriUp mr-[4px]" />
          <span class="mr-[4px] text-gray-400">{{ $t('Earnings') }}:</span>
          <span class="textPriUp">{{ item.Amount }}</span>
        </div>
      </div>
    </div>
  </page>
</template>
<script>
import { ApiPath } from '@/api/path';
import { getSymbolLogo } from '@/utils';
export default {
  data() {
    return {
      list: [],
      isLoading: false,
    };
  },
  methods: {
    async getRobotTradeList() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
      };
      this.isLoading = true;
      try {
        const { data, code } = await this.$http.get(
          ApiPath.getRobotTradeRecord,
          params
        );

        this.isLoading = false;
        if (code == 0) {
          this.list = data.map((item) => {
            item.Logo = getSymbolLogo(item.Symbol);
            return item;
          });
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getRobotTradeList();
  },
};
</script>
<style lang=""></style>
