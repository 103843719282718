import Vue from 'vue';
import VueI18n from 'vue-i18n';
Vue.use(VueI18n);
// 引入各个语言配置文件
import zh_TW from './config/zh_TW';
import en from './config/en';
import india from './config/india';
import jap from './config/japanese';
import korean from './config/Korean';
import Store from '@/utils/storage';

// // 创建vue-i18n实例i18n
const i18n = new VueI18n({
  // 设置默认语言
  locale: Store.getLang() || 'zh-TW',
  fallbackLocale: 'zh-TW',
  messages: {
    'zh-TW': zh_TW,
    en,
    india,
    jap,
    korean,
  },
});

export default i18n;

export const langList = [
  {
    locale: 'zh-TW',
    lang: '繁體中文',
    logo: require('../assets/img/svg/TW.svg'),
  },
  {
    locale: 'en',
    lang: 'English',
    logo: require('../assets/img/svg/USA.svg'),
  },
  // {
  //   locale: 'jap',
  //   lang: '日本語',
  //   logo: require('../assets/img/svg/Japanese.svg'),
  // },
  // {
  //   locale: 'korean',
  //   lang: '한국인',
  //   logo: require('../assets/img/svg/Korean.svg'),
  // },
  // {
  //   locale: 'india',
  //   lang: 'हिंदी',
  //   logo: require('../assets/img/svg/Hindi.svg'),
  // },
];
