<template>
  <page
    :title="
      $route.query.type == '1'
        ? $t('loginpwd.ChangeLoginPassword')
        : $t('my.b7')
    "
    class="bg-no-repeat bg-fixed bg-top priPageBg" :style="{
    backgroundImage: `url(${require('../../../assets/img/bg-blur.png')})`,
  }"
  >
    <div class="py-2 max-w-[320px] mx-auto space-y-2">
      <div class="">
        <div class="label">{{ $t('loginpwd.OldPassword') }}</div>
        <t-input
          glass
          type="password"
          :placeholder="$t('loginpwd.p1')"
          v-model="formData.OldPassword"
        ></t-input>
      </div>
      <div class="">
        <div class="label">{{ $t('loginpwd.NewPassword') }}</div>
        <t-input
          glass
          type="password"
          :placeholder="$t('loginpwd.p2')"
          v-model="formData.Password"
        ></t-input>
      </div>
      <div class="">
        <div class="label">{{ $t('loginpwd.ConfirmNewPassword') }}</div>
        <t-input
          glass
          type="password"
          :placeholder="$t('loginpwd.p3')"
          v-model="repassword"
        ></t-input>
      </div>
      <!-- <div class="">
        <div class="label">{{ $t('input.Verificationcode') }}</div>
        <t-input
          glass
          :placeholder="$t('input.verification')"
          v-model="formData.VerifyCode"
        >
          <div class="text-blue-400 text-[14px] pr-[4px]" slot="after">
            <send-code-btn :countTime="60" :type="2" />
          </div>
        </t-input>
      </div> -->

      <button class="btn w-full" @click="submitChange">
        {{ $t('loginpwd.Confirm') }}
      </button>
    </div>
  </page>
</template>

<script>
let _this;
import Navbar from '@/components/Navbar/Navbar.vue';
import { ApiPath } from '@/api/path';
import { Toast } from 'vant';
import SendCodeBtn from '@/components/SendCodeBtn.vue';
export default {
  components: {
    Navbar,
    SendCodeBtn,
  },
  data() {
    return {
      repassword: '',
      formData: {
        OldPassword: '',
        Password: '',
        VerifyCode: '',
      },
    };
  },
  methods: {
    async submitChange() {
      if (!this.formData.OldPassword) {
        Toast.fail(_this.$t('loginpwd.p1'));
        return;
      }
      if (!this.formData.Password) {
        Toast.fail(_this.$t('loginpwd.p2'));
        return;
      }
      if (!this.repassword) {
        Toast.fail(_this.$t('loginpwd.p3'));
        return;
      }
      if (this.repassword != this.formData.Password) {
        Toast.fail(_this.$t('loginpwd.p4'));
        return;
      }
      // if (!this.formData.VerifyCode) {
      //   Toast.fail(_this.$t('input.verification'));
      //   return;
      // }
      if (this.$route.query.type == '1') {
        try {
          const { code, msg } = await this.$http.post(
            ApiPath.changeLoginPassword,
            this.formData
          );
          if (code == 0) {
            Toast.success(_this.$t('common.succeed'));
          } else {
            Toast.fail(msg);
          }
        } catch (error) {}
      } else {
        try {
          const { code, msg } = await this.$http.post(
            ApiPath.changePayPassword,
            this.formData
          );
          if (code == 0) {
            Toast.success(_this.$t('common.succeed'));
          } else {
            Toast.fail(msg);
          }
        } catch (error) {}
      }
    },
  },
  created() {
    // console.log(this.$route.query.type);
    _this = this;
  },
};
</script>

<style lang="scss" scoped>
.changPwdPage {
  padding-top: 60px;
  width: 100%;
  min-height: 100%;
  background-color: #161616;
  box-sizing: border-box;
  .inputView {
    padding: 15px;
    .inputItem {
      width: 9.2rem;
      height: 1.3333rem;
      padding: 10px 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-size: 14px;
      background-color: #1f1f1f;
      border-radius: 0.26667rem;
      margin-bottom: 15px;
      input {
        background-color: transparent;
        margin-left: 10px;
        color: #fff;
      }
      ::-webkit-input-placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      span {
        color: #999;
        text-wrap: nowrap;
      }
    }
  }
  .done {
    margin: -15px auto 0;
    width: 9.2rem;
    height: 1.1733rem;
    line-height: 1.1733rem;
    background-color: #ffe87e;
    text-align: center;
    border-radius: 0.21333rem;
    font-size: 14px;
    font-weight: 700;
  }
}
</style>
