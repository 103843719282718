import { ApiPath } from '@/api/path';
import store from '..';
import http from '@/api';
const getDefaultState = () => {
  return {
    walletList: [],
    wallet: {},
    globalSymbol: 'USDT',
    rechargeList: [],
  };
};
const state = getDefaultState();

export const walletGetter = {
  walletList: (state) => state.wallet.walletList,
  wallet: (state) => state.wallet.wallet,
  globalSymbol: (state) => state.wallet.globalSymbol,
  rechargeList: (state) => state.exchange.rechargeList,
};

const mutations = {
  SET_WALLET_LIST: (state, payload) => {
    state.walletList = payload;
  },
  SET_WALLET: (state, payload) => {
    state.wallet = payload;
  },
  SET_RECHARGE_LIST: (state, payload) => {
    state.rechargeList = payload;
  },
};
const actions = {
  async getWalletList({ commit }) {
    const params = {
      type: '', // 1: 兑换
    };
    const { code, data } = await http.get(ApiPath.getWalletList, params);

    if (code == 0) {
      commit('SET_WALLET_LIST', data);
    }
  },
  async getWallet({ commit }, symbol = state.globalSymbol) {
    const params = {
      symbol,
    };
    const { code, data } = await http.get(ApiPath.getWallet, params);

    if (code == 0) {
      commit('SET_WALLET', data);
    }
  },
  async getRechargeAddressList({ commit }) {
    const { code, data } = await http.get(ApiPath.getRechargeAddressList);

    if (code == 0) {
      commit('SET_RECHARGE_LIST', data);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
