<template>
  <div class="welcomePage">
    <div class="logo"></div>
    <div class="fromBtn">
      <button class="fromButton" @click="$router.push('/login')">
        {{ $t('common.login') }}
      </button>
      <button class="fromButton" @click="$router.push('/regist')">
        {{ $t('common.reg') }}
      </button>
    </div>
    <div class="bg-color2"></div>
    <ChooseLang></ChooseLang>
  </div>
</template>

<script>
import ChooseLang from '@/components/ChooseLang/ChooseLang.vue';
export default {
  data() {
    return {};
  },
  components: {
    ChooseLang,
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.welcomePage {
  position: relative;
  width: 100%;
  height: 100%;
  background-size: cover;
  overflow: hidden;
  .logo {
    margin: 10% auto;
    padding-top: 1.06667rem;
    width: 96px;
    height: 0.85333rem;
    max-width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }

  .fromBtn {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .fromButton {
      margin-bottom: 0.4rem;
      background-color: #ffe87e;
      width: 264px;
      height: 44px;
      border-radius: 0.21333rem;
      font-size: 0.37333rem;
      font-weight: 700;
    }
  }

  .img2,
  .img1 {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  .img1 {
    width: 6.4rem;
    bottom: -1.06667rem;
  }

  .img2 {
    width: 5.86667rem;
    bottom: 0;
  }

  .bg-color2 {
    z-index: 4;
    position: absolute;
    height: 103.6vw;
    width: 100vw;
    overflow: hidden;
  }

  .bg-color2:after {
    content: '';
    display: block;
    width: 200%;
    height: 0.8rem;
    background-color: #1f1f1f;
    box-shadow: 0 0 0.53333rem #1f1f1f;
    opacity: 0.1;
    transform: rotate(45deg);
    position: absolute;
    top: -1.6rem;
    left: 0;
    animation: xian 4s infinite;
  }
}

@keyframes xian {
  0% {
    top: -1.6rem;
    left: 0;
  }

  50% {
    left: -110%;
    top: 110%;
  }

  100% {
    top: -1.6rem;
    left: 0;
  }
}
</style>
