<template>
  <div class="langPage">
    <NavBar NavLeftArrow="true" :NavMidText="$t('common.lang')">
      <div slot="leftSolt">
        <span>123</span>
      </div>
    </NavBar>
    <van-radio-group v-model="radio">
      <div class="checkLang" @click="checkoutlang('1', 'en')">
        <div class="langType">
          <img
            src="../../assets/img/svg/EN.svg"
            class="rounded-full w-[32px] h-[32px]"
            alt=""
          />
          <span>English</span>
        </div>
        <van-radio name="1" checked-color="#fcc061"></van-radio>
      </div>
      <div class="checkLang" @click="checkoutlang('2', 'zh')">
        <div class="langType">
          <img
            src="../../assets/img/svg/HK.svg"
            class="rounded-full w-[32px] h-[32px]"
            alt=""
          />
          <span>繁体中文</span>
        </div>
        <van-radio name="2" checked-color="#fcc061"></van-radio>
      </div>
    </van-radio-group>
    <!-- <h4 @mousemove="MouseMove">123</h4> -->
  </div>
</template>

<script>
import NavBar from '@/components/Navbar/Navbar.vue';
export default {
  created() {
    let lang = localStorage.getItem('locale');
    if (lang == 'en') {
      this.radio = '1';
    } else {
      this.radio = '2';
    }
  },
  components: {
    NavBar,
  },
  data() {
    return {
      radio: '1',
    };
  },
  methods: {
    MouseMove() {
      console.log(123);
    },
    checkoutlang(radio, type) {
      this.radio = radio;
      localStorage.setItem('locale', type);
      this.$i18n.locale = type; // 修改页面需要显示的语言
      this.lan = this.$i18n.locale;
      this.$nextTick(() => {
        this.$router.back();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.langPage {
  width: 100%;
  height: 100%;
  background-color: #161616;
  padding-top: 1.6rem;
  .checkLang {
    margin: 15px auto;
    width: 9.2rem;
    height: 1.3333rem;
    background-color: #1f1f1f;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 0.4267rem;
    box-sizing: border-box;
    border-radius: 0.2667rem;
    .langType {
      height: 100%;
      display: flex;
      // justify-content: space-between;
      align-items: center;
      padding-left: 20px;
      box-sizing: border-box;
      span {
        margin-left: 0.6933rem;
      }
    }

    span {
      color: #fff;
      font-size: 0.3467rem;
    }
  }
}
h4 {
  display: block;
  width: 200px;
  height: 200px;
  background-color: red;
}
</style>
