<template>
  <div
    class=" rounded-full fixed bottom-[60px] right-[16px] z-50 	  flex justify-center items-center"
    @click="showKefuPopup(true)"
  >
    <!-- <img src="../../assets/img/index-kefu.png" class="w-[32px]" alt="" /> -->
    <!-- 客服 -->
    <van-popup
      v-model="showKefuDialog"
      position="bottom"
      get-container="body"
      close-on-click-overlay
      @closed="showKefuPopup(false)"
    >
      <div class="text-gray-400 priBoxBg  p-base t-base font-bold">
        {{ $t('common.kefu.onlineKefu') }}
      </div>
      <div class="priBoxBg text-white py-2">
        <t-cell @click="goKefuPage('recharge')">
          <img
            src="../../assets/img/icons/logo_line.png"
            class="w-[24px] mr-[12px]"
            slot="leading"
            alt=""
          />
          {{ $t('common.kefu.RechargeCustomerService') }}
        </t-cell>
        <t-cell @click="goKefuPage('line')">
          <img
            src="../../assets/img/icons/logo_line.png"
            class="w-[24px] mr-[12px]"
            slot="leading"
            alt=""
          />
          LINE
        </t-cell>
        <!-- <t-cell
          icon="message-square"
          icon-class=""
          @click="goKefuPage('onlineservice')"
        >
          {{ $t('common.kefu.CUSTOMERSERVICE') }}
        </t-cell>
        <t-cell
          icon="message-square"
          icon-class=""
          @click="goKefuPage('telegram')"
        >
          Telegram
        </t-cell>
        <t-cell
          icon="message-square"
          icon-class=""
          @click="goKefuPage('whatapp')"
        >
          Whatapp
        </t-cell> -->
      </div>
    </van-popup>
  </div>
</template>

<script>
import { ApiPath } from '@/api/path';
var dom = document.body;
dom.addEventListener('mousemove', function () {
  // console.log(123);
});
import { mapActions, mapGetters, mapMutations } from 'vuex';
export default {
  data() {
    return {
      showKefuDialog: false,
      kefuList: [],
    };
  },
  watch: {
    showKefu(val) {
      this.showKefuDialog = val;
    },
  },
  computed: {
    ...mapGetters(['ossData', 'showKefu']),
  },
  methods: {
    ...mapMutations({
      setShowKefu: 'app/SHOW_KEFU',
    }),
    ...mapActions({
      getOssData: 'app/getOssData',
    }),
    showKefuPopup(isShow) {
      this.setShowKefu(isShow);
    },
    goKefuPage(type) {
      const kefu = this.ossData.customer;
      const url = kefu[type];
      console.log(url);
      // console.log(url);
      window.open(url, '_blank');
    },
    async getKefuList() {
      const { data, code, msg } = await this.$http.post(ApiPath.getKeFuList);

      if (code == 0) {
        this.kefuList = data;
        console.log(data);
      }
    },
  },
  created() {
    this.getOssData();
    this.getKefuList();
  },
};
</script>

<style lang="scss" scoped>
.whs-service-btn {
  padding: 0.13333rem;
  cursor: pointer;
  z-index: 99999999;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 1.46667rem;
  height: 1.46667rem;
  border-radius: 11.46667rem;
  box-shadow: 0.02667rem 0.08rem 0.26667rem #282829;
  background: #1f1f1f;
  box-sizing: border-box;
  img {
    max-width: 100%;
  }
}
</style>
