<template lang="">
  <page :title="curCoin.Symbol" class="priPageBg">
    <div class="p-base flex items-center priPageBg	  ">
      <div class="flex-1">
        <div class="mb-[8px] t-sm text-gray-400">{{ curCoin.Symbol }}</div>
        <div
          class="t-2xl font-bold"
          :class="curCoin.Market?.DiffRate > 0 ? 'text-up' : 'text-down'"
        >
          {{ curCoin.Market?.Price }}
        </div>
        <chg-rate :rate="curCoin.Market?.DiffRate" class="mt-[8px]" />
      </div>
      <div class="flex-1 grid grid-cols-2 gap-[10px] t-sm font-light">
        <div class="space-y-[6px]">
          <div class="note">Open</div>
          <div>{{ curCoin.Market?.Open }}</div>
        </div>
        <div class="space-y-[6px] text-right">
          <div class="note">24H Hight</div>
          <div>{{ curCoin.Market?.High }}</div>
        </div>
        <div class="space-y-[6px]">
          <div class="note">24H Low</div>
          <div>{{ curCoin.Market?.Low }}</div>
        </div>
        <div class="space-y-[6px] text-right">
          <div class="note">24H Vol</div>
          <div>{{ curCoin.Market?.TotalVol }}</div>
        </div>
      </div>
    </div>
    <kchart
      :symbol="curCoin.Symbol"
      v-if="curCoin.Symbol"
      class="mt-2"
      height="400"
    />
    <!-- <trading-view :symbol="curCoin.Symbol" v-if="curCoin.Symbol" /> -->
    <div class="p-base priPageBg">
      <div class="flex">
        <div class="flex-1">
          <div class="flex justify-between t-xs text-gray-400 px-base mb-[8px]">
            <div class="">{{ $t('home.Price') }}</div>
            <div class="">{{ $t('common.number') }}</div>
          </div>
          <DepthBars :data="curCoin.Market" isBuy />
        </div>

        <div class="flex-1">
          <div class="flex justify-between t-xs text-gray-400 px-base mb-[8px]">
            <div class="">{{ $t('common.number') }}</div>
            <div class="">{{ $t('home.Price') }}</div>
          </div>
          <DepthBars :data="curCoin.Market" isReverse />
        </div>
      </div>
    </div>
  </page>
</template>
<script>
import { ApiPath } from '@/api/path';
import Kchart from '@/views/components/Kchart.vue';
import DepthBars from '@/views/components/DepthBar.vue';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    Kchart,
    DepthBars,
  },
  data() {
    return {
      coinData: {},
    };
  },
  computed: {
    ...mapGetters(['curCoin']),
  },
  methods: {
    ...mapMutations({
      setCurCoin: 'exchange/SET_CUR_COIN',
    }),
    async getCoinDetail() {
      const params = {
        symbol: this.$route.params.symbol.replace('_', '/'),
      };
      const { data, code } = await this.$http.get(ApiPath.getCoin, params);
      if (code == 0) {
        this.coinData = data.Market;
        this.setCurCoin(data);
      }
    },
  },
  created() {
    this.getCoinDetail();
  },
};
</script>
<style lang=""></style>
