<!-- 登錄方式---手機號 -->
<template>
           <van-popover v-model="showPopover" theme="dark" :offset="[0,-10]" trigger="click" >
                <div class="chooseLoginType">
                        <div class="radioBox">
                            <span>{{ $t("common.phoneNumber") }}</span>
                            <van-radio-group v-model="radio">
                            <van-radio name="1" label-disabled :checked-color="radioColor"></van-radio>
                            </van-radio-group>
                        </div>
                </div>
                <template #reference>
                    <div class="loginType">
                        {{ $t("common.phoneNumber") }}
                        <div class="moreTypeIcon"><van-icon name="play"/></div>
                    </div>
                </template>
            </van-popover>
</template>

<script>
    export default {
        props:{
            radioColor:{
                default:'#ebd675'
            }
        },
        data() {
            return {
                radio:'1',
                showPopover: false,                
            }
        },
    }
</script>

<style lang="scss" >
// 隐藏三角popover
.van-popover[data-popper-placement^=bottom] .van-popover__arrow{
    display: none!important;
}
.van-popup .chooseLoginType{
        padding: 10px!important;
        background-color: #161616;
        .radioBox{
            display: flex;
            padding: 10px!important;
            background-color: #1f1f1f;
            border-radius: .2667rem;
            font-size: .3733rem;
            span{
                margin-right: 5px;
            }
        }
    }
    .loginType {
            margin-top: 10px;
            // width: 1.5467rem;
            height: .8533rem;
            line-height: .8533rem;
            padding: 0 .4rem;
            border: 0.02667rem solid #282829;
            font-size: .37333rem;
            text-align: center;
            display: flex;
            margin-bottom: 20px;
            text-wrap: nowrap;

            .moreTypeIcon {
                margin-left: 2px;
                transform: rotate(90deg);
            }
        }
</style>

