import Vue from 'vue';
import VueRouter from 'vue-router';

// 5
import Welcome from '../views/Welcome/Welcome.vue';
import Lang from '../views/lang/lang.vue';
import Login from '@/views/Login/Login.vue';
import findPwd from '@/views/FindPassword/FindPassword.vue';
import Regist from '@/views/Regist/regist.vue';

// 权限页 14
import Home from '@/views/Home/HomeRouterView.vue';
import Index from '@/views/Home/Index.vue';
import RobotTab from '@/views/Robot/RobotTab.vue';
import RobotTrades from '@/views/Robot/robot-trades.vue';
import Message from '@/views/Home/Message.vue';
import MessageDetail from '@/views/Home/MessageDetail.vue';
import Christmas from '@/views/Home/Christmas.vue';
import Robot from '@/views/Home/Robot.vue';
import SellRobot from '@/views/Robot/SellRobot.vue';
import About from '@/views/Home/About.vue';
import Share from '@/views/Home/Share.vue';
import Proxy from '@/views/Home/Proxy.vue';
import Deposit from '@/views/Home/deposit.vue';
import Recharge from '@/views/Home/recharge.vue';
import Withdraw from '@/views/Home/Withdraw.vue';
import BindWallet from '@/views/Home/BindWallet.vue';
import Help from '@/views/Home/Help.vue';
import CoinDetail from '@/views/exchange/CoinDetail.vue';

// 行情頁 1
import Trend from '@/views/Home/Trend/Trend.vue';

// 團隊頁 4
import Team from '@/views/Home/Team/Team.vue';
import TeamMember from '@/views/Home/Team/TeamMember.vue';
import Bonus from '@/views/Home/Team/Bonus.vue';
import tmRule from '@/views/Home/Team/tmRule.vue';
// 我的頁 8
import My from '@/views/Home/My/my.vue';
import TradeRecord from '@/views/Home/My/TradeRecord.vue';
import Income from '@/views/Home/My/Income.vue';
import Cost from '@/views/Home/My/Cost.vue';
import WithdrawRecord from '@/views/Home/My/WithdrawRecord.vue';
import DepositRecord from '@/views/Home/My/DepositRecord.vue';
import Bank from '@/views/Home/My/Bank.vue';
import LoginPwd from '@/views/Home/My/LoginPwd.vue';
import Store from '@/utils/storage';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index,
    meta: {
      index: 0,
    },
  },
  {
    path: '/trend',
    name: 'trend',
    component: Trend,
    meta: {
      index: 1,
    },
  },
  {
    path: '/team',
    name: 'team',
    component: Team,
    meta: {
      index: 2,
      requiresAuth: true,
    },
  },
  {
    path: '/robots',
    name: 'robots',
    component: RobotTab,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/robot-trades',
    name: 'robots-trades',
    component: RobotTrades,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/my',
    name: 'My',
    component: My,
    meta: {
      index: 3,
      requiresAuth: true,
    },
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: Welcome,
  },
  {
    path: '/lang',
    name: 'lang',
    component: Lang,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/find-pwd',
    name: 'findPwd',
    component: findPwd,
  },
  {
    path: '/regist',
    name: 'regist',
    component: Regist,
  },
  {
    path: '/message-list',
    name: 'messageList',
    component: Message,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/message-list/:id',
    name: 'messageDetail',
    component: MessageDetail,
  },
  {
    path: '/christmas',
    name: 'Christmas',
    component: Christmas,
  },
  {
    path: '/coins/:symbol',
    name: 'CoinDetail',
    component: CoinDetail,
  },
  {
    path: '/robot',
    name: 'robot',
    component: Robot,
  },
  {
    path: '/sellrobot',
    name: 'sellrobot',
    component: SellRobot,
  },
  {
    path: '/about',
    name: 'about',
    component: About,
  },
  {
    path: '/share',
    name: 'share',
    component: Share,
  },
  {
    path: '/proxy',
    name: 'proxy',
    component: Proxy,
  },
  {
    path: '/deposit',
    name: 'Deposit',
    component: Deposit,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/recharge',
    name: 'Recharge',
    component: Recharge,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/withdraw',
    name: 'Withdraw',
    component: Withdraw,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bind-wallet',
    name: 'bindWallet',
    component: BindWallet,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
  },
  {
    path: '/team-member',
    name: 'teamMember',
    component: TeamMember,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bonus',
    name: 'Bonus',
    component: Bonus,
  },
  {
    path: '/tm-rule',
    name: 'TmRule',
    component: tmRule,
  },
  {
    path: '/trade-record',
    name: 'TradeRecord',
    component: TradeRecord,
  },
  {
    path: '/income',
    name: 'Income',
    component: Income,
  },
  {
    path: '/cost',
    name: 'Cost',
    component: Cost,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/withdraw-record',
    name: 'WithdrawRecord',
    component: WithdrawRecord,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/deposit-record',
    name: 'DepositRecord',
    component: DepositRecord,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/bank',
    name: 'BankBank',
    component: Bank,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/login-pwd',
    name: 'LoginPwd',
    component: LoginPwd,
    meta: {
      requiresAuth: true,
    },
  },
];

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { y: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = Store.getToken(); // 检查用户是否已经登录

  const requieLogin = to.matched.some((item) => {
    return item.meta.requiresAuth;
  });

  if (to.name === 'login') {
    next();
  } else if (requieLogin && !isAuthenticated) {
    // 如果用户需要鉴权但未登录，则重定向到登录页面
    next({ name: 'login' }); // 请根据你的路由配置修改
  } else {
    next();
  }
});

export default router;
