var render, staticRenderFns
import script from "./Robot.vue?vue&type=script&lang=js&"
export * from "./Robot.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./Robot.vue?vue&type=custom&index=0&blockType=div&class=card%20priBoxBg%20overflow-hidden%20&v-for=(item%2C%20index)%20in%20robotOrderList&%3Akey=index"
if (typeof block0 === 'function') block0(component)
import block1 from "./Robot.vue?vue&type=custom&index=1&blockType=van-popup&v-model=show&position=bottom"
if (typeof block1 === 'function') block1(component)

export default component.exports