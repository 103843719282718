import axios from 'axios';

const API_KEY = 'YOUR_GOOGLE_TRANSLATE_API_KEY'; // 替换为你的 Google Translate API 密钥

const translateMessage = async (message, targetLanguage) => {
  const apiUrl = `https://translation.googleapis.com/language/translate/v2?key=${API_KEY}`;

  try {
    const response = await axios.post(apiUrl, {
      q: message,
      target: targetLanguage,
    });

    return response.data.data.translations[0].translatedText;
  } catch (error) {
    console.error('Translation error:', error);
    return message; // 如果翻译失败，返回原消息
  }
};

export default translateMessage;

export const translationMsg = async (msg) => {
  let messageText = msg;
  if (typeof msg != 'string') {
    return msg;
  }

  try {
    const res = await axios.post(
      `http://translate.google.com/translate_a/single?client=gtx&dt=t&dj=1&ie=UTF-8&sl=auto&tl=en&q=${msg}`,
      {},
      {
        headers: {
          'Access-Control-Allow-Origin': '*', // 替换为接口允许的域名，*表示允许所有域名访问（不推荐在生产环境中使用）
          'Content-Type': 'application/json',
        },
      }
    );
    console.log(res);
  } catch (error) {
    console.log(error);
  }

  return messageText;
};
