const zh_TW = {
  common: {
    runningCount: '您當前有 {count} 台機器人正在工作',
    profitRecord: '收益紀錄',
    payPassword: '支付密碼',
    payPasswordTips: '請輸入支付密碼',
    rePayPasswordTips: '請再次輸入支付密碼',
    payPwdNotSame: '兩次支付密碼不一致',
    selectArea: '選擇地區',
    invalidAddress: '無效地址, 請聯繫客服',
    copied: '已複製到剪貼板',
    submitAndWait: '已提交，請等待審批',
    selectCurrency: '選擇貨幣',
    selectWithdrawalMethod: '選擇提領方式',
    enterWithdrawalAddress: '輸入提領地址',
    enterAmount: '輸入金額',
    enterPassword: '輸入密碼',
    withdrawalSubmitted: '提領申請已提交，請等待審核',
    selled: '已轉讓',
    cancel: '取消',
    confirm: '確認',
    selectLinkName: '選擇鏈名',
    withdrawAddress: '提領地址',
    inputWithdrawAddress: '請輸入提領地址',
    transfer: '轉讓',
    loosingText: '釋放即可刷新',
    pullingText: '下拉即可刷新',
    isLoading: '加載中',
    loginTips: '登入後檢視更多內容',
    days: '天',
    hours: '時',
    minutes: '分',
    seconds: '秒',
    selectCoin: '選擇幣種',
    depositAddress: '儲值地址',
    uploadImg: '上傳憑證',
    inputAmount: '請輸入金額',
    robotTradeList: '機器人交易紀錄',
    login: '登录',
    login2: '登入',
    phoneLogin: '手機登入',
    emailLogin: '郵箱登入',
    reg: '註冊',
    lang: '選擇語言',
    phoneNumber: '手機號',
    forgetNumber: '忘記密碼？',
    noCount: '沒有賬戶?',
    signUp: ' 註冊',
    regForEmail: '郵箱注冊',
    regForPhone: '手機注冊',
    robot: '機器人',
    property: '資產',
    about: ' 關於我們 ',
    Deposit: '儲值',
    Withdrawal: '提領',
    Help: ' 幫助指南 ',
    OnlineService: '網路客服',
    number: '數量',
    begin: '開始',
    Nodata: '暫無數據',
    Confirmrecharge: '確認儲值',
    Cancel: '取消',
    verify: '確認',
    Recharge: '儲值',
    Pleaseselect: '請選擇',
    day: '天',
    people: '人',
    succeed: '成功',
    fail: '失敗',
    noMore: '沒有更多了',
    get: '獲取驗證碼',
    Level: '等級',
    Clicktologin: '點選登入',
    loginSeeMore: '登入后可查看更多内容',
    robots: {
      activated: '運行中',
      buyType: '來源',
      Contractpurchase: '簽約購買',
      Successfullysigned: '簽約成功',
      activate: '激活',
      PauseInProgress: '暫停中',
      Expired: '已過期',
      slod: '已售',
    },
    kefu: {
      onlineKefu: '線上客服',
      RechargeCustomerService: '儲值客服',
      AddKefu: '添加客服line號gpt_robot',
      CUSTOMERSERVICE: '聯係客服',
      Telegram: '電報',
      downApp: '下載App',
    },
    wallet: {
      Embodyin: '提領到',
      Handlingcharge: '手續費',
      Actualarrival: '實際到賬',
      Paymentmethod: '收款方式',
      Accountnumber: '賬號',
      username: '戶名',
      Embodimenttime: '提領時間',
    },
    sendCodeBtn: {
      emaiFail: '郵箱格式不正確',
      codeSendEmai: '驗證碼已發送到郵箱',
      phoneInfo: '短信驗證碼已發送',
    },
  },
  noMore: '沒有更多了',
  Earnings: '收益',
  input: {
    phoneEmail: '請輸入你的手機/郵箱',
    phone: '請輸入手機號',
    email: '請輸入郵箱地址',
    pwd: '請輸入密碼',
    pwdAgain: '請再次輸入密碼',
    userName: '請輸入使用者名稱',
    verification: '請輸入驗證碼',
    Verificationcode: '驗證碼',
    getCode: '獲取驗證碼',
    Sending: '發送中',
    findPwd: '找回密碼',
    ResetPwd: '重新設置密碼',
    enterCode: '請輸入驗證碼',
    inviCode: '請輸入邀請碼',
    haveCount: '已有賬戶？',
    pdwUnSame: '兩次密碼輸入不一致',
    regSuccess: '注冊成功',
    loginFail: '使用者名稱或密碼錯誤',
  },
  home: {
    tabs: {
      Home: '首頁',
      Market: '行情',
      Team: '團隊',
      Assets: '帳戶',
    },
    hello: '您好',
    profit: '今日個人收益 ',
    robot: '機器人',
    allRobot: '我的機器人數量：',
    tower: '台',
    Contracted: '簽約購買',
    Price: '價格:',
    ValidDay: '有效期：',
    day: '天',
    StartPrice: '做單價格：',
    USDT: 'USDT/單',
    Service: '服務Service',
    Invite: '分享邀請',
    Deposit: '儲值',
    Withdrawal: '提領',
    OnlineService: '網路客服',
    Help: ' 幫助指南 ',
    about: ' 關於我們 ',
    Partners: '合作夥伴',
    // 其他頁面
    // msg
    message: '我的訊息',
    detailMsg: '訊息詳情',
    // christmas
    chrInvi: '已成功邀請人數 ',
    Redraws: ' 剩餘抽獎次數 ',
    Prizes: '獎品',
    User: '用戶',
    chrDesc: '抽獎規則說明',
    PrizeList: '獎品列表',
    back: '返回',
  },
  robot: {
    MyRobot: '我的機器人',
    Total: '全部',
    NoActivation: '未激活',
    Activation: '已激活',
    PauseInProgress: '暫停中',
    Expired: '已過期',
    slod: '已售',
    Source: '來源: ',
    SourceResult: '簽約購買',
    Price: '  價格：',
    ValidPeriod: '有效期：',

    day: '天',
    Hours: ' 小時 ',
    Minutes: ' 分 ',

    StartUpFunds: '做單價格：',
    USDT: 'USDT/單',
    DateAcquired: '獲得時間：',
    FinalActivationTime: '最後啟動時間：',
    ActivationTime: '激活時間：',
    RunTime: '已運行時間：',
    UniqueCode: '唯一碼：',
    Work: '工作中',
    CapitalTrading: '凍結本金: ',

    Pause: '暫停',

    // 遮罩层
    Forsalegift: '出售/贈送 ',
    UniqueCode1: '唯一碼',
    OfficialPrice: '官方價格',
    SalesValue: '出售價格',
    p1: '請輸入對方使用者名稱',
    p2: ' 轉入財產請仔細審核信息，如操作失誤，無法通過系統或平台客服找回。 ',
    Confirm: '確認',
  },
  share: {
    InviteFriends: '邀請朋友',
    rules: '代理模式',
    title: '邀請好友 共同賺幣',
    desc: '返佣升級，邀請好友共享最高40%交易返佣，和好友一起做老闆吧',
    InvitationCode: '邀請碼：',
    copy: '複製 ',
    link: '推薦連結',
    InvitationRules: '邀請規則',
  },
  Deposit: {
    Deposit: '儲值',
    RechargeInstructions: '儲值說明',
    WalletBalance: '錢包餘額',
    ExchangeRates: '匯率',
    Amounts: '金額',
    Depositamount: '儲值金額：',
    Price: '價格',
    StartUpCapital: '啟動資金',
    t1: '1.下載DEEPSPEED—打開',
    t2: '2.右下角點賬戶—立即註冊—郵箱註冊—輸入驗證碼—手機號碼—手機驗證碼',
    t3: '3.設定PIN碼，以後可以用PIN碼登入DEEPSPEED',
    t4: '實名認證：',
    t5: '立即驗證—身分驗證—上傳身分證正面—上傳身分證反面—上傳手持身分證件於手寫便簽（便簽上寫：申請DEEPSPEED賬號使用，日期，簽名）—人臉識別—完成身分認證—綁定銀行賬戶—送出',
    t6: '入金：',
    t7: '右下角點錢包—點加值—選擇儲值方式—複製賬號—轉賬成功後可以在資金總覽查看台幣餘額',
    t8: '購買USDT：',
    t9: '點擊市場—點TWD—選擇USDT—點買—點市價—100%—買入即可—錢包可以查看USDT數量',
    t10: '提領到平台；',
    t11: '複製我們平台錢包地址—進入DEEPSPEED—點錢包—選擇提領—選擇USDT—粘貼我們平台地址—輸入數量—確認—明細截圖 ',
  },
  withdraw: {
    Accountinformation: '賬號信息',
    Selectacurrency: '選擇幣種',
    Selectpresentationmode: '選擇提領方式',
    Confirmationembodiment: '確認提領',
    Paymentpassword: '請輸入支付密碼',

    WithdrawalMethod: '提領方式',
    WalletBalance: '錢包餘額',
    ExchangeRates: '匯率',
    Amounts: '金額 ',
    info: '請輸入提領金額',
    t1: '提領說明提示',
    t2: '最低提領金額為10 USDT。',
    t3: 'USDT-TRC20交易費為1USDT。 花費時間0-2小時，可能需要額外的時間，與網路雜湊率相關。',
    Recommend: '推薦',
    ERC20TIPS: 'ERC20 TIPS',
    Confirm: '確認',
    Selectwithdrawalmethod: '選擇提領方式',
    Next: '確定',
    Cancel: '取消',
    WalletAddressBinding: '錢包地址綁定',
    walletAddress: '請輸入錢包地址',
    t4: '錢包地址提領繫結說明：請核對您的TRC錢包地址是否有誤，如果錯誤的地址轉賬無法到賬，概不負責。  TRC20的USDT地址以“Tx”開頭。每個賬戶只能繫結一個USDT地址',
  },
  help: {
    HelpCenter: '幫助中心',
    commonProblem: '幫助指南',
    q1: ' DeepSpeedPro機器人的特點 ',
    p1: ' 自動化：DeepSpeedPro機器人可以自動化地處理虛擬幣交易，根據市場趨勢和投資者的風險承受能力，自動調整交易策略。 ',
    p2: ' 數據分析：DeepSpeedPro機器人可以透過大數據分析，對市場趨勢、虛擬貨幣走勢等進行預測和分析，幫助投資者做出更明智的投資決策。 ',
    p3: ' 高效性：DeepSpeedPro機器人可以實現高效的虛擬幣交易，減少了人工操作的時間和成本。 ',
    p4: ' 學習能力：DeepSpeedPro機器人能夠根據交易的結果和市場的變化，不斷學習和優化交易策略。這樣可以提高系統的適應能力和長期盈利能力。 ',
    p5: ' 監控風險：DeepSpeedPro機器人可以實時監控市場風險和投資風險，及時調整交易策略，降低風險。 ',
    p6: ' 低門檻：DeepSpeedPro機器人具有較低的投資門檻，讓您即時參與市場投資。 ',
    p7: ' 方便快捷：DEEPSPEED機器人可以實現24小時不間斷地投資操作，方便快捷。 ',
    q2: ' DeepSpeedPro平台的優勢',
    p8: ' 快速提領。您的提領將會在24小時內完成，最大限度的保障您的資金安全。 ',
    p9: ' 安全、高效的投資環境。保障了您長期、穩定、可持續的盈利。 ',
    p10: ' 便捷的儲值與提領渠道。DeepSpeedPro支持USDT在Trc20與Erc20鏈上的儲值與提領。',
    p11: ' 盈利遠超傳統金融投資。日均收益達到1.4%～2.5%，月均收益高達30%～55%。',
    p12: ' AI智慧交易。DeepSpeedPro自動交易讓客戶無需時刻關注市場動態。 ',
    p13: ' 收入多元化。多種盈利方式，成為代理即可實現低投資高回報。 ',
  },
  trend: {
    Market: '行情',
    CurrentTotal: ' 當前共有 ',
    t1: ' 台 機器人自動做單中 ',
    CurrentRobotLevel: '當前機器人級別：',
    RoboticExpirationDate: '機器人有效期：',
    Status: '狀態：',
    Regular: '正常',
    StartUpFunds: '做單價格：',
    USDT: 'USDT/單 ',
    Earnings: '收益：',
    GotoExchange: '前往交易所',
  },
  team: {
    MyTeam: '我的團隊',
    ReferralRules: '推薦規則',
    CurrentExperience: '當前經驗值：',
    MemberInformation: '會員資料',
    TeamRewards: '團隊獎勵',
    Today: '今天',
    Thisweek: '本週',
    Total: '全部',
    more: '更多',
    Level1Agent: ' 一級會員 ',
    Level2Agent: ' 二級會員 ',
    Level3Agent: ' 三級會員 ',
    AgentRebateCommission: '我的團隊',
  },
  my: {
    InvitationCode: '邀請碼',
    Language: '選擇語言',
    MyWalletBalance: '我的錢包餘額',
    Capitaltrading: '凍結本金',
    Totalofpersonalprofits: '收益累計',
    Todaysagentprofits: '今日代理收益',
    Totalofagentprofits: '代理累計收益',
    Robotscommission: '機器人返傭',
    r1: '交易記錄',
    r2: '收入記錄',
    r3: '消費記錄',
    r4: '取款記錄',
    r5: '儲值記錄',
    Viewmyrobots: '查看我的機器人倉庫',
    b1: '我的銀行卡',
    b2: '我的團隊',
    b3: '代理返傭',
    b4: '網路客服',
    b5: '修改登入密碼',
    b6: '下載APP',
    b7: '修改支付密碼',
    logout: '登出',
    RobotTradingHistory: '交易记录',
  },
  income: {
    IncomeRecords: '收入記錄',
    Makeanorder: '做單',
    TeamRebates: '團隊返傭',
    BuyRobotRebates: '購買機器人返傭',
    Returnofprincipal: '本金返還',
    ForSale: '出售',
    Amounts: '金額',
    Time: '時間',
  },
  cost: {
    ExpenseRecords: '消費記錄',
    BuyRobot: '購買機器人',
    Amounts: '金額',
    Time: '時間',
  },
  withdrawRecord: {
    WithdrawRecords: '取款記錄',
    Feedback: '反饋失敗',
    Withdrawalamount: '提領金額',
    Handlingfee: '手續費',
    WithdrawalChannel: '取款渠道',
    ApplicationTime: '申請時間',
  },
  DepositRecords: {
    DepositRecords: '儲值記錄',
  },
  bank: {
    bank: '我的銀行卡',
    Selectwithdrawalmethod: '選擇提領方式',
    Recommend: '推薦',
    Cancel: '取消',
    Next: '確定',
  },
  loginpwd: {
    ChangeLoginPassword: '修改登入密碼',
    OldPassword: '原始密碼',
    p1: '請輸入原密碼',
    NewPassword: '新密碼',
    p2: '請輸入新密碼',
    ConfirmNewPassword: '確認新密碼',
    p3: '請輸入確認新密碼',
    Confirm: '確認',
    p4: '兩次密碼輸入不一致',
  },
  //機器人購買
  ContractedPurchase: {
    ContractedPurchase: '簽約購買',
    Price: '買入價格',
    Expecteddailyearnings: '預計每日收益',
    Validperiod: '有效期限',
    StartupFunds: '做單價格',
    USDT: 'USDT/單',
    tower: '台',
    CurrentBalance: '當前餘額',
    p1: '溫馨提示：',
    p2: '二號機器人',
    p3: '機器人可購買、轉售和贈與',
    p4: '每個等級的機器人都能同時啟用多個實例，這樣您可以更靈活地管理多個交易策略，提高多元化，最大程度發揮機器人的效能，為您的投資組合帶來更合理的被動收益。',
    p5: '機器人具有時效性，請充分運行機器人，賺取更多收益。',
    p6: '機器人級別越高，做單時間更有效率，更能捕捉收益。',
    Payments: '支付',
    day: '天',
  },
  aboutUs: {
    intro: '簡介',
    VisionandMission: '願景和使命',
    p1: ' DEEPSPEED是一家專注於人工智慧技術研發的科技公司，總部位於美國華盛頓州雷德蒙市。DEEPSPEED結合了人工智慧和大數據分析技術，旨在為投資者提供更智慧、高效和穩健的理財交易體驗，從而產生穩定和長期的收益。',
    p2: ' DEEPSPEED的願景是成為全球領先的智慧理財交易平台，為投資 吉提供最佳的投資策路和交易工具，幫助他們實現財務自由和長期穩定的資本增值。為了實現這一願景，DEEPSPEED的使命是利用人工智慧和大數據分析技術，提供全面的投資組合管理和交易執行服務，以最大程度地降低風險並提高回報。DEEPSPEED將根據投資者的風險承受能力、投資目標和時間 範圍，自動生成最佳的投資組合配置方案，並提供即時的市場分析和交易執行建議。DEEPSPEED還將提供全面的教育和培訓資源，幫助投資者提升理財知識和技能，更好地理解市場趨勢和投資策路。我們相信，只有投資者具備足夠的知識和技能，才能做出明智的投資決策，並在市場波動中保持冷靜和自信。 ',
    p3: ' DEEPSPEED將致力於保護投資者的利益和資金安全。我們採用最先進的加密技術和安全措施，確保投資者的個人信息和交易數據得到妥善保護。我們只與合規機構合作，確保平台的合法性和合規性，並遵守所有相關的法律和監管要求。 ',
    p4: ' 最後，我們將不斷創新和改進我們的平台，以滿足投資者不斷變化的需求和市場環境。我們將緊密關注科技和金融 領域的最新發展，並將其應用於我們的平台，提供更多元化和個性化的服務。 ',
    p5: ' 在DEEPSPEED的引領下，投資者將能夠更輕鬆地實現他們的土 目標，並在投資市場中獲得更穩定和可持續的回報。我們期待著與您攜手合作，共同開創Al智慧理財的新時代。 ',
  },
  record: {
    recharge: '儲值',
    withdraw: '提領',
    SecondContractOrder: '秒合約下單',
    SecondContractSettlement: '秒合約結算',
    CoinTransaction: '幣幣交易',
    OrderInPledge: '質押下單',
    PledgeInterest: '質押結息',
    PledgeToReturnThePrincipal: '質押退還本金',
    PledgeMinusLiquidatedDamages: '質押扣除違約金',
    NewCurrencySubscription: '新幣申購',
    SingaporeDollarSettlement: '新幣結算',
    FlashTransferOut: '閃兌轉出',
    FlashTransfer: '閃兌轉入',
    BuyRobot: '購買機器人',
    FreezeRobot: '凍結機器人做單本金',
    RobotDailYrevenue: '機器人每日收益',
    RobotsDoSinglePrincipalReturn: '機器人做單本金返還',
    TeamRebate: '團隊返佣',
    RobotForSale: '機器人出售',
  },
  "冻结中":"凍結中"
};
export default zh_TW;
