<template>
  <page class="t-sm bg-no-repeat bg-fixed bg-top priPageBg" :title="$t('common.Withdrawal')" :style="{
    backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
  }">
    <button
      class="addCard"
      @click="$router.push('/withdraw-record')"
      slot="nav-action"
    >
      <t-icon icon="gantt-chart-square" />
    </button>
    <div class="card p-base mx-base priBoxBg">
      <div class="t-sm mb-1 opacity-60">{{ $t('withdraw.WalletBalance') }}</div>
      <div class="">
        <span class="t-2xl font-bold">{{ wallet.Balance }}</span>

        <span class="t-sm opacity-60 ml-1">{{ globalSymbol }}</span>
      </div>
    </div>
    <div class="p-[16px] text-white">
      <!-- <h2 class="title-md py-[12px]">
        {{ $t('withdraw.Accountinformation') }}
      </h2> -->
      <div class="mb-base">
        <div class="label">{{ $t('withdraw.Selectacurrency') }}</div>
        <t-selector
          :columns="withdrawTypes.map((item) => item.Name)"
          :label="$t('common.Pleaseselect')"
          @on-confirm="changeCurType"
         
        />
      </div>
      <div class="mb-base">
        <div class="label">{{ $t('withdraw.Selectpresentationmode') }}</div>
        <t-selector
          :columns="channels.map((item) => item.ChannelName)"
          :label="$t('common.Pleaseselect')"
          @on-confirm="changeChannel"
         
        />
      </div>
      <!-- USDT -->
      <div class="" v-if="curChannel.TypeId == 4">
        <div class="mb-base">
          <div class="label">{{ $t('common.selectLinkName') }}</div>
          <div class="flex gap-1">
            <button
              class="btn h-[40px] text-[14px] "
              @click="changeLink(index)"
              :class="
                index == curLinkIndex
                  ? 'bg-primary '
                  : ' text-white bg-[#3C4376] '
              "
              v-for="(item, index) in linkNames"
              :key="index"
            >
              {{ item }}
            </button>
          </div>
        </div>
        <div class="">
          <div class="label">{{ $t('common.withdrawAddress') }}</div>
          <t-input
            type="text"
            :placeholder="$t('common.inputWithdrawAddress')"
            v-model="formData.AccountNumber"
          />
        </div>
      </div>
    </div>
    <div class="px-base">
      <h2 class="t-lg font-bold">
        {{ $t('withdraw.Amounts') }} <span> ( USDT ) </span>
      </h2>
      <div class="mt-1">
        <t-input
          type="text"
          :placeholder="$t('withdraw.info')"
          class="h-[56px]"
          v-model="formData.Amount"
        />
      </div>
      <div class="mt-2">
        <t-input
          type="password"
          :placeholder="$t('withdraw.Paymentpassword')"
          class="h-[56px]"
          v-model="formData.Password"
        />
      </div>
      <!-- <div class="yellowDesc mt-2">
        <p>{{ $t('withdraw.t1') }}</p>
        <p>{{ $t('withdraw.t2') }}</p>
        <p>{{ $t('withdraw.t3') }}</p>
      </div> -->

      <div class="" v-if="false">
        <van-radio-group v-model="withdrawTypeRadio">
          <div
            class="buyItem"
            v-for="(item, index) in withdrawTypes"
            :key="index"
          >
            <img src="../../assets/img/Homeindex/buyType.png" alt="" />
            <div class="desc">
              <p>{{ $t('withdraw.ERC20TIPS') }}</p>
              <span>{{ item.Name }}</span>
            </div>
            <van-radio
              :name="item.Id"
              checked-color="#ffee77"
              shape="square"
              size="20"
            ></van-radio>
          </div>
        </van-radio-group>
      </div>
    </div>
    <!-- 確認 -->
    <div class="">
      <div class="p-2">
        <t-button class="w-full" :loading="isLoading" @click="saveWithdraw">{{
          $t('withdraw.Confirmationembodiment')
        }}</t-button>
      </div>
    </div>
    <!-- <van-dialog :show="showResultDialog" title="提示">
      <div class="">信息已提交，请等待审核</div>
    </van-dialog> -->
  </page>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import { mapActions, mapGetters } from 'vuex';
import { ApiPath } from '@/api/path';
import { Dialog, Toast } from 'vant';
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      radio: '1',
      radio2: '1',
      show: false,
      withdrawTypeRadio: '',
      withdrawTypes: [],
      curType: {},
      channels: [],
      curChannel: {},
      isLoading: false,
      showResultDialog: false,
      formData: {
        Amount: '',
        AccountType: '',
        AccountNumber: '',
        AccountName: '',
        Remark: '',
        Password: '',
      },
      linkNames: ['TRC-20', 'ERC-20'],
      curLinkIndex: 0,
    };
  },
  computed: {
    ...mapGetters(['wallet', 'globalSymbol']),
  },
  watch: {
    withdrawTypeRadio(oldVal) {
      console.log(oldVal);
    },
  },
  methods: {
    ...mapActions({
      getWallet: 'wallet/getWallet',
    }),

    async getWithdrawTypes() {
      const { data, code, msg } = await this.$http.get(
        ApiPath.getWithdrawTypes
      );
      if (code == 0) {
        this.withdrawTypes = data;
        console.log(data);
        this.curType = data[0];
      }
    },

    changeLink(index) {
      this.curLinkIndex = index;
      this.formData.AccountType = this.linkNames[index];
    },

    changeCurType(index) {
      this.curType = this.withdrawTypes[index];
      this.channels = this.curType.Channels;
    },

    changeChannel(index) {
      this.curChannel = this.channels[index];
      console.log(this.curChannel);
    },

    async saveWithdraw() {
      if (!this.curType.Id) {
        Toast.fail(this.$t('common.selectCurrency'));
        return;
      }
      if (!this.curChannel.Id) {
        Toast.fail(this.$t('common.selectWithdrawalMethod'));
        return;
      }
      if (!this.formData.AccountNumber) {
        Toast.fail(this.$t('common.enterWithdrawalAddress'));
        return;
      }
      if (!this.formData.Amount) {
        Toast.fail(this.$t('common.enterAmount'));
        return;
      }
      if (!this.formData.Password) {
        Toast.fail(this.$t('common.enterPassword'));
        return;
      }
      const params = {
        WayId: this.curType.Id,
        ChannelId: this.curChannel.Id,
        Symbol: this.curType.Symbol,
        ...this.formData,
      };
      this.isLoading = true;
      const { data, code, msg } = await this.$http.post(
        ApiPath.saveWithdraw,
        params
      );
      this.isLoading = false;
      if (code == 0) {
        Dialog.alert({
          message: this.$t('common.withdrawalSubmitted'),
          confirmButtonText: this.$t('common.confirm'),
          cancelButtonText: this.$t('common.cancel'),
        }).then(() => {
          // on close
          this.formData = {
            Amount: '',
            AccountType: '',
            AccountNumber: '',
            AccountName: '',
            Remark: '',
            Password: '',
          };
        });
      } else {
        Toast.fail(msg);
      }
    },
  },

  created() {
    this.getWallet(this.globalSymbol);
    this.getWithdrawTypes();
  },
};
</script>

<style lang="scss" scoped></style>
