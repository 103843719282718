import { ApiPath } from '@/api/path';
import store from '..';
import http from '@/api';
const getDefaultState = () => {
  return {
    isLogined: false,
    userInfo: {},
    heartBeatCount: 0,
  };
};
const state = getDefaultState();

export const userGetter = {
  isLogined: (state) => state.user.isLogined,
  userInfo: (state) => state.user.userInfo,
};

const mutations = {
  SET_LOGIN: (state, payload) => {
    state.isLogined = payload;
  },
  SET_UERINFO: (state, payload) => (state.userInfo = payload),
  SET_HEART_COUNT: (state, payload) => {
    state.heartBeatCount++;
  },
};
const actions = {
  async getUserInfo({ commit }) {
    try {
      const { code, data } = await http.get(ApiPath.getUserInfo);

      if (code == 0) {
        commit('SET_UERINFO', data);
        commit('SET_LOGIN', true);
      }
    } catch (error) {}
  },

  async sendHeartBeat({ commit }) {
    const params = {
      ip: '',
    };
    try {
      const { data, code, msg } = await http.get(ApiPath.sendHeartbeat, params);
      if (code == 0) {
        commit('SET_HEART_COUNT');
      }
    } catch (error) {
      console.log(error);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
