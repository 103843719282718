<template>
  <div
    class="robotItem"
    :style="showDetail ? 'height:4.9333rem' : 'height:2.64rem;'"
  >
    <div class="robotState">
      <div class="robotLeft">
        <img :src="robot.CoverImage" alt="" />
        <span>{{ robot?.RobotName }}</span>
        <div class="dot"></div>
      </div>
      <div class="robotRight" @click="$emit('showBuy')">
        <span>{{ $t('home.Contracted') }}</span>
      </div>
    </div>
    <!-- 详情 -->
    <div class="robotDetail" v-show="showDetail">
      <div class="row1">
        <van-icon name="gold-coin-o" size=".42667rem" />
        <p>
          {{ $t('home.Price')
          }}<span>{{ robot.Price | toFixedStr }} {{ globalSymbol }}</span>
        </p>
      </div>
      <div class="row2">
        <van-icon name="underway-o" size=".42667rem" />
        <p>
          {{ $t('home.Contracted') }}:<span
            >{{ robot.Days }} {{ $t('home.day') }}</span
          >
        </p>
      </div>
      <div class="row3">
        <van-icon name="points" size=".42667rem" />
        <p>
          {{ $t('home.StartPrice')
          }}<span>{{ robot.OrderPrice }}{{ $t('home.USDT') }}</span>
        </p>
      </div>
    </div>
    <!-- 展开详情 -->
    <div
      class="robotMore"
      :style="
        showDetail
          ? 'padding-bottom: .2667rem;transform: rotate(180deg);'
          : 'padding-top: .6133rem;transform: rotate(0deg);'
      "
    >
      <img
        src="../../assets/img/Homeindex/upDetail.png"
        alt=""
        @click="showDetail = !showDetail"
      />
    </div>
  </div>
</template>

<script>
import { ApiPath } from '@/api/path';
import { toFixedStr } from '@/utils/filters';
import { mapGetters } from 'vuex';

export default {
  props: ['robot'],
  data() {
    return {
      showDetail: false,
      robotOrderList: [],
    };
  },
  computed: {
    ...mapGetters(['globalSymbol']),
  },
  methods: {
    async getRobotOrderList() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
        status: null, // 0：待激活，1：进行中，2：暂停中，3：已过期，4：已售
      };
      const { data, code } = await this.$http.get(
        ApiPath.getRobotOrderList,
        params
      );
      if (code == 0) {
        this.robotOrderList = data;
      }
    },
  },
  created() {
    this.getRobotOrderList();
  },
};
</script>

<style lang="scss" scoped>
.robotItem {
  width: 100%;
  height: 2.64rem;
  padding: 15px 15px 10px;
  box-sizing: border-box;
  background-color: #1f1f1f;
  border-radius: 0.26667rem;
  margin-bottom: 0.4rem;

  .robotState {
    display: flex;
    justify-content: space-between;
    .robotLeft {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 4rem;
      height: 0.96rem;
      border: 0.02667rem solid #fcc061;
      border-radius: 0.26667rem;
      box-sizing: border-box;
      background-color: #151515;
      img {
        margin: 0 0 0 -0.16rem;
        width: 0.96rem;
        height: 0.96rem;
      }
      span {
        font-size: 0.3733rem;
        margin-left: -0.8267rem;
      }
      .dot {
        width: 0.16rem;
        height: 0.16rem;
        background-color: #fcc061;
        border-radius: 50%;
        margin-right: 0.2667rem;
      }
    }
    .robotRight {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2.3467rem;
      // height: .9333rem;
      text-align: center;
      background-color: #ffe87e;
      color: black;
      font-weight: 700;
      font-size: 0.37333rem;
      border-radius: 0.21333rem;
    }
  }
  .robotDetail {
    padding: 0 0.4rem;
    box-sizing: border-box;
    margin-top: 11px;
    overflow: hidden;
    & > div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .van-icon {
        font-weight: 600;
      }
      p {
        margin-left: 0.1333rem;
        font-size: 0.37333rem;
        span {
          margin-left: 0.2667rem;
          color: #fcc061 !important;
        }
      }
    }
  }
  .robotMore {
    // margin-top: .6133rem;
    display: flex;
    justify-content: center;
    transition: all 0.3s;
    transform-origin: center center;
    img {
      width: 0.48rem;
      height: 0.32rem;
    }
  }
}
</style>
