<template>
  <page :title="$t('withdrawRecord.WithdrawRecords')" class="bg-no-repeat bg-fixed bg-top priPageBg" :style="{
    backgroundImage: `url(${require('../../../assets/img/bg-blur.png')})`,
  }">
    <t-list-wrap @onLoad="getList" :list-loading="isLoading" :is-empty="list.length == 0">
      <div class="p-base space-y-[12px] ">
        <div class="card p-base priBoxBg" v-for="(item, index) in list" :key="index">
          <div class="flex items-center pb-[12px] border-b ">
            <div class="flex-1 text-[16px] font-bold">
              {{ $t('common.wallet.Embodyin') + ` ${item.ChannelName}` }}
            </div>
            <div class="text-[18px] font-bold text-red-500">
              {{ item.Amount }} USDT
            </div>
          </div>
          <div class="space-y-[12px] mt-1">
            <div class="flex items-center text-[14px]">
              <div class="flex-1 text-gray-400">
                {{ $t('common.wallet.Handlingcharge') }}
              </div>
              <span>{{ item.Fee }} USDT</span>
            </div>
            <div class="flex items-center text-[13px]">
              <div class="flex-1 text-gray-400">
                {{ $t('common.wallet.Actualarrival') }}
              </div>
              <span class="text-up">{{ item.RealAmount }} USDT</span>
            </div>
            <div class="flex items-center text-[13px]">
              <div class="flex-1 text-gray-400">
                {{ $t('common.wallet.Paymentmethod') }}
              </div>
              <span>{{ item.ChannelName }}</span>
            </div>
            <div class="flex items-center text-[13px]">
              <div class="flex-1 text-gray-400">
                {{ $t('common.wallet.Accountnumber') }}
              </div>
              <span>{{ item.AccountNumber || '--' }}</span>
            </div>
            <div class="flex items-center text-[13px]">
              <div class="flex-1 text-gray-400">
                {{ $t('common.wallet.username') }}
              </div>
              <span>{{ item.AccountName || '--' }}</span>
            </div>
            <div class="flex items-center text-[13px]">
              <div class="flex-1 text-gray-400">
                {{ $t('common.wallet.Embodimenttime') }}
              </div>
              <span>{{ item.CreateTime }}</span>
            </div>
          </div>
        </div>
      </div>
    </t-list-wrap>
  </page>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import { ApiPath } from '@/api/path';
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      finished: true,
      loading: false,
      isLoading: false,
      status: 0,
      list: [],
    };
  },
  methods: {
    onLoad() {
      console.log('上拉');
    },
    onRefresh() {
      console.log('下拉');
    },
    async getList() {
      const params = {
        pageIndex: 1,
        pageSize: 30,
        status: this.status,
      };
      this.isLoading = true;
      try {
        const { data, code } = await this.$http.get(
          ApiPath.getWithdrawRecords,
          params
        );

        this.isLoading = false;
        if (code == 0) {
          this.list = data;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.getList();
  },
};
</script>

<style lang="scss" scoped>
.recordPage {
  padding-top: 60px;
  background-color: #161616;
  padding-bottom: 10px;

  .recordView {
    margin-top: 12px;

    .recordItem {
      margin: 0 auto 15px;
      width: 9.2rem;
      box-sizing: border-box;
      padding: 15px;
      border-radius: 0.26667rem;
      background-color: #1f1f1f;
      font-size: 0.3733rem;
      color: #fff;

      .title {
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 10px;
      }

      .row {
        margin-top: 10px;
        opacity: 0.6;

        span {
          margin-left: 5px;
          // color: #05ec15!important;
        }
      }
    }
  }
}
</style>
