export default{
	namespaced:true,
	state:()=>({
        showKefu:false,
        showChooseAreaPhone:false
	}),
	mutations:{
        change_showKefu(state,flag){
            state.showKefu=flag
        },
        change_showChooseAreaPhone(state,flag){
            state.showChooseAreaPhone=flag
        }
	},
	getters:{},
}