const korean = {
  common: {
    selectArea: ' 지역을 선택',
    invalidAddress: 'The address is invalid, please contact customer service',
    copied: '클립 보드에 복사되었습니다',
    submitAndWait: '제출되었습니다. 승인을 기다려주세요.',
    selectCurrency: '통화 선택',
    selectWithdrawalMethod: '출금 방법 선택',
    enterWithdrawalAddress: '출금 주소 입력',
    enterAmount: '금액 입력',
    enterPassword: '비밀번호 입력',
    withdrawalSubmitted: '출금 정보가 제출되었습니다. 승인을 기다려주세요.',
    selled: '판매 완료',
    cancel: '취소',
    confirm: '확인하다',
    selectLinkName: '체인 이름 선택',
    withdrawAddress: '출금주소',
    inputWithdrawAddress: '주소를 입력해주세요',
    transfer: 'Transfer',
    loosingText: 'Release and Refresh',
    pullingText: 'Pull to Refresh',
    isLoading: 'Loading',
    loginTips: '로그인 후에 더 많은 콘텐츠를 확인하세요',
    days: '일',
    hours: '시간',
    minutes: '분',
    seconds: '초',
    selectCoin: '코인 선택',
    depositAddress: '입금 주소',
    uploadImg: '이미지 업로드',
    inputAmount: '금액을 입력하세요',
    robotTradeList: '로봇 거래 기록',
    login: '로그인',
    login2: '로그인',
    reg: '회원가입',
    lang: '언어선택',
    phoneNumber: '핸드폰번호',
    forgetNumber: '비밀번호를 잊으셨나요?',
    noCount: '아직 계정이 없습니까?',
    signUp: '회원가입',
    regForEmail: '이메일로 회원가입',
    regForPhone: '핸드폰 회원가입',
    robot: '로봇',
    property: '자산',
    about: ' 우리에 관해서 ',
    Deposit: '저축',
    Withdrawal: '출금',
    Help: ' 도움말 안내 ',
    OnlineService: '온라인 커뮤니티',
    number: '번호',
    begin: '시작',
    Nodata: '아직 데이터가 없습니다.',
    Confirmrecharge: '충전 확인',
    Cancel: '취소',
    verify: '확인',
    Recharge: '충전',
    Pleaseselect: '선택해주세요',
    day: '일',
    people: '사람',
    succeed: '성공',
    fail: '실패',
    noMore: '더 이상 없습니다',
    get: '인증 코드 받아오기',
    Level: '레벨',
    Clicktologin: '로그인 클릭',
    loginSeeMore: '로그인 후 더 많은 것을 확인하세요',
    robots: {
      activated: '달리기',
      buyType: '구매 방식',
      Contractpurchase: '계약 구매',
      Successfullysigned: '계약 성공',
      activate: '활성화',
      PauseInProgress: '중단 처리 중',
      Expired: '만료됨',
      slod: '판매',
    },
    kefu: {
      onlineKefu: '온라인 커뮤니티',
      RechargeCustomerService: '충전 상담대',
      AddKefu: '고객 서비스 추가',
      CUSTOMERSERVICE: '고객 서비스 연락',
      Telegram: '텔레그램',
      downApp: '앱 다운로드',
    },
    wallet: {
      Embodyin: '추출 보기',
      Handlingcharge: '수수료',
      actualarrival: '실제 처착',
      Paymentmethod: '결제 방법',
      Accountnumber: '계좌 번호',
      username: '사용자 이름',
      Embodimenttime: '추출 시간',
    },
    sendCodeBtn: {
      emailFail: '이메일 형식이 올바르지 않습니다',
      codeSendEmail: '인증 코드가 이메일로 전송되었습니다',
      phoneInfo: '문자 인증 코드가 전송되었습니다',
    },
  },
  noMore: '더 이상 없습니다',
  Earnings: '수익',
  input: {
    phoneEmail: '전화번호/이메일 주소를 입력하세요',
    phone: '휴대폰 번호를 입력하세요',
    email: '이메일 주소를 입력하세요',
    pwd: '비밀번호를 입력하세요',
    pwdAgain: '비밀번호를 확인하세요',
    userName: '사용자 이름을 입력하세요(글자+숫자)',
    verification: '인증 코드를 입력하세요',
    Verificationcode: '인증 코드',
    getCode: '인증 코드를 받으세요',
    Sending: '보내는 중',
    findPwd: '비밀번호를 찾으세요',
    ResetPwd: '비밀번호를 재설정하세요',
    enterCode: '인증 코드를 입력하세요',
    inviCode: '초대 코드를 입력하세요',
    haveCount: '이же 계정이 있으신가요?',
    pdwUnSame: '2회 비밀번호 입력이 다르다',
    regSuccess: '등록 완료',
    loginFail: '사용자 이름 또는 비밀번호가 잘못되었습니다',
  },
  home: {
    tabs: {
      Home: '집',
      Market: '시장',
      Team: '팀',
      Assets: '자산',
    },
    hello: '안녕하세요',
    profit: '오늘 개인 이익 ',
    robot: '로봇',
    allRobot: '내 로봇 수량:',
    tower: '터',
    Contracted: '체결 PURCHASE',
    Price: '가격:',
    ValidDay: '유효기간：',
    day: '일',
    StartPrice: '장래 태그 값 :',
    USDT: '달러/단일',
    Service: '서비스',
    Invite: '공유 초대',
    Deposit: '충전',
    Withdrawal: ' 인출 ',
    OnlineService: '온라인 서비스',
    Help: ' 도움말 ',
    about: ' 우리에 대해 ',
    Partners: 'パート너',
    // other pages
    // msg
    message: '내 메시지',
    detailMsg: '메시지 세부사항',
    // christmas
    chrInvi: '성공적으로 초대받은 사람의 수 ',
    Redraws: ' 남은 추첨 횟수 ',
    Prizes: '상품',
    User: '사용자',
    chrDesc: '추첨 규칙의 설명',
    PrizeList: '상품 목록',
    back: '뒤로',
  },
  robot: {
    MyRobot: '내 로봇',
    Total: '전체',
    NoActivation: '미활성화',
    Activation: '활성화',
    PauseInProgress: '중지 진행',
    Expired: '만료됨',
    slod: '판매 완료',
    Source: '출: ',
    SourceResult: '계약 구매',
    Price: '  가:',
    ValidPeriod: '유효 기간：',

    day: '일',
    Hours: ' 시 ',
    Minutes: ' 분 ',

    StartUpFunds: '작업 금액：',
    USDT: '달러/단일',
    DateAcquired: '수신 날짜：',
    FinalActivationTime: '최종 활성화 날짜：',
    ActivationTime: '활성화 시간：',
    RunTime: '운행 시간：',
    UniqueCode: '유니크 코드：',
    Work: '운영 중',
    CapitalTrading: '본금 잠금: ',

    Pause: '중지',

    // 차감 층
    Forsalegift: '판매/선물 ',
    UniqueCode1: '유니크 코드',
    OfficialPrice: '공식 가격',
    SalesValue: '판매 가격',
    p1: '상대 사용자 이름 입력',
    p2: ' 자산 입금은 정확한 입력을 바라며, 운영자 또는 플랫폼 고객 서비스를 통해서만 복구할 수 있습니다. ',
    Confirm: '확인',
  },
  share: {
    InviteFriends: '친구 초대',
    rules: '대리모델',
    title: '친구를 초대하다 함께 벌다',
    desc: '부가적인 보상 업그레이드, 친구를 초대함 최대 40% 거래 보상과 함께하고 친구와 함께 사업을 해요',
    InvitationCode: '초대 코드：',
    copy: '복사 ',
    link: '추천 링크',
    InvitationRules: '초대 규칙',
  },
  Deposit: {
    Deposit: '입금',
    RechargeInstructions: '충전 지침',
    WalletBalance: '지갑 잔고',
    ExchangeRates: '환율',
    Amounts: '금액',
    Depositamount: '입금 금액：',
    Price: '가격',
    StartUpCapital: '시작 자금',
    t1: '1. DEEPSPEED를 다운로드 받고 — 열기',
    t2: '2. 하단 왼쪽에서 계정 — 즉시 등록 — 이메일 등록 — 입력 확인 코드 — 전화번호 — 전화 확인 코드',
    t3: '3. PIN 코드를 설정하고, 나중에 DEEPSPEED를 로그인 할 수 있습니다.',
    t4: '실명 인증：',
    t5: '지금 확인 - 본인 확인 - 신분증 앞면 업로드 - 신분증 뒷면 업로드 - 신분증을 손에 들고 손으로 쓴 메모 업로드 (메모에 쓰기 : BitoPro 계정 사용 신청, 날짜, 서명) - 얼굴 인식 - 완전한 신원 인증 - 은행 계좌 바인딩 - 보내기',
    t6: '입금：',
    t7: '오른쪽 하단에서 지갑 클릭 - 가치 추가 - 저장 방법 선택 - 계정 복사 - 이체 성공 후 펀드 개요에서 대만 달러 잔액을 확인할 수 있습니다.',
    t8: 'USDT 구매 방법:',
    t9: '마켓— TWD— USDT— 구매— 시장가— 100%— 구매하기— 돈夹에서 USDT 수량을 확인할 수 있습니다.',
    t10: '플랫폼으로 인출；',
    t11: '플랫폼의 지갑 주소 복사 - BitoPro 입력 - 지갑 클릭 - 출금 선택 - USDT 선택 - 플랫폼 주소 붙여넣기 - 수량 입력 - 확인 - 세부 정보 스크린샷  ',
  },
  withdraw: {
    Accountinformation: '계정 정보',
    Selectacurrency: '통화 선택',
    Selectpresentationmode: '출금 방법 선택',
    Confirmationembodiment: '출금 확인',
    Paymentpassword: '결제 비밀번호 입력',
    WithdrawalMethod: '출금 방법',
    WalletBalance: '지갑 잔고',
    ExchangeRates: '환율',
    Amounts: '금액 ',
    info: '출금 금액을 입력하세요',
    t1: '출금 설명 알림',
    t2: '최소 출금 금액은 10 USDT입니다.',
    t3: 'USDT-TRC20 거래 수수료는 1USDT입니다. 소요 시간은 0-2시간이며, 网络雜湊 率에 의해서可能需要 더 오랜 시간입니다.',
    Recommend: '추천',
    ERC20TIPS: 'ERC20 TIPS',
    Confirm: '확인',
    Selectwithdrawalmethod: '출금 방법 선택',
    Next: '확정',
    Cancel: '취소',
    WalletAddressBinding: '지갑 주소 바인딩',
    walletAddress: '지갑 주소를 입력하세요',
    t4: '지갑 주소 출금 연결 설명: TRC 지갑 주소를 확인해서, 실수로 다른 주소로 전송은 되지 않으며, 그러한 사태로 인한 결과는 모두 부담합니다. TRC20의 USDT 주소는 “Tx”로 시작합니다.각 계정은 하나의 USDT 주소와 연결됩니다.',
  },
  help: {
    HelpCenter: '도움말센터',
    commonProblem: '일반문제',
    q1: 'DEEPSPEED 로봇의 특징 ',
    p1: '자동화: DEEPSPEED 봇은 암호화폐 거래를 자동으로 처리하여 시장 동향과 투자자의 위험 허용 범위에 따라 거래 전략을 자동으로 조정할 수 있습니다.',
    p2: '데이터 분석: DEEPSPEED BOT은 빅 데이터 분석을 통해 시장 동향, 가상 화폐 동향 등을 예측하고 분석하여 투자자가 정보에 입각한 투자 결정을 내릴 수 있도록 도와줍니다.',
    p3: '고효율: DEEPSPEED 봇은 효율적인 가상 화폐 거래를 달성하여 수동 작업의 시간과 비용을 줄일 수 있습니다.',
    p4: '학습 능력: DEEPSPEED BOT은 거래 결과와 시장 변화에 따라 거래 전략을 지속적으로 학습하고 최적화할 수 있습니다. 이는 시스템의 복원력과 장기적인 수익성을 증가시킵니다.',
    p5: '위험 모니터링: DEEPSPEED BOT은 시장 위험과 투자 위험을 실시간으로 모니터링하고 적시에 거래 전략을 조정하며 위험을 줄일 수 있습니다. ',
    p6: '낮은 문턱: DEEPSPEED 로봇은 투자 문턱이 낮아 더 많은 사람들이 투자에 참여할 수 있습니다.',
    p7: '편리하고 빠름: DEEPSPEED 로봇은 편리하고 빠르게 24시간 중단 없는 투자 작업을 달성할 수 있습니다.',
    q2: 'DEEPSPEED 로봇의 기능 ',
    p8: '빠른 지불. 인출은 2시간 이내에 자금 계좌에 입금되어 자금의 최대한의 안전을 보장합니다.',
    p9: ' 안전하고 효율적인 투자 환경. 100% 일일 수익, 투자 위험 제로, 장기적이고 안정적이며 지속 가능한 수익을 보장합니다.',
    p10: '편리한 입출금 채널. DEEPSPEED는 USDT 저장 및 출금을 지원합니다. ',
    p11: '수익성은 전통적인 금융 투자의 수익성을 훨씬 능가합니다. 하루 소득은 1.4%~2.5%에 달하고 월 소득은 30%~55%에 달합니다.',
    p12: 'AI 지능형 거래. DEEPSPEED 로봇 자동 거래는 고객이 시장을 주시하지 않고도 수익을 올릴 수 있도록 도와줍니다. ',
    p13: '소득 다양화. 수익을 창출하는 다양한 방법, 에이전트가 되면 낮은 투자와 높은 수익을 얻을 수 있습니다.',
  },
  trend: {
    Market: '시장',
    CurrentTotal: ' 현재 한 사람 ',
    t1: ' 세대의 기계가 자동적으로 거래중입니다 ',
    CurrentRobotLevel: '기계 현재 등급:',
    RoboticExpirationDate: '기계 유효기한:',
    Status: '상태:',
    Regular: '정상',
    StartUpFunds: '거래가:',
    USDT: '미화/매물 ',
    Earnings: '이익:',
    GotoExchange: '거래소로 이동',
  },
  team: {
    MyTeam: '제 팀',
    ReferralRules: '추천 규칙',
    CurrentExperience: '현재 경:',
    MemberInformation: '회원 정보',
    TeamRewards: '팀 보상',
    Today: '오늘',
    Thisweek: '이번 주',
    Total: '총',
    more: '더',
    Level1Agent: '1급  Agent',
    Level2Agent: '2급 Agent',
    Level3Agent: '3급 Agent',
    AgentRebateCommission: '제 팀',
  },
  my: {
    InvitationCode: '초대 코드',
    Language: '언어 선택',
    MyWalletBalance: '내 지갑 잔고',
    Capitaltrading: '본금 얼음',
    Totalofpersonalprofits: '개인  PROFIT 총액',
    Todaysagentprofits: '오늘 에이전트 이익',
    Totalofagentprofits: '에이전트 총 이익',
    Robotscommission: '로봇 댓지급',
    r1: '거래 기록',
    r2: '수입 기록',
    r3: '소비 기록',
    r4: '출금 기록',
    r5: '储值 기록',
    Viewmyrobots: '내 로봇 창고 보기',
    b1: '내 은행 카드',
    b2: '내 팀',
    b3: '대리인 댓지급',
    b4: '네트워크  Customer 서비스',
    b5: '로그인 암호 변경',
    b6: 'APP 다운로드',
    b7: '지불 암호 변경',
    logout: '로그아웃',
    RobotTradingHistory: '거래 기록',
  },
  income: {
    IncomeRecords: '수입 기록',
    Makeanorder: '주문하기',
    TeamRebates: '팀 댓지급',
    BuyRobotRebates: '로봇 구매 댓지급',
    Returnofprincipal: '본금 반환',
    ForSale: '판매',
    Amounts: '금액',
    Time: '시간',
  },
  cost: {
    ExpenseRecords: '이용료 기록',
    BuyRobot: '로봇 구매',
    Amounts: '금액',
    Time: '시간',
  },
  withdrawRecord: {
    WithdrawRecords: '출금 기록',
    Feedback: 'フィード백 실패',
    Withdrawalamount: '출금 금액',
    Handlingfee: '수리비용',
    WithdrawalChannel: '출금 채널',
    ApplicationTime: '신청 시간',
  },
  DepositRecords: {
    DepositRecords: '저축 기록',
  },
  bank: {
    bank: '내 은행 카드',
    Selectwithdrawalmethod: '대금 수령 방법 선택',
    Recommend: '추천',
    Cancel: '취소',
    Next: '확인',
  },
  loginpwd: {
    ChangeLoginPassword: '수정 로그인 암호',
    OldPassword: '오래된 암호',
    p1: '원래 암호 를 입력 하세요',
    NewPassword: '새 암호',
    p2: '새 암호 를 입력 하세요',
    ConfirmNewPassword: '확인 새 암호',
    p3: '새 암호 를 확인 하세요',
    Confirm: '확인',
    p4: '두 번 암호 입력  불일치 합니다',
  },
  // 로봇 구매
  ContractedPurchase: {
    ContractedPurchase: '계약 구매',
    Price: ' 구매 가격',
    Expecteddailyearnings: '예상 일일 수익',
    Validperiod: '유효 기간',
    StartupFunds: '단일 계산 가격',
    USDT: '달러/단',
    tower: '대',
    CurrentBalance: '현재 잔고',
    p1: '온라인 :',
    p2: '2번 로봇',
    p3: '로봇은 구매 및 양도가 가능하며, 양도되는 로봇은 활성화되지 않은 로봇, 실행되지 않은 로봇에 한합니다.',
    p4: '각 계급 로봇 동시에 멀티 런칭 가능, 계좌 운용 본금 충분 조치 바랍니다.',
    p5: '봇은 시간에 민감하므로 더 많은 돈을 벌기 위해 봇을 최대한 실행하십시오.',
    p6: '로봇 계급 높을수록, 단일 계산 시간 효율적, 수익 확보가능성 높습니다.',
    Payments: '지불',
    day: '일',
  },

  aboutUs: {
    intro: '소개',
    VisionandMission: ' 비전과 임무',
    p1: ' DEEPSPEED는 AI 기술 탐구에 집중한 테크 회사로서, 본사는 미국 콜로라도 주 던브로를 갖고 있습니다. DEEPSPEED는 AI와 대기자 데이터 분석 기술을 결합하여, 투자자들에게 더 총명하고, 효율적이고, 안정적인 금융 거래 경험을 제공하고자 합니다. 1DEEPSPEED의 비전과 임무 ',
    p2: ' DEEPSPEED의 비전은 세계 최고의 지혜 금융 거래 플랫폼이 되어, 투자자들에게 최고의 투자 전략과 거래 도구를 제공하는 것입니다. 이를 통해서 그들의 재무 자유를 실현하고, 장기 안정적인 자본 증가를 목표로 합니다. 이러한 비전을 실현하기 위해, DEEPSPEED의 임무는 AI와 대기자 데이터 분석 기술을 이용하여, 전체적인 투자 조합 관리와 거래 실행 서비스를 제공하는 것입니다. 이를 통해서 风险的 최대한 감소와 汇报의 최대한 증가를 목표로 합니다. DEEPSPEED는 투자자의 风险 감수력, 투자 목표, 시간 범위를 바탕으로, 자동적으로最佳의 투자 조합 설정을 생성하고, 即時의 시장 분석과 거래 실행 建议를 제공합니다. 또한 DEEPSPEED는 전체적인 교육과 培訓 자원을 제공하여, 투자자들이 금융 지식과 기술을 향상시켜, 시장 동향과 투자 전략을 더 잘 이해할 수 있도록 돕습니다. 우리는 투자자들이 충분한 지식과 기술을 가지고 있只有这样에서야, 明智한 투자 결정을 내리 수 있으며, 시장 파동에서도 냉정하고 자신감을 유지할 수 있다고 믿습니다. ',
    p3: ' DEEPSPEED는 투자자들의 이익과 자금 안전을 보호하기 위해 노력할 것입니다. 우리는 최첨단의 암호화 기술과 안전 조치를 사용하여, 투자자들의 개인 정보와 거래 자료를 제대로 보호할 수 있도록 하겠습니다. 우리는 만약 合规機構와 συνεργασ동합니다만, 플랫폼의 합법성과 合規性을 보장하고, 모든 관련된 법률과 감독 要求를嚴格히 준수합니다. ',
    p4: ' 마지막으로, 우리는 지속적으로 혁신하고 우리의 플랫폼을 개선할 것입니다, 투자자들이 바뀌는 수요와 시장 环境에 의해 모두를 보다 동글화하고 개인화된 서비스를 제공할 수 있도록. 우리는 기술과금융 분야의 최신 발전에 대한紧急 관심을 가지고 있으며, 이를 사용하여 우리의 플랫폼에 더 다양하고 개인화된 서비스를 제공할 수 있습니다. ',
    p5: ' DEEPSPEED의 가이기에, 투자자들은 더 이 lég롭게 그들의 이š 의적标를 실현할 수 있으며, 투기 시장에서 더 안정되고 지속 가능한汇报를 얻을 수 있습니다. 우리는 당신과 DEEPSPEED와 같이, Al 지혜금융의新时代를 개척할 수 있도록 당신과 DEEPSPEED와 같이攜手 공업합니다. ',
  },
  record: {
    Recharge: '충전',
    Withdraw: '인출',
    SecondContractOrder: '초기 계약 명령',
    SecondContractSettlement: '초기 계약 처리',
    CoinTransaction: '코인 거래',
    OrderInPledge: '담보 거래 명령',
    PledgeInterest: '담보  이자',
    PledgeToReturnThePrincipal: '담보 本金 회수',
    PledgeMinusLiquidatedDamages: '담보 - 체결 손해',
    NewCurrencySubscription: '새 코인 구독',
    SingaporeDollarSettlement: '싱가포르 달러 처리',
    FlashTransferOut: '섬광 전송 アウト',
    FlashTransfer: '섬광 전송',
    BuyRobot: '로봇 구매',
    FreezeRobot: '로봇 얼어 돈 담보',
    RobotDailYrevenue: '로봇 일일 수익',
    RobotsDoSinglePrincipalReturn: '로봇 단일 본금 회수',
    TeamRebate: '팀 리바이트',
    RobotForSale: '로봇 판매',
  },
};
export default korean;
