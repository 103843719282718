import { render, staticRenderFns } from "./Proxy.vue?vue&type=template&id=53462750&scoped=true&"
import script from "./Proxy.vue?vue&type=script&lang=js&"
export * from "./Proxy.vue?vue&type=script&lang=js&"
import style0 from "./Proxy.vue?vue&type=style&index=0&id=53462750&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53462750",
  null
  
)

export default component.exports