export const ApiPath = {
  // User
  login: '/api/user/login',
  register: '/api/user/register',
  getUserInfo: '/api/user/get',
  sendUserEmailCode: '/api/user/emailcode', //修改密码
  updateAvatar: '/api/user/avatar/update',
  updateNickName: '/api/user/nickname/update',
  realNameAuth: '/api/user/auth',
  changeLoginPassword: '/api/user/modifypwd',
  changePayPassword: '/api/user/modifypaypwd',
  resetPassword: '/api/user/resetpwd',
  logout: '/api/user/logout',

  // Common
  sendEmailCode: '/api/emailcode/get', //注册
  sendMobileCode: '/api/captcha/get', //注册
  uploadFile: '/api/file/upload',
  uploadBase64File: '/api/file/upload/base64',
  getKeFuList: '/api/customerservice/getlist',

  // Slide
  getSwiperList: '/api/slide/getlist',

  // Robot
  getRobotList: '/api/robot/getlist',
  getRobot: '/api/robot/get',
  addRobotOrder: '/api/robotorder/save',
  getRobotOrderList: '/api/robotorder/getlist',
  getSellRobotList: '/api/robotorder/getselllist',
  activeRobot: '/api/robotorder/active',
  stopRobot: '/api/robotorder/stop',
  sellRobot: '/api/robotorder/sell',
  giveRobot: '/api/robotorder/give',
  getRobotProfit: '/api/robotprofit/getlist',
  getRobotTradeRecord: '/api/robottransaction/getlist',

  // Market
  getCoinList: '/api/tradepair/getlist',
  getCoin: '/api/tradepair/get',
  getKlineHistory: '/api/tradepair/kline/getlist',
  getRobot: '/api/robot/get',

  // Wallet
  getWalletList: '/api/userwallet/getlist',
  getWallet: '/api/userwallet/get',

  // Withdraw
  getWithdrawTypes: '/api/withdrawway/getlist',
  getWithdrawRecords: '/api/withdraw/getlist',
  saveWithdraw: '/api/withdraw/save',

  // Recharge
  getRechargeAddressList: '/api/rechargeaddress/getlist',
  getRechargeRecordList: '/api/recharge/getlist',
  saveRecharge: '/api/recharge/save',
  sendHeartbeat: '/api/user/checkheart',

  // Team
  getMyTeamInfo: '/api/team/get',
  getVipLevelList: '/api/userrank/getlist',
  getPromoteRules: '/api/promoteactivity/getlist',
  getMyTeamList: '/api/team/getlist',
  getMyCommission: '/api/commissionlog/getlist',

  // TransferLog
  getTransferLog: '/api/transferlog/getlist',

  // Message
  getMessageList: '/api/message/getlist',
  getMessage: '/api/message/get',
  getMessageUnread: '/api/message/unread',

  // Notice
  getNoticeList: '/api/notice/getlist',
  getNotice: '/api/notice/get',
};
