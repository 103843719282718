<template lang="">
  <div class="px-base priPageBg">
    <div
      class="rounded-[8px] overflow-hidden bg-card bg-opacity-60 "
    >
      <div class="flex items-center p-base deepPri">
        <img
          src='../../assets/img/svg/V2/AiRobot.svg'
          class="w-[36px] h-[36px] rounded-full object-cover mr-[6px]"
          alt=""
        />
        <div>
          {{ $t('trend.CurrentTotal') }}
          <span class="text-yellow-500">{{ robotOrderList.length }}</span
          >{{ $t('trend.t1') }}
        </div>
      </div>
      <div class="p-base priBoxBg ">
        <t-loading v-if="loading" />
        <div class="" v-else-if="robotOrderList.length == 0">
          <t-empty />
        </div>
        <van-swipe
          class="my-swipe"
          :autoplay="5000"
          :show-indicators="false"
          v-else
        >
          <van-swipe-item v-for="(item, index) in robotOrderList" :key="index">
            <div class="space-y-[12px]">
              <div class="flex items-center">
                <img
                  src="@/assets/img/T2/t1.png"
                  class="w-[20px] mr-[12px]"
                  alt=""
                />
                <div class="flex-1 text-gray-100 ">
                  {{ $t('trend.CurrentRobotLevel') }}
                </div>
                <span>{{ item.RobotName }}</span>
              </div>
              <div class="flex items-center">
                <img
                  src="@/assets/img/T2/t2.png"
                  class="w-[20px] mr-[12px]"
                  alt=""
                />
                <div class="flex-1 text-gray-100">
                  {{ $t('trend.RoboticExpirationDate') }}
                </div>
                <span>
                  {{ item.Days }}
                  {{ $t('common.day') }}
                </span>
              </div>
              <div class="flex items-center">
                <img
                  src="@/assets/img/T2/t3.png"
                  class="w-[20px] mr-[12px]"
                  alt=""
                />
                <div class="flex-1 text-gray-100">
                  {{ $t('trend.Status') }}
                </div>
                <span class="z1">{{ $t('trend.Regular') }}</span>
              </div>
              <div class="flex items-center">
                <img
                  src="@/assets/img/T2/t4.png"
                  class="w-[20px] mr-[12px]"
                  alt=""
                />
                <div class="flex-1 text-gray-100">
                  {{ $t('trend.StartUpFunds') }}
                </div>
                <span> {{ item.OrderPrice }}{{ $t('trend.USDT') }} </span>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    </div>
  </div>
</template>
<script>
import { ApiPath } from '@/api/path';
import { mapActions, mapGetters } from 'vuex';

let _this;
export default {
  data() {
    return {
      robotOrderList: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters(['sellRobotList', 'isLogined']),
  },
  filters: {
    robotStatus(val) {
      switch (val) {
        case 1:
          return _this.$t('trend.Regular');
        case 2:
          return _this.$t('robots.PauseInProgress');
        case 3:
          return _this.$t('robots.Expired');
        default:
          return _this.$t('robots.activate');
      }
    },
  },
  methods: {
    ...mapActions({
      getSellRobotList: 'robots/getSellRobotList',
    }),
    async getRobotOrderList() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
        status: 1, // 0：待激活，1：进行中，2：暂停中，3：已过期，4：已售
      };
      this.loading = true;
      try {
        const { data, code } = await this.$http.get(
          ApiPath.getRobotOrderList,
          params
        );
        this.loading = false;
        if (code == 0) {
          this.robotOrderList = data;
        }
      } catch (error) {
        this.loading = false;
      }
    },
  },
  created() {
    _this = this;
    this.getRobotOrderList();
  },
};
</script>
<style lang="scss"></style>
