<template>
  <page :title="$t('team.MyTeam')">
    <!-- 用户区域 -->
    <div
      class="flex items-center px-base py-[24px] card p-base mx-base bg-gradient-to-r from-primary to-indigo-500 text-white"
    >
      <img
        src="../../../assets/img/TeamPage/user.png"
        alt=""
        class="w-[40px] h-[40px] rounded-full mr-[6px] object-cover border border-white"
      />
      <div class="flex-1">
        <h2 class="text-[18px]">{{ myTeamInfo.UserName }}</h2>
        <div class="opacity-60 font-normal text-[12px] mt-[2px]">
          {{ $t('common.Level') + ':' + myTeamInfo.RankName }}
        </div>
      </div>
      <span
        class="ring-1 ring-white text-white px-[6px] text-[12px] h-[24px] inline-flex items-center rounded-[4px]"
        >{{ $t('team.CurrentExperience') }}{{ myTeamInfo.ExpValue }}</span
      >
    </div>
    <!-- 卡片 -->
    <div class="px-base">
      <!-- <div class="relative">
          <img
            src="../../../assets/img/TeamPage/banner.png"
            class="w-full"
            alt=""
          />
  
          <span class="absolute left-2 bottom-2"> 您已达成该等级 </span>
        </div> -->
    </div>
    <!-- 节点区域 -->
    <div class="p-base">
      <div class="relative p-4 card">
        <div
          class="h-[2px] bg-gray-600 rounded-full absolute top-1/2 -translate-y-1/2 inset-x-0"
        ></div>
        <ul
          class="flex items-center justify-between rounded-full relative z-10"
        >
          <li
            class="rounded-full relative"
            :class="
              extractNumbers(myTeamInfo.RankName) >=
              extractNumbers(item.RankName)
                ? 'bg-primary ring-4 ring-primary/20 w-[10px] h-[10px]'
                : ' bg-gray-600 w-[8px] h-[8px]'
            "
            v-for="(item, index) in vipLevelList"
            :key="index"
          >
            <div
              class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex flex-col items-center text-[12px]"
              :class="
                extractNumbers(myTeamInfo.RankName) >=
                extractNumbers(item.RankName)
                  ? 'text-primary'
                  : ' text-gray-400'
              "
            >
              <div class="">{{ item.RankName }}</div>
              <div class="mt-[28px]">{{ item.Amount }}</div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <!-- 会员资料 -->
    <div class="p-base flex items-center">
      <h4 class="flex-1 text-[18px]">{{ $t('team.MemberInformation') }}</h4>
      <div
        class="text-[12px] space-x-[4px] inline-flex items-center text-gray-400"
        @click="$router.push('/team-member')"
      >
        <span>{{ $t('team.more') }}</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="px-base">
      <div class="card p-base space-y-[6px]">
        <div class="flex items-center py-[12px]">
          <img
            src="../../../assets/img/TeamPage/l1.png"
            class="w-[24px] mr-[10px]"
            alt=""
          />
          <div class="flex-1">{{ $t('team.Level1Agent') }}</div>
          <span class="text-[12px] text-gray-400"
            >{{ myTeamInfo.Level_1 }} {{ $t('common.people') }}</span
          >
        </div>
        <div class="flex items-center py-[12px]">
          <img
            src="../../../assets/img/TeamPage/l2.png"
            class="w-[24px] mr-[10px]"
            alt=""
          />
          <div class="flex-1">{{ $t('team.Level2Agent') }}</div>
          <span class="text-[12px] text-gray-400"
            >{{ myTeamInfo.Level_2 }} {{ $t('common.people') }}</span
          >
        </div>
        <div class="flex items-center py-[12px]">
          <img
            src="../../../assets/img/TeamPage/l3.png"
            class="w-[24px] mr-[10px]"
            alt=""
          />
          <div class="flex-1">{{ $t('team.Level3Agent') }}</div>
          <span class="text-[12px] text-gray-400"
            >{{ myTeamInfo.Level_3 }} {{ $t('common.people') }}</span
          >
        </div>
      </div>
    </div>
    <!-- 团队奖励 -->
    <div class="p-base flex items-center">
      <h4 class="flex-1 text-[18px]">{{ $t('team.TeamRewards') }}</h4>
      <div
        class="text-[12px] space-x-[4px] inline-flex items-center text-gray-400"
        @click="$router.push('/bonus')"
      >
        <span>{{ $t('team.more') }}</span>
        <van-icon name="arrow" />
      </div>
    </div>
    <div class="card p-base grid grid-cols-3 gap-[10px] text-center mx-base">
      <div class="">
        <div class="note mb-1">{{ $t('team.Today') }}</div>
        <div class="text-[20px]">{{ myTeamInfo.DailyReward }}</div>
      </div>
      <div class="">
        <div class="note mb-1">{{ $t('team.Thisweek') }}</div>
        <div class="text-[20px]">{{ myTeamInfo.WeekReward }}</div>
      </div>
      <div class="">
        <div class="note mb-1">{{ $t('team.Total') }}</div>
        <div class="text-[20px]">{{ myTeamInfo.TotalReward }}</div>
      </div>
    </div>
  </page>
</template>

<script>
import { ApiPath } from '@/api/path';
import Navbar from '../../../components/Navbar/Navbar.vue';
import TabPage from '@/components/TabPage.vue';
export default {
  data() {
    return {
      myTeamInfo: {},
      vipLevelList: {},
      promoteRules: {},
      myTeamList: {},
    };
  },
  components: {
    Navbar,
    TabPage,
  },
  methods: {
    async getMyTeamInfo() {
      const { data, code } = await this.$http.get(ApiPath.getMyTeamInfo);
      if (code == 0) {
        this.myTeamInfo = data;
      }
    },
    async getVipLevelList() {
      const { data, code } = await this.$http.get(ApiPath.getVipLevelList);
      if (code == 0) {
        this.vipLevelList = data;
      }
    },
    async getPromoteRules() {
      const { data, code } = await this.$http.get(ApiPath.getPromoteRules);
      if (code == 0) {
        this.promoteRules = data;
      }
    },
    async getMyTeamList() {
      const params = {
        pageIndex: 1,
        pageSize: 20,
        level: '',
      };
      const { data, code } = await this.$http.get(
        ApiPath.getMyTeamList,
        params
      );
      if (code == 0) {
        this.myTeamList = data;
      }
    },

    extractNumbers(inputString) {
      if (typeof inputString != 'string') {
        return [0];
      }
      const numbers = inputString.match(/\d+/g);
      if (numbers) {
        return numbers.map(Number);
      } else {
        return [0];
      }
    },
  },
  created() {
    this.getMyTeamInfo();
    this.getVipLevelList();
    this.getPromoteRules();
    this.getMyTeamList();
  },
};
</script>

<style lang="scss" scoped>
.teamPage {
  background: url(../../../assets/img/TeamPage/bg.png) top no-repeat;
  background-size: contain;

  .rightView {
    text-align: center !important;

    .goRulePage {
      text-wrap: nowrap;
      margin-left: 40px;
      font-size: 14px;
    }
  }

  .userView {
    display: flex;
    align-items: center;
    width: 9.2rem;

    img {
      width: 40px;
      height: 40px;
      border: 0.02667rem solid #fe7;
      border-radius: 1.06667rem;
      box-sizing: border-box;
    }

    p {
      // margin:0 .32rem 0 .2667rem;
      margin: 0px 0.2667rem -0.08rem 0.2667rem;
      color: #ffe87e !important;
      font-size: 0.4rem;
      font-weight: 700;
    }

    span {
      display: block;
      padding: 0 5px;
      // width: 136px;
      text-wrap: nowrap;
      height: 0.64rem;
      line-height: 0.64rem;
      box-sizing: border-box;
      border: 0.02667rem solid #c08a63;
      font-size: 0.32rem;
      text-align: center;
      border-radius: 0.10667rem;
      color: #c08a63;
    }
  }

  .bannerCard {
    position: relative;
    margin: 21px auto 0;
    width: 9.2rem;
    height: 3.3867rem;

    img {
      width: 100%;
      height: 100%;
    }

    span {
      position: absolute;
      left: 0.5333rem;
      top: 2.2933rem;
      font-size: 0.4rem;
      opacity: 0.8;
    }
  }

  .pointView {
    overflow: hidden;
    .lv-tabs {
      position: relative;
      display: flex;
      font-size: 12px;
      text-align: center;
      // &::before {
      //   content: '';
      //   position: absolute;
      //   left: -0.53333rem;
      //   right: -0.53333rem;
      //   display: block;
      //   height: 0.02667rem;
      //   transform: translateY(-50%);
      //   background-color: #413329;
      //   top: 50%;
      // }

      .active .dot {
        background-color: #ffe87e !important;
      }
      // .active {
      //   color: #ffe87e;
      // }

      li.active::after {
        background-color: #ffe87e;
        border-radius: 4px;
      }

      li {
        position: relative;
        z-index: 2;
        list-style: none;
        flex: 1;
        width: 100%;
        text-align: center;
        padding: 0.26667rem 0;
        transition: all 0.3s;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          display: block;
          height: 0.08rem;
          transform: translateY(-50%);
          background-color: #8d6e5d;
          top: 50%;
        }

        .lv-tab-item {
          color: #8d6e5d;

          .dot {
            margin: 0.26667rem auto;
            width: 0.21333rem;
            height: 0.21333rem;
            background-color: #8d6e5d;
            border-radius: 0.53333rem;
          }
        }
      }
    }
  }
  .vipView {
    padding: 0 15px;
    box-sizing: border-box;
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      h4 {
        font-size: 0.4267rem;
        color: #ffe87e;
      }
      .left {
        font-size: 0.3733rem;
        opacity: 0.5;
      }
    }
    .main {
      width: 9.2rem;
      height: 3.8933rem;
      background-color: #1f1f1f;
      border-radius: 0.26667rem;
      padding: 10px 15px;
      box-sizing: border-box;
      font-size: 0.3733rem;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .vipItem {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left {
          display: flex;
          align-items: center;
          img {
            margin-right: 10px;
            width: 0.6133rem;
            height: 0.64rem;
          }
        }
      }
    }
  }
  .teamView {
    padding: 0 15px;
    box-sizing: border-box;
    .header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      h4 {
        font-size: 0.4267rem;
        color: #ffe87e;
      }
      .left {
        font-size: 0.3733rem;
        opacity: 0.5;
      }
    }
    .main {
      width: 9.2rem;
      height: 3.1733rem;
      background-color: #1f1f1f;
      border-radius: 0.26667rem;
      box-sizing: border-box;
      font-size: 0.3733rem;
      position: relative;
      .tableView {
        padding: 20px 15px;
        .th {
          opacity: 0.8;
          padding-bottom: 10px;
          span {
            font-size: 0.3733rem !important;
            font-weight: 400 !important;
          }
        }
        .row {
          display: flex;
          justify-content: space-between;
          span {
            flex: 1;
            display: block;
            text-align: left;
            font-size: 16px;
            font-weight: 700;
          }
        }
      }
      .line {
        width: 100%;
        height: 1px;
        background-color: #313132;
      }
    }
  }
}

.rightView > div {
  text-align: justify !important;
}
</style>
