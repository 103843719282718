<template>
  <page class="bg-no-repeat bg-fixed bg-top priPageBg" :title="$t('my.r1')"  :style="{
      backgroundImage: `url(${require('../../../assets/img/bg-blur.png')})`,
    }">
    <t-list-wrap
      @onLoad="getTransferLog"
      :list-loading="isLoading"
      :is-empty="list.length == 0"
    >
      <div class="p-base space-y-[12px]">
        <div class="card p-base priBoxBg " v-for="(item, index) in list" :key="index">
          <div class="flex">
            <div class="flex-1">
              <div class="flex">
                <span class="text-[16px] font-bold text-white">{{
                  item.Symbol
                }}</span>
                <span class="text-[12px] ml-1 text-primary">{{
                  item.Type | tradeType
                }}</span>
              </div>
              <p class="text-[12px] text-gray-200 mt-1">
                {{ item.CreateTime }}
              </p>
            </div>
            <div class="t-lg text-down">
              {{ item.Amount | toFixedStr }}
            </div>
          </div>
          <div class="text-[12px] text-gray-200 mt-[10px]">
            {{ item.Remark }}
          </div>
        </div>
      </div>
    </t-list-wrap>
  </page>
</template>

<script>
import { ApiPath } from '@/api/path';
import Navbar from '@/components/Navbar/Navbar.vue';
import { toFixedStr } from '@/utils/filters';
let _this;
export default {
  components: {
    Navbar,
  },
  created() {},
  data() {
    return {
      finished: true,
      loading: false,
      isLoading: false,
      list: [],
    };
  },
  methods: {
    onLoad() {
      // console.log('上拉');
    },
    onRefresh() {
      // console.log('下拉');
    },
    async getTransferLog() {
      const params = {
        pageIndex: 1,
        pageSize: 50,
        type: 0, // 0:全部, 1:充值, 2:提现, 3:秒合约下单, 4:秒合约结算, 5:币币交易, 6:质押下单, 7:质押结息, 8:质押退还本金, 9:质押扣除违约金, 10:新币申购, 11:新币结算, 12:闪兑转出, 13:闪兑转入
      };
      const { data, code } = await this.$http.get(
        ApiPath.getTransferLog,
        params
      );
      if (code == 0) {
        this.list = data;
      }
    },
  },
  filters: {
    tradeType(value) {
      // console.log(_this);
      switch (value) {
        case 1:
          return _this.$t('record.recharge');
        case 2:
          return _this.$t('record.withdraw');
        case 3:
          return _this.$t('record.SecondContractOrder');
        case 4:
          return _this.$t('record.SecondContractSettlement');
        case 5:
          return _this.$t('record.CoinTransaction');
        case 6:
          return _this.$t('record.OrderInPledge');
        case 7:
          return _this.$t('record.PledgeInterest');
        case 8:
          return _this.$t('record.PledgeToReturnThePrincipal');
        case 9:
          return _this.$t('record.PledgeMinusLiquidatedDamages');
        case 10:
          return _this.$t('record.NewCurrencySubscription');
        case 11:
          return _this.$t('record.SingaporeDollarSettlement');
        case 12:
          return _this.$t('record.FlashTransferOut');
        case 13:
          return _this.$t('record.FlashTransfer');
        case 14:
          return _this.$t('record.BuyRobot');
        case 15:
          return _this.$t('record.FreezeRobot');
        case 16:
          return _this.$t('record.RobotDailYrevenue');
        case 17:
          return _this.$t('record.RobotsDoSinglePrincipalReturn');
        case 18:
          return _this.$t('record.TeamRebate');
        case 19:
          return _this.$t('record.RobotForSale');

        default:
          return '';
      }
    },
  },
  created() {
    this.getTransferLog();
    _this = this;
  },
};
</script>
<style lang="scss" scoped></style>
