<template>
  <page :title="$t('input.findPwd')"  class="bg-no-repeat bg-fixed  bg-top priPageBg" :style="{
    backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
  }">
    <div class="p-base mx-auto max-w-[360px] space-y-2 ">
      <!-- <div class="py-base">
        <t-tab
          :tabs="[$t('common.regForEmail'), $t('common.regForPhone')]"
          @tab-change="tabChange"
          :tab-index="curTabIndex"
        />
      </div> -->
      <t-input v-model="formData.Email" :placeholder="$t('input.email')"></t-input>

      <t-input type="password" v-model="formData.Password" :placeholder="$t('input.pwd')"></t-input>
      <t-input type="password" v-model="repwd" :placeholder="$t('input.pwdAgain')"></t-input>
      <!-- <t-input :placeholder="$t('input.phone')"></t-input> -->
      <t-input v-model="formData.VerifyCode" :placeholder="$t('input.verification')">
        <div class="text-blue-400 text-[14px] pr-[6px]" slot="after">
          <send-code-btn :countTime="60" :email="formData.Email" />
        </div>
      </t-input>
      <div class="py-base">
        <t-button :loading="isLoading" class="w-full" @click="submitResetPwd">
          {{ $t('input.ResetPwd') }}
        </t-button>
      </div>
    </div>
  </page>
</template>

<script>
import NavBar from '@/components/Navbar/Navbar.vue';
import LoginType from '@/components/LoginType/LoginType.vue';
import { ApiPath } from '@/api/path';
import { Toast } from 'vant';
import SendCodeBtn from '@/components/SendCodeBtn.vue';
export default {
  components: {
    NavBar,
    LoginType,
    SendCodeBtn,
  },
  data() {
    return {
      curTabIndex: 0,
      formData: {
        Email: '',
        Password: '',
        VerifyCode: '',
      },
      repwd: '',
      isLoading: false,
    };
  },
  methods: {
    tabChange(tab) {
      console.log(tab);
    },
    async submitResetPwd() {
      if (!this.formData.Email) {
        Toast.fail(this.$t('input.email'));
        return;
      }
      if (!this.formData.Password) {
        Toast.fail(this.$t('input.pwd'));
        return;
      }
      if (!this.repwd) {
        Toast.fail(this.$t('input.pwdAgain'));
        return;
      }
      if (this.formData.Password != this.repwd) {
        Toast.fail(this.$t('input.pdwUnSame'));
        return;
      }
      if (!this.formData.VerifyCode) {
        Toast.fail(this.$t('input.verification'));
        return;
      }

      this.isLoading = true;
      try {
        const { data, code, msg } = await this.$http.post(
          ApiPath.resetPassword,
          this.formData
        );
        this.isLoading = false;
        if (code == 0) {
          Toast.success(this.$t('common.succeed'));
          this.$router.back();
        } else {
          Toast.fail(msg);
        }
      } catch (error) {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
