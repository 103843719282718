<template>
  <div class="bankPage">
    <Navbar NavLeftArrow="true" :NavLeftText="$t('bank.bank')">
      <div class="addView" slot="rightSolt" @click="show = true">
        <div class="addBox">+</div>
      </div>
    </Navbar>
    <div class="bankView">
      <div class="bankCardItem">
        <p class="title">Wallet Address</p>
        <p class="num">123</p>
        <p class="type">ERC20</p>
      </div>
      <div class="bankCardItem b2">
        <p class="title">Wallet Address</p>
        <p class="num">44455</p>
        <p class="type">TRC20</p>
      </div>
    </div>
    <!-- 彈出層 -->
    <van-popup v-model="show">
      <div class="popupView">
        <div class="header">
          <span>{{ $t('bank.Selectwithdrawalmethod') }}</span>
          <van-icon
            name="cross"
            color="#bababd"
            size="23"
            @click="show = false"
          />
        </div>
        <div class="chooseView">
          <div class="buyItem">
            <img src="../../../assets/img/Homeindex/buyType.png" alt="" />
            <div class="desc">
              <p>USDT（TRC20）</p>
              <span>{{ $t('bank.Recommend') }}</span>
            </div>
            <van-radio-group v-model="radio2">
              <van-radio
                name="1"
                checked-color="#ffee77"
                shape="square"
                size="20"
              ></van-radio>
            </van-radio-group>
          </div>
          <div class="buyItem it2">
            <img src="../../../assets/img/Homeindex/buyType.png" alt="" />
            <div class="desc">
              <p>USDT（ERC20）</p>
            </div>
            <van-radio-group v-model="radio2">
              <van-radio
                name="2"
                checked-color="#ffee77"
                shape="square"
                size="20"
              ></van-radio>
            </van-radio-group>
          </div>
        </div>
        <div class="buttonView">
          <div class="buttonFail" @click="$router.push('')">
            {{ $t('bank.Cancel') }}
          </div>
          <div class="buttonOk" @click="$router.push('/bind-wallet')">
            {{ $t('bank.Next') }}
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import { mapActions, mapGetters } from 'vuex';
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      show: false,
      radio2: '1',
    };
  },
  computed: {
    ...mapGetters(['rechargeList']),
  },
  methods: {
    ...mapActions({
      getRechargeAddressList: 'wallet/getRechargeAddressList',
    }),
  },
  created() {
    this.getRechargeAddressList();
  },
};
</script>

<style lang="scss" scoped>
.bankPage {
  padding-top: 60px;
  width: 100%;
  height: 100%;
  background-color: #161616;
  .addView {
    .addBox {
      float: right;
      width: 0.96rem;
      height: 0.9067rem;
      line-height: 0.9067rem;
      box-sizing: border-box;
      border: 0.02667rem solid #282829;
      border-radius: 0.10667rem.10667rem;
      text-align: center;
      font-size: 22px;
    }
  }
  .bankView {
    margin-top: 15px;
    .bankCardItem {
      margin: 0 auto;
      padding: 0.4267rem;
      box-sizing: border-box;
      width: 9.2rem;
      height: 3.9067rem;
      background-size: 100% 100%;
      color: #fff;
      .title {
        font-size: 15px;
      }
      .num {
        font-size: 24px;
        margin: 10px 0 20px;
      }
      .type {
        font-size: 14px;
      }
    }
    .b2 {
      margin-top: 20px;
      background-size: 100% 100%;
    }
  }
  .popupView {
    width: 9rem;
    height: 8.08rem;
    padding: 0.4rem;
    box-sizing: border-box;
    background-color: #161616;
    border-radius: 10px;
    color: #fff;
    font-size: 0.4267rem;
    overflow: hidden;
    .header {
      display: flex;
      justify-content: space-between;
    }
    .chooseView {
      margin-top: 0.7467rem;
      .buyItem {
        background-color: #161616;
      }
      .it2 {
        height: 1.3333rem !important;
      }
    }
    .buttonView {
      display: flex;
      justify-content: space-between;
      padding: 15px;

      & > div {
        width: 3.5667rem;
        height: 1.1733rem;
        line-height: 1.1733rem;
        border-radius: 0.21333rem;
        box-sizing: border-box;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
      }
      .buttonFail {
        background-color: #ed531d;
      }
      .buttonOk {
        background-color: #ffe87e;
        color: #000;
      }
    }
  }
  .buyItem {
    display: flex;
    align-items: center;
    width: 8.1867rem;
    height: 1.8133rem;
    padding: 0.4rem;
    border: 0.02667rem solid #757a7b;
    background-color: #1f1f1f;
    border-radius: 0.26667rem;
    display: flex;
    justify-content: space-between;
    font-size: 0.3733rem;
    color: #fff;
    box-sizing: border-box;
    margin-bottom: 0.4rem;

    img {
      width: 28px;
      height: 28px;
    }
    .desc {
      margin-left: -103px;
      p {
        margin-bottom: 5px;
      }
      span {
        font-size: 0.32rem;
        opacity: 0.5;
      }
    }
  }
  .van-popup {
    background-color: transparent;
  }
}
</style>
