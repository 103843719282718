<template>
  <page :title="$t('home.message')" class="messagePage min-h-screen  bg-no-repeat bg-fixed bg-top priPageBg" :style="{
    backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
  }">
    <t-list-wrap
      @onLoad="getList"
      :list-loading="listLoading"
      :is-empty="list.length == 0"
    >
      <div class="space-y-[12px]">
        <div
          class="card p-base relative priBoxBg"
          v-for="(item, index) in list"
          @click="$router.push('/message-list' + `/${item.Id}`)"
          :key="index"
        >
          <div class="line-clamp-2">
            {{ item.Content }}
          </div>
          <p class="note mt-[6px]">{{ item.CreateTime | formatDateRemoveT }}</p>
          <div
            class="w-[6px] h-[6px] rounded-full absolute right-0 top-0 bg-red-400"
            v-if="item.State == 0"
          ></div>
        </div>
     
      </div>
    </t-list-wrap>
  </page>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
import { ApiPath } from '@/api/path';
import { translationMsg } from '@/i18n/translation';
export default {
  components: {
    Navbar,
  },
  data() {
    return {
      isLoading: false,
      list: [],
      listLoading: false,
    };
  },
  methods: {
    async getList() {
      const params = {
        pageIndex: 1,
        pageSize: 30,
      };
      this.listLoading = true;
      const { data, code } = await this.$http.get(
        ApiPath.getMessageList,
        params
      );
      this.listLoading = false;
      if (code == 0) {
        this.list = data;
      }
    },
  },
  created() {
    this.getList();
    translationMsg('你好啊，你今年幾歲了？');
  },
};
</script>

<style lang="scss" scoped>
.messagePage {
  width: 100%;
  height: 100%;
  padding-top: 60px;
  box-sizing: border-box;
  .van-pull-refresh {
    min-height: calc(100% - 55px);
    padding: 0.4rem 0.4rem 1.3333rem;

    .msgItem {
      height: 2.0533rem;
      margin-bottom: 0.4rem;
      background-color: #1f1f1f;
      padding: 0.4rem;
      box-sizing: border-box;
      border-radius: 0.26667rem;
      position: relative;
      color: #fff;
      .msgTop {
        font-size: 0.35rem;
        .title {
          margin-left: 0.2667rem;
        }
      }
      .msgTime {
        margin-top: 0.2667rem;
        font-size: 0.32rem;
        color: #757a7b;
      }
    }
    .noMore {
      font-size: 0.37333rem;
      color: #969799;
      text-align: center;
    }
  }
}
</style>
