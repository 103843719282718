const jap = {
  common: {
    selectArea: ' 地域を選択',
    invalidAddress: '無效地址, 請聯繫客服',
    copied: 'クリップボードにコピーされました',
    submitAndWait: '提出されました。承認をお待ちください。',
    selectCurrency: '通貨を選択',
    selectWithdrawalMethod: '引き出し方法を選択',
    enterWithdrawalAddress: '引き出しアドレスを入力',
    enterAmount: '金額を入力',
    enterPassword: 'パスワードを入力',
    withdrawalSubmitted: '出金情報が提出されました。承認をお待ちください。',
    selled: '売り切れ',
    cancel: 'キャンセル',
    confirm: '確認する',
    selectLinkName: 'チェーン名を選択してください',
    withdrawAddress: '出金アドレス',
    inputWithdrawAddress: '住所を入力してください',
    transfer: 'Transfer',
    loosingText: 'Release and Refresh',
    pullingText: 'Pull to Refresh',
    isLoading: 'Loading',
    loginTips: 'ログイン後により多くのコンテンツを表示',
    days: '日',
    hours: '時間',
    minutes: '分',
    seconds: '秒',
    selectCoin: 'コインを選択',
    depositAddress: '入金アドレス',
    uploadImg: '画像をアップロード',
    inputAmount: '金額を入力してください',
    robotTradeList: 'ロボット取引記録',
    login: 'ログイン',
    login2: 'ログイン',
    reg: '注冊',
    lang: '言語選択',
    phoneNumber: '手機番号',
    forgetNumber: '忘記密碼？',
    noCount: '無账戶？',
    signUp: ' 注冊',
    regForEmail: 'メール注冊',
    regForPhone: '手機注冊',
    robot: 'ロボット',
    property: '資産',
    about: ' について ',
    Deposit: '預存',
    Withdrawal: '引き出し',
    Help: ' ヘルプ ',
    OnlineService: 'オンラインサービス',
    number: '数',
    begin: '開始',
    Nodata: '暫無數據',
    Confirmrecharge: '確認充值',
    Cancel: '取消',
    verify: '確認',
    Recharge: 'チャージ',
    Pleaseselect: '選択してください',
    day: '日',
    people: '人',
    succeed: '成功',
    fail: '失敗',
    noMore: 'もうない',
    get: '認証コードを取得',
    Level: 'レベル',
    Clicktologin: 'ログインをクリック',
    loginSeeMore: 'ログイン後、さらに内容を確認できます',
    robots: {
      activated: 'ランニング',
      buyType: '購入方法',
      Contractpurchase: '契約購入',
      Successfullysigned: '契約成功',
      activate: 'アクティベート',
      PauseInProgress: '暫定中',
      Expired: '有効期限切れ',
      slod: '完売',
    },
    kefu: {
      onlineKefu: 'オンラインカスタマーサービス',
      RechargeCustomerService: 'チャージカスタマーサービス',
      AddKefu: 'カスタマーサービスを追加しましたline號DEEPSPEED_robot',
      CUSTOMERSERVICE: '連絡先カスタマーサービス',
      Telegram: 'テレグラム',
      downApp: 'Appをダウンロード',
    },
    wallet: {
      Embodyin: '預金先',
      Handlingcharge: '手数料',
      Actualarrival: '実際到着',
      Paymentmethod: '受け取り方法',
      Accountnumber: '口座番号',
      username: '利用者名',
      Embodimenttime: '預金時間',
    },
    sendCodeBtn: {
      emailFail: 'メールアドレス形式が正しくありません',
      codeSendEmail: '認証コードをメールで送信しました',
      phoneInfo: 'SMS認証コードを送信しました',
    },
  },
  noMore: 'ない',
  Earnings: '収益',
  input: {
    phoneEmail: '請入力您的手機/メール',
    phone: '請入力手機番号',
    email: '請入力メールアドレス',
    pwd: '請入力パスワード',
    pwdAgain: '請入力再確認パスワード',
    userName: '請入力使用者名稱，英字加数字',
    verification: '請入力認証コード',
    Verificationcode: '認証コード',
    getCode: '認証コード取得',
    Sending: '送信中',
    findPwd: 'パスワード忘れた？',
    ResetPwd: 'パスワード再設定',
    enterCode: '請入力認証コード',
    inviCode: '請入力招待コード',
    haveCount: '既にアカウントが存在しますか？',
    pdwUnSame: '2回のパスワード入力が一致しません',
    regSuccess: '登録成功',
    loginFail: 'ユーザー名またはパスワードが間違っています。',
  },
  home: {
    tabs: {
      Home: 'ホーム',
      Market: '市況',
      Team: 'チーム',
      Assets: '資産',
    },
    hello: 'こんにちは',
    profit: '今日個人収益 ',
    robot: 'ロボット',
    allRobot: '私のロボット数：',
    tower: 'タワー',
    Contracted: '契約購入',
    Price: '価格:',
    ValidDay: '有効期限：',
    day: '日',
    StartPrice: '注文価格：',
    USDT: 'ドル/単位',
    Service: 'サービス',
    Invite: 'シェアリング招待',
    Deposit: '預金',
    Withdrawal: '引き出し',
    OnlineService: 'オンラインカスタマーサービス',
    Help: ' ヘルプ ',
    about: ' について ',
    Partners: 'パートナーズ',
    // other pages
    // msg
    message: '私のメッセージ',
    detailMsg: 'メッセージ詳細',
    // christmas
    chrInvi: '成功した招待者の人数 ',
    Redraws: ' 残り抽選回数 ',
    Prizes: '賞品',
    User: 'ユーザー',
    chrDesc: '抽選ルールの説明',
    PrizeList: '賞品リスト',
    back: '戻る',
  },
  robot: {
    MyRobot: '私のロボット',
    Total: '全員',
    NoActivation: '未アクティベーション',
    Activation: 'アクティベーション済',
    PauseInProgress: '一時停止中',
    Expired: '有効期限切れ',
    slod: '販売終了',
    Source: '来源: ',
    SourceResult: '契約購入',
    Price: '  価格：',
    ValidPeriod: '有効期限：',

    day: '日',
    Hours: ' 時間 ',
    Minutes: ' 分 ',

    StartUpFunds: '注文価格：',
    USDT: 'USDT/注文',
    DateAcquired: '獲得日：',
    FinalActivationTime: '最終アクティベーション日時：',
    ActivationTime: 'アクティベーション時間：',
    RunTime: '稼働時間：',
    UniqueCode: '唯一コード：',
    Work: '稼働中',
    CapitalTrading: '凍結資本金: ',

    Pause: '一時停止',

    // 遮罩层
    Forsalegift: '販売/贈与 ',
    UniqueCode1: '唯一コード',
    OfficialPrice: '公式価格',
    SalesValue: '販売価格',
    p1: '相手の使用者名を入力してください。',
    p2: ' 財産の移転は細心の注意を払ってください。操作ミスによるものは、システムまたはプラットフォームのカスタマーサービスを通してのみ戻せません。 ',
    Confirm: '確認',
  },
  share: {
    InviteFriends: '友達を招待する',
    rules: '代理モード',
    title: '友達招待 共にビットコイン稼ぐ',
    desc: 'リバウンドアップ、友達を招待し最高40％の交換リターンと共にボスをやろう！',
    InvitationCode: '招待コード：',
    copy: '複製 ',
    link: '推奨リンク',
    InvitationRules: '招待規則',
  },
  Deposit: {
    Deposit: '預金',
    RechargeInstructions: '再チャージ指示',
    WalletBalance: 'ウォレット余計',
    ExchangeRates: '為替レート',
    Amounts: '金額',
    Depositamount: '預金金額：',
    Price: '価格',
    StartUpCapital: '起動資本金',
    t1: '1.DEEPSPEEDをダウンロード－開く',
    t2: '2.右下隅のアカウント－立即登録－メール登録－認証コード入力－携帯番号－携帯認証番号',
    t3: '3.PINコードを設定し、今後はPINコードを使ってDEEPSPEEDにログインできる。',
    t4: '实名認証：',
    t5: '立即認証－身分認証－身分証正面をアップロード－身分証反面をアップロード－手持ち身分証の写真付き便箋（便箋に書いてください：DEEPSPEEDアカウント申請用、日付、署名）－顔認証－身分認証完了－銀行口座と紐付ける－送信完了。 ',
    t6: '入金： ',
    t7: '右下隅のウォレット－加値する－チャージ方法選ぶ－口座番号をコピー－振込成功後、資金総覧で台幣余計が確認できる ',
    t8: 'USDTを買う： ',
    t9: 'マーケットをクリック－TWD－USDT－買う－市価－100％－買い戻す－ウォレットでUSDTの数が確認できる ',
    t10: '平台への引き出し； ',
    t11: '当社の平台ウォレットアドレスをコピー＆ペースト－DEEPSPEEDに入る－ウォレット選ぶ－USDT引き出し選ぶ－当社の平台アドレスを貼り付ける－引き出し量入力－確認－明細画像 ',
  },
  withdraw: {
    Accountinformation: '口座情報',
    Selectacurrency: '選択する通貨',
    Selectpresentationmode: '選択する表示モード',
    Confirmationembodiment: '確認請求',
    Paymentpassword: '入力してください支払いの暗証番号',
    WithdrawalMethod: '出金方法',
    WalletBalance: 'ウォレットの残高',
    ExchangeRates: '為替レート',
    Amounts: '金額',
    info: '入力してください出金金額',
    t1: '出金方法の説明と注意事項',
    t2: '最低出金金額は10 USDTです。',
    t3: 'USDT-TRC20の交換手数料は1USDTです。 0-2時間の処理時間が必要です。ネットワークのハッシュレートにも影響を受けます。',
    Recommend: '推奨',
    ERC20TIPS: 'ERC20 TIPS',
    Confirm: '確認',
    Selectwithdrawalmethod: '出金方法を選択してください。',
    Next: '次へ',
    Cancel: '取消',
    WalletAddressBinding: 'ウォレットアドレスのバインディング',
    walletAddress: '入力してくださいウォレットアドレス',
    t4: 'TRCウォレットアドレスの説明：口座のTRCウォレットアドレスを確認してください。間違ったアドレスでの振込は到着しません。TRC20のUSDTアドレスは「Tx」で始まります。一人の口座は一度に1つのUSDTアドレスをバインドできます。',
  },
  help: {
    HelpCenter: 'ヘルプセンター',
    commonProblem: '共通問題',
    q1: 'DEEPSPEED機器人的特徴',
    p1: '自動化：DEEPSPEED機器人は、自動的に仮想通貨取引を処理し、市場の動向と投資家のリスク耐性に基づいて、自動的に取引策略を調整します。',
    p2: 'データ分析：DEEPSPEED機器人は、大規模なデータ分析を通じて、市場の動向、仮想通貨の動向等を予測和分析し、投資家がより賢明な投資決断をするのを手助けします。',
    p3: '高効率：DEEPSPEED機器人は、高効率的な仮想通貨取引を実現し、人工操作の時間とコストを削減します。',
    p4: '学習能力：DEEPSPEED機器人は、取引の結果と市場の変化に基づいて、不時に学習と取引策略の最適化を行うことができる。これにより、システムの適応能力と长期的な利益獲得能力を高めることができる。',
    p5: '風險監視：DEEPSPEED機器人は、市場のリスクと投資のリスクを实时監視し、適時に取引策略を調整し、リスクを減少させます。',
    p6: '低門檻：DEEPSPEED機器人は、比較的低い投資門檻を持っており、多くの人々が投資に参加することができる。',
    p7: '便利で素早く：DEEPSPEED機器人は、24時間中断しない投資操作を実現し、便利で素早い。',
    q2: 'DEEPSPEED機器人的特徴',
    p8: '高速提現。提現は2時間内に資金口座に到達し、資金の安全を最大限に保障します。',
    p9: '安全で高効率な投資環境。日均100％利益、ゼロリスク、長期・安定・持続可能な利益保障。',
    p10: '便捷な預け入れと提現チャネル。DEEPSPEEDはUSDTの預け入れと提現をサポートしています。',
    p11: '利益遠超伝統金融投資。日利益1.4%~2.5％、月利益30％~55％',
    p12: 'AI智慧取引。DEEPSPEED機器人の自動取引が客戶の利益を援助し、市場の動向を常時注意する必要はありません。',
    p13: '収入多元化。様々な利益獲得方法で、代理にして低投資高回报を実現できます。',
  },
  trend: {
    Market: '市況',
    CurrentTotal: '現在の合計 ',
    t1: ' 台 機器人が自動で注文を行っています ',
    CurrentRobotLevel: '現在の機器人レベル：',
    RoboticExpirationDate: '機器人の有効期限：',
    Status: '状態：',
    Regular: '通常',
    StartUpFunds: '注文価格：',
    USDT: 'ドル/注文 ',
    Earnings: '利益：',
    GotoExchange: '交易所へ移動',
  },
  team: {
    MyTeam: '私のチーム',
    ReferralRules: '紹介規則',
    CurrentExperience: '現在の経験値：',
    MemberInformation: '会員情報',
    TeamRewards: 'チーム報酬',
    Today: '今日',
    Thisweek: '今週',
    Total: '全て',
    more: '更多',
    Level1Agent: '一級会員 ',
    Level2Agent: '二級会員 ',
    Level3Agent: '三級会員 ',
    AgentRebateCommission: '私のチーム',
  },
  my: {
    InvitationCode: '招待コード',
    Language: '言語を選択',
    MyWalletBalance: '私のウォレットバランス',
    Capitaltrading: '資本トレーディング',
    Totalofpersonalprofits: '個人利益の合計',
    Todaysagentprofits: '今日の代理利益',
    Totalofagentprofits: '代理の累計利益',
    Robotscommission: 'ロボットの返傭',
    r1: '取引記録',
    r2: '収入記録',
    r3: '消費記録',
    r4: '引き出し記録',
    r5: '預金記録',
    Viewmyrobots: '私のロボット倉庫を表示',
    b1: '私の銀行カード',
    b2: '私のチーム',
    b3: '代理の返傭',
    b4: 'ネットワークサービスカウンター',
    b5: 'ログインパスワードを変更',
    b6: 'APPをダウンロード',
    b7: '支払いパスワードを変更',
    logout: 'ログアウト',
    RobotTradingHistory: '取引履歴',
  },
  income: {
    IncomeRecords: '収入記録',
    Makeanorder: '注文',
    TeamRebates: 'チーム返傭',
    BuyRobotRebates: 'ロボット購入返傭',
    Returnofprincipal: '本金返還',
    ForSale: '販売中',
    Amounts: '金額',
    Time: '時間',
  },
  cost: {
    ExpenseRecords: '消費記録',
    BuyRobot: 'ロボット購入',
    Amounts: '金額',
    Time: '時間',
  },
  withdrawRecord: {
    WithdrawRecords: '引き出し記録',
    Feedback: 'フィードバック失敗',
    Withdrawalamount: '引き出し金額',
    Handlingfee: '手数料',
    WithdrawalChannel: '引き出し方法',
    ApplicationTime: '申請時間',
  },
  DepositRecords: {
    DepositRecords: '貯金記録',
  },
  bank: {
    bank: '私の銀行カード',
    Selectwithdrawalmethod: '提現方法を選択',
    Recommend: '推薦',
    Cancel: '取消',
    Next: '確定',
  },
  loginpwd: {
    ChangeLoginPassword: '登入パスワードを変更',
    OldPassword: '古いパスワード',
    p1: '古いパスワードを入力してください',
    NewPassword: '新しいパスワード',
    p2: '新しいパスワードを入力してください',
    ConfirmNewPassword: '新しいパスワードを確認',
    p3: '新しいパスワードを再び入力してください',
    Confirm: '確認',
    p4: '2回目のパスワード入力と一致しません',
  },
  ContractedPurchase: {
    ContractedPurchase: '契約購買',
    Price: '購入価格',
    Expecteddailyearnings: '予想日々利益',
    Validperiod: '有効期間',
    StartupFunds: '作業価格',
    USDT: 'ドル/作業',
    tower: '台',
    CurrentBalance: '現在余額',
    p1: 'ヒント：',
    p2: 'ロボット2号機',
    p3: 'ロボットは購入して譲渡することができますが、譲渡できるロボットは未起動・未実行のロボットに限ります。',
    p4: '各レベルのボットは同時に複数実行できるため、アカウント操作プリンシパルが十分であることを確認してください。',
    p5: 'ボットは時間に敏感ですので、ボットを最大限に実行して、より多くの収入を得てください。',
    p6: 'ロボットのレベルが高ければ高いほど、作業時間が効率化され、収益をよりよく獲得できます。',
    Payments: '払う',
    day: '日',
  },
  aboutUs: {
    intro: '概要',
    VisionandMission: 'ビジョンと使命',
    p1: 'DEEPSPEEDは人工知能技術の研究開発に専念する科学技術会社で,米国コロラド州デンバーに本社を置く,DEEPSPEEDは人工知能とビッグデータ分析技術を結合し,投資家により知恵,効率,安定した財テク取引体験を提供し,安定と長期的な収益を生み出すことを目的としている,1 DEEPSPEEDのビジョンと使命',
    p2: 'DEEPSPEEDのビジョンは世界をリードする知的財産取引プラットフォームとなり,投資吉に最適な投資策と取引ツールを提供し,財務の自由と長期的に安定した資本の付加価値を実現するのを支援することである,このビジョンを実現するために,DEEPSPEEDの使命は,人工知能とビッグデータ分析技術を利用して,リスクを最小限に抑え,リターンを高めるための包括的なポートフォリオ管理とトランザクション実行サービスを提供することです,DEEPSPEEDは投資家のリスク負担能力,投資目標,時間範囲に基づいて,最適なポートフォリオ配置方案を自動的に生成し,即時の市場分析と取引実行提案を提供する,DEEPSPEEDはまた,投資家が財テクの知識とスキルを向上させ,市場動向と投資策をよりよく理解するための包括的な教育とトレーニング資源を提供する,私たちは,投資家が十分な知識とスキルを持っていてこそ,賢明な投資決定を下すことができ,市場の変動の中で冷静さと自信を保つことができると信じています.',
    p3: 'DEEPSPEEDは投資家の利益と資金安全の保護に力を入れる,デルは,投資家の個人情報と取引データを適切に保護するために,最先端の暗号化技術とセキュリティ対策を採用しています,デルは,プラットフォームの正当性とコンプライアンスを確保し,関連するすべての法律と規制要件を遵守するために,コンプライアンス機関とのみ協力しています.',
    p4: '最後に,投資家の変化するニーズと市場環境を満たすために,デルのプラットフォームを革新し,改善していきます,私たちは科学技術と金融分野の最新の発展に緊密に注目し,それを私たちのプラットフォームに応用し,より多くのメタライズと個性的なサービスを提供します.',
    p5: 'DEEPSPEEDのリードの下で,投資家はより簡単に土の目標を実現し,投資市場でより安定した持続可能なリターンを得ることができます,私たちはあなたと手を携えて,Al知恵財テクの新しい時代を切り開くことを期待しています',
  },
  record: {
    recharge: 'チャージ',
    withdraw: '引き出し',
    SecondContractOrder: '秒契約注文',
    SecondContractSettlement: '秒契約清算',
    CoinTransaction: 'コイン取引',
    OrderInPledge: '質押注文',
    PledgeInterest: '質押利息',
    PledgeToReturnThePrincipal: '質押本金返還',
    PledgeMinusLiquidatedDamages: '質押扣除清算損失',
    NewCurrencySubscription: '新貨幣申購',
    SingaporeDollarSettlement: 'シンガポールドル清算',
    FlashTransferOut: 'Flash Transfer Out',
    FlashTransfer: 'Flash Transfer',
    BuyRobot: '買いロボット',
    FreezeRobot: '凍結ロボット注文本金',
    RobotDailYrevenue: 'ロボット毎日収益',
    RobotsDoSinglePrincipalReturn: 'ロボット注文本金返還',
    TeamRebate: 'チームリバース',
    RobotForSale: 'ロボット販売',
  },
};
export default jap;
