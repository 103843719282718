import '@/assets/css/tailwind.css';
import '@/assets/css/styles.css';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import '@/assets/css/init.scss';
import '@/assets/js/flexible';
// import '@/assets/css/custom-vant.scss';
import './components/ui';



import i18n from './i18n/index';

Vue.config.productionTip = false;
import Vant from 'vant';
import 'vant/lib/index.css';
import { HttpClientPlugin } from './api';

import * as filters from './utils/filters';

Object.keys(filters).forEach((key) => {
  Vue.filter(key, filters[key]);
});

Vue.use(HttpClientPlugin);

import VueClipboard from 'vue-clipboard2';
Vue.use(VueClipboard);

Vue.use(Vant);

import VueNativeSock from 'vue-native-websocket';
Vue.use(VueNativeSock, 'wss://wss.vndirectshop.com', {
  reconnection: true,
  format: 'json',
});

export const EventBus = new Vue();

Vue.prototype.$socket.onmessage = function (msg) {
  EventBus.$emit('socketMessage', JSON.parse(msg.data));
};

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
