import Vue from 'vue';
const vue = new Vue();
export function formatDate(value, format) {
  // 这里是日期格式化的逻辑
  const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return new Intl.DateTimeFormat('en', options).format(value);
}
export function formatDateRemoveT(value) {
  if (typeof value != 'string') {
    return value;
  }
  const timeArr = value.split('T');
  return `${timeArr[0]} ${timeArr[1]}`;
}
export function formatDateStr(value, format) {
  // 创建 Date 对象并解析时间字符串
  const parsedDate = new Date(value);

  // 格式化日期部分（年、月、日）
  const formattedDate = parsedDate.toISOString().split('T')[0];

  // 格式化时间部分（时、分、秒）
  const formattedTime = parsedDate.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });

  // 合并日期和时间部分
  const formattedDateTime = `${formattedDate} ${formattedTime}`;
  return formattedDateTime;
}

export function capitalize(value) {
  // 这里是字符串首字母大写的逻辑
}

export function toFixedStr(value, precision = 2) {
  if (typeof value !== 'number') {
    return value;
  }
  return value.toFixed(precision);
}

// 提取字符串的数字
export function extractNumbers(inputString) {
  const numbers = inputString.match(/\d+/g);
  if (numbers) {
    return numbers.map(Number);
  } else {
    return [];
  }
}

// 格式化累计时间
export function calculateTimeDifference(startDateStr) {
  if (typeof startDateStr != 'string') {
    // return `0 day 0 hover 0 minute`;
    return [0, 0, 0];
  }
  const startDate = new Date(startDateStr);
  const currentDate = new Date();

  const timeDifference = currentDate - startDate;

  const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  // return `${days} 天 ${hours} 时 ${minutes} 分`;
  return [days, hours, minutes];
}

export function formatSecondTime(seconds) {
  if (typeof seconds !== 'number' || seconds < 0) {
    return [0, 0, 0];
  }

  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  return [hours, minutes, remainingSeconds];
}

// 隐藏中间部分
export function hideStrMiddle(value) {
  if (!value || typeof value !== 'string') {
    return value;
  }

  const length = value.length;
  const visible = 2; // 保留的字符数

  if (length <= visible) {
    return value; // 如果字符串长度小于等于保留的字符数，直接返回原字符串
  }

  const hiddenLength = length - visible * 2; // 需要隐藏的字符数
  const start = value.substr(0, visible); // 开头保留的部分
  const end = value.substr(length - visible, visible); // 结尾保留的部分
  const hidden = '*'.repeat(hiddenLength); // 中间用 * 代替的部分

  return `${start}${hidden}${end}`;
}

export function toFixeds(val, pre) {
  const num = parseFloat(val);
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(num)) {
    return false;
  }
  const p = 10 ** pre;
  const value = num * p;
  let f = (Math.round(value) / p).toString();
  let rs = f.indexOf('.');
  if (rs < 0) {
    rs = f.length;
    f += '.';
  }
  while (f.length <= rs + pre) {
    f += '0';
  }
  return f;
}

export const datetimeFormats = (t) => {
  let date = new Date(t),
    Y = date.getFullYear() + '-',
    M =
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '-',
    D =
      +date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ',
    h =
      date.getHours() > 9 ? date.getHours() + ':' : '0' + date.getHours() + ':',
    m = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes(),
    s = ':' + date.getSeconds();
  return M + D + h + m;
};
