<template>
  <div class="teamMemberPage">
    <Navbar NavLeftArrow="true" :NavLeftText="$t('team.MyTeam')"></Navbar>
    <div class="proxyView">
        <div :class="['proxyItem',index==0?'active':''] " @click="index=0">
            <span>{{ $t('team.Level1Agent') }}</span>
        </div>
        <div  :class="['proxyItem',index==1?'active':''] "  @click="index=1">
            <span>{{ $t('team.Level2Agent') }}</span>
        </div>
        <div  :class="['proxyItem',index==2?'active':''] "  @click="index=2">
            <span>{{ $t('team.Level3Agent') }}</span>
        </div>
    </div>
    <div class="teamView">
        <div class="teamItem">
            <img src="../../../assets/img/Homeindex/robot.png" alt="">
            <div class="rInfo">
                <p>231010</p>
                <span>2023-10-14 15:47:12</span>
            </div>
            <p>+88620231010</p>
        </div>
      </div>
    <p class="sign">{{ $t('noMore') }}</p>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
export default {
    components:{
        Navbar
    },
    created() {},
    data() {
    return {
        index:0
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.teamMemberPage{
    width: 100%;
    height: 100%;
    background-color: #161616;
    padding-top: 60px;
    .proxyView{
        padding: 0 .4rem;
        box-sizing: border-box;
        margin-top: 20px;
        display: flex;
        justify-content: space-around;
        .proxyItem{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 2.2933rem;
            height: .9333rem;
            span{
                font-size: .3733rem;
                color: #646566;

            }
        }
        .active{
            background-color: #1f1f1f!important;  
            span{
                color: #ffe87e;
            }
        }
    }
    .teamView{
       margin-top: 20px;
       
        .teamItem{
            margin: 0 auto;
            width: 9.2rem;
            height: 2.4667rem;
            padding: 10px 15px;
            box-sizing: border-box;
            display: flex;
            font-size: .3733rem;
            color: #fff;
            background-color: #1f1f1f;
            display: flex;
            align-items: center;
            border-radius: .26667rem;
            justify-content: space-between;

        }
        img{
            width: 36px;
            height: 36px;
        }
        .rInfo{
            margin-left: -0.5333rem;
            p{
                font-size: .4267rem;
                font-weight: 700;
            }
            span{
                opacity: .7;
            }
        }
      }
    .sign{
        margin-top: 20px;
        text-align: center;
        color: #969799;
        font-size: .3733rem;
    }
}

</style>