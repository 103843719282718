<template>
  <page :title="$t('home.detailMsg')" class="messagePage min-h-screen  bg-no-repeat bg-fixed bg-top priPageBg" :style="{
    backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
  }">
    <div class="p-base leading-3">
      {{ info.Content }}
    </div>
  </page>
</template>

<script>
import { ApiPath } from '@/api/path';
export default {
  data() {
    return {
      info: {},
    };
  },
  methods: {
    async getMessage() {
      const params = {
        id: this.$route.params.id,
      };
      const { data, code } = await this.$http.get(ApiPath.getMessage, params);
      if (code == 0) {
        this.info = data;
      }
    },
  },
  created() {
    this.getMessage();
  },
};
</script>

<style lang="scss" scoped>
.messageDetailPage {
  width: 100%;
  height: 100%;
  background-color: #161616;
  padding-top: 1.6rem;
  .msgDetail {
    padding: 30px 0.4rem;
    color: #fff;
    font-size: 0.3733rem;
  }
}
</style>
