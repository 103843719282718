<template>
  <page class="bg-no-repeat bg-fixed  bg-top priPageBg" :title="$t('aboutUs.intro')" :style="{
    backgroundImage: `url(${require('../../assets/img/bg-blur.png')})`,
  }">
    <div class="p-base">
      <t-markdown fileName="about.md" />
    </div>
    <!-- <div class="p-base">
      <div class="text-[20px] text-primary font-bold py-[16px]">
        {{ $t('aboutUs.intro') }}
      </div>
      <div class="text-[14px] leading-[32px] text-gray-200">
        <p>
          {{ $t('aboutUs.p1') }}
        </p>
      </div>
      <div class="text-[20px] text-primary font-bold py-[16px]">
        {{ $t('aboutUs.VisionandMission') }}
      </div>
      <div class="text-[14px] leading-[32px] text-gray-200">
        <p>
          {{ $t('aboutUs.p2') }}
        </p>
        <p>
          {{ $t('aboutUs.p3') }}
        </p>
        <p>
          {{ $t('aboutUs.p4') }}
        </p>
        <p>
          {{ $t('aboutUs.p5') }}
        </p>
      </div>
    </div> -->
  </page>
</template>

<script>
import Navbar from '@/components/Navbar/Navbar.vue';
export default {
  components: {
    Navbar,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.aboutPage {
  img {
    width: 100%;
  }
}
</style>
